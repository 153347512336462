import React, { Fragment } from "react";
import { Link } from "react-router-dom";

export function UserBranchManagement() {
   
    return (
        <div className="widget-media">
            <ul className="timeline">

            <li>
                <Link to="/settings/users">
					<div className="timeline-panel">
						<div className="media me-2 media-primary">
							<i className="bi bi-person-plus"></i>
						</div>
						<div className="media-body">
							<h5 className="mb-1">Company Users</h5>
							<small className="d-block">Add or Edit User Information </small>
						</div>
						</div> 
                </Link>
			</li>

                <li>
                   <Link to="/settings/branches">
                                <div className="timeline-panel">
                                    <div className="media me-2 media-primary">
                                        <i className="bi bi-folder-plus"></i>
                                    </div>
                                    <div className="media-body">
                                        <h5 className="mb-1">Branches / Department</h5>
                                        <small className="d-block">Add or edit branches and depatt </small>
                                    </div>
                                </div> 
                                </Link>
                </li>

                <li>
                    <Link to="/settings/permission-groups">
												<div className="timeline-panel">
													<div className="media me-2 media-primary">
														<i className="bi bi-plus-circle-dotted"></i>
													</div>
													<div className="media-body">
														<h5 className="mb-1">Permission Groups</h5>
														<small className="d-block">Add or edit permissions </small>
													</div>
												</div> 
                    </Link>
				</li>
            </ul>

        </div>
    );

}

export function LoadManagement() {
    return (

        <div className="widget-media">
            <ul className="timeline">
            <li>
                <Link to="/settings/ims">
					<div className="timeline-panel">
						<div className="media me-2 media-primary">
							<i className="bi bi-person-plus"></i>
						</div>
						<div className="media-body">
							<h5 className="mb-1">Management Screens</h5>
							<small className="d-block">Edit Load Status, Commodities, Pay Items, Truck Length, Truck Status, Equipment Types</small>
						</div>
						</div> 
                </Link>
			</li>
            </ul>
        </div>


    );
}

