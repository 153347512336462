import React from "react";
import { Link } from "react-router-dom";

const SocialLinks = (props) => {
  const facebook = (props.facebookUrl || "");
  const facebook_url = 'https://www.facebook.com/'+ (props.facebookUrl || "");
  const linkedin = (props.linkedinUrl || "");
  const linkedin_url = 'https://in.linkedin.com/'+ (props.linkedinUrl || "");
  const twitter = (props.twitterUrl || "");
  const twitter_url = 'https://x.com/'+ (props.twitterUrl || "");
  const instagram = (props.instagramUrl || "");
  const instagram_url = 'https://www.instagram.com/'+ (props.instagramUrl || "");
  return (
    <>
      <div className="col-xxl-4 col-xl-6 col-sm-6 ">
        <Link
          to={facebook_url}
          target="_blank"
          className="btn text-start d-block mb-3 bg-facebook light"
        >
          <i className="fab fa-facebook-f scale5 me-4 text-facebook" />
          {facebook}
        </Link>
      </div>
      <div className="col-xxl-4 col-xl-6 col-sm-6 ">
        <Link
          to={linkedin_url}
          target="_blank"
          className="btn text-start d-block mb-3 bg-linkedin light"
        >
          <i className="fab fa-linkedin-in scale5 me-4 text-linkedin" />
          {linkedin}
        </Link>
      </div>
      <div className="col-xxl-4 col-xl-6 col-sm-6 ">
        <Link
          to={twitter_url}
          target="_blank"
          className="btn text-start d-block mb-3 bg-dribble light"
        >
          <i className="fab fa-twitter scale5 me-4 text-dribble" />
          {twitter}
        </Link>
      </div>
      <div className="col-xxl-4 col-xl-6 col-sm-6 ">
        <Link
          to={instagram_url}
          target="_blank"
          className="btn text-start d-block mb-3 bg-youtube light"
        >
          <i className="fab fa-instagram scale5 me-4 text-youtube" />
          {instagram}
        </Link>
      </div>
    </>
  );
};

export default SocialLinks;
