import AxiosInstanceWithAuth from "./AxiosInstanceWithAuth";

const getLocationList = async (pageMeta) => {
  try {
    const response = await AxiosInstanceWithAuth.post(`/location/list`, {
      page: pageMeta.currentPage,
      limit: pageMeta.limit,
    });
    return response;
  } catch (error) {
    console.error("Error fetching location list:", error);
    return null;
  }
};

const deleteLocationById = async (locationId) => {
  try {
    const response = await AxiosInstanceWithAuth.post(`/location/delete`, {
      location_id: locationId,
    });
    return response.data;
  } catch (error) {
    console.error("Error while deleting location:", error);
    return error;
  }
};

const addLocation = async (location) => {
  try {
    const response = await AxiosInstanceWithAuth.post(
      "/location/add",
      location
    );
    return response.data;
  } catch (error) {
    console.error("Error adding location:", error);
    throw error;
  }
};

const updateLocation = async (id, location) => {
  try {
    const response = await AxiosInstanceWithAuth.post(
      "/location/edit",
      location
    );
    return response.data;
  } catch (error) {
    console.error("Error updating location:", error);
    throw error;
  }
};

const getLocationById = async (locationId) => {
  try {
    const response = await AxiosInstanceWithAuth.post(
      `/location/get_location_by_id`,
      {
        location_id: locationId,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching location:", error);
    return null;
  }
};

const deleteLocationContact = async (locationId, contactId) => {
  try {
    const response = await AxiosInstanceWithAuth.post(
      `/location/contact_delete`,
      {
        location_id: locationId,
        location_meta_id: contactId,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching location:", error);
    return null;
  }
};

export {
  getLocationList,
  deleteLocationById,
  addLocation,
  updateLocation,
  getLocationById,
  deleteLocationContact,
};
