import React from "react";
import { Link } from "react-router-dom";

const ProfileBanner = (props) => {
  return (
    <div className="col-xl-12">
      <div className="profile-back">
        <img src={props.companyLogo || ""} alt="" />
        <div className="social-btn">
          <Link to={"#"} className="btn btn-light social">
            <div className="col">
              <h3 className="m-b-0">150</h3>
              <span>Loads</span>
            </div>
          </Link>
          <Link to={"#"} className="btn btn-light social">
            872 Customer
          </Link>
          <Link to={"#"} className="btn btn-light social">
            872 Carrier
          </Link>
          <Link to={"#"} className="btn btn-light social">
            872 Location
          </Link>
          <Link to={"#"} className="btn btn-primary">
                    Update Profile
                </Link>
        </div>
      </div>
      <div className="profile-pic d-flex">
        <img src={props.userImage || ""} alt="" />
        <div className="profile-info2">
          <h2 className="mb-0">{props.name || ""}</h2>
          <h4>{props.businessType || ""}</h4>
          <span className="d-block">
            <i className="fas fa-map-marker-alt me-2"></i>
            {props.companyAddress || ""}
          </span>
        </div>
      </div>
    </div>
  );
};

export default ProfileBanner;
