import { getMakeList } from "../../../../../../services/CustomServices/CommonService";

export const fetchMakeList = async () => {
  try {
    const res = await getMakeList();
    const result = res.data.result;
    var options = result.map((item) => ({
      value: item.id,
      label: item.make,
    }));
    options.unshift({ value: "", label: "Choose" });
    return options;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const transformReducerData = (state, action) => {
  switch (action.type) {
    case "collpase1":
      return { ...state, collpase1: !state.collpase1 };
    case "collpase2":
      return { ...state, collpase2: !state.collpase2 };
    case "collpase3":
      return { ...state, collpase3: !state.collpase3 };

    default:
      return state;
  }
};