import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  location_name: Yup.string().required("Location name is required"),
  location_mobile: Yup.string()
  .matches(
    /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
    "Invalid mobile format"
  ),
  location_branch: Yup.string().required("Branch is required"),
  location_address: Yup.string().required("Location address is required"),
  location_private_notes: Yup.string(),
  location_public_notes: Yup.string(),
  location_meta: Yup.array().of(
    Yup.object().shape({
      contact_name: Yup.string().required("Required"),
      contact_mobile: Yup.string()
        .matches(
          /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
          "Invalid contact mobile format"
        )
        .required("Required"),
      /* contact_mobile_ext: Yup.string().required("Required"),
        contact_designation: Yup.string().required("Required"),
        contact_email: Yup.string()
          .email("Must be a valid email")
          .required("Required"),
        contact_fax: Yup.string().required("Required"), */
    })
  ),
});

export default validationSchema;