import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Loader,
  Paginate,
  PageTitle,
  handleDeleteItem,
} from "../../../../services/CustomServices/CommonService";
import {
  getTrailersList,
  deleteTrailerById,
} from "../../../../services/CustomServices/TrailerService";

export default function TrailerList() {
  const [trailers, setTrailers] = useState({
    result: [],
    totalRecords: 0,
  });

  const [pageMeta, setPageMeta] = useState({
    currentPage: 1,
    limit: 10,
  });

  const [isLoading, setIsLoading] = useState(false);

  const handleDeleteTrailer = (trailerId) => {
    handleDeleteItem(
      trailerId,
      "Trailer",
      deleteTrailerById,
      trailers,
      setTrailers
    );
  };

  function listTrailer() {
    setIsLoading(true);
    getTrailersList(pageMeta)
      .then((res) => {
        if (res.data) {
          setTrailers((prev) => ({
            ...prev,
            result: res.data.result,
            totalRecords: res.data.total_rows,
          }));
        } else {
          console.error("Invalid response from getTrailersList:", res);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  }

  useEffect(() => {
    listTrailer();
  }, [pageMeta]);

  const handlePrevPage = () => {
    if (pageMeta.currentPage > 1) {
      setPageMeta((prevState) => ({
        ...prevState,
        currentPage: Math.max(prevState.currentPage - 1, 1),
      }));
    }
  };

  const handleNextPage = () => {
    if (pageMeta.currentPage * pageMeta.limit <= trailers.totalRecords) {
      setPageMeta((prevState) => ({
        ...prevState,
        currentPage: Math.max(prevState.currentPage + 1, 1),
      }));
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-xl-12">
          <PageTitle activeMenu="Trailer List" motherMenu="Trailer" />
          <div className="mb-3">
            <ul className="d-flex align-items-center flex-wrap">
              <li>
                <Link
                  to={"/assets/trailer"}
                  className="btn btn-primary btn-sm mx-1"
                >
                  Add Trailer
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-xl-12">
          <div className="table-responsive">
            <table className="table display mb-4 dataTablesCard job-table table-responsive-xl card-table dataTable no-footer">
              <thead>
                <tr>
                  <th className="fs-14">No</th>
                  <th className="fs-14">Trailer Number</th>
                  <th className="fs-14">Make</th>
                  <th className="fs-14">Model</th>
                  <th className="fs-14">Trailer Type</th>
                  <th className="fs-14">License Plate</th>
                  <th className="fs-14">License Plate State/Province</th>
                  <th className="fs-14">License Plate Expiration</th>
                  <th className="fs-14">Vehicle ID</th>
                  <th className="fs-14">Actions</th>
                </tr>
              </thead>
              <tbody>
                {/* <Loader isLoading={isLoading} /> */}
                {trailers.result?.length ? (
                  !isLoading &&
                  trailers.result?.map(
                    (trailer, index) =>
                      !trailer.isHidden && (
                        <tr key={`${index}${trailer.id}`}>
                          <td className="fs-14">{++index}</td>
                          <td className="fs-14">
                            {trailer.trl_trailer_number}
                          </td>
                          <td className="fs-14">{trailer.trl_make_name}</td>
                          <td className="fs-14 wspace-no">
                            {trailer.trl_model}
                          </td>
                          <td className="fs-14 wspace-no">
                            {trailer.trl_trailer_type}
                          </td>
                          <td className="fs-14">{trailer.trl_license_plate}</td>
                          <td className="fs-14">
                            {trailer.trl_license_plate_state_province}
                          </td>
                          <td className="fs-14">
                            {trailer.trl_license_plate_expiration}
                          </td>
                          <td className="fs-14">
                            {trailer.trl_vehicle_id_number}
                          </td>
                          <td className="fs-14">
                            <div className="action-buttons d-flex justify-content-end">
                              <Link
                                to={`/assets/trailer/${trailer.id}`}
                                className="btn btn-secondary light mr-2"
                                onClick={(event) => {}}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24px"
                                  height="24px"
                                  viewBox="0 0 24 24"
                                  version="1.1"
                                  className="svg-main-icon"
                                >
                                  <g
                                    stroke="none"
                                    strokeWidth="1"
                                    fill="none"
                                    fillRule="evenodd"
                                  >
                                    <rect
                                      x="0"
                                      y="0"
                                      width="24"
                                      height="24"
                                    ></rect>
                                    <path
                                      d="M8,17.9148182 L8,5.96685884 C8,5.56391781 8.16211443,5.17792052 8.44982609,4.89581508 L10.965708,2.42895648 C11.5426798,1.86322723 12.4640974,1.85620921 13.0496196,2.41308426 L15.5337377,4.77566479 C15.8314604,5.0588212 16,5.45170806 16,5.86258077 L16,17.9148182 C16,18.7432453 15.3284271,19.4148182 14.5,19.4148182 L9.5,19.4148182 C8.67157288,19.4148182 8,18.7432453 8,17.9148182 Z"
                                      fill="#000000"
                                      fillRule="nonzero"
                                      transform="translate(12.000000, 10.707409) rotate(-135.000000) translate(-12.000000, -10.707409) "
                                    ></path>
                                    <rect
                                      fill="#000000"
                                      opacity="0.3"
                                      x="5"
                                      y="20"
                                      width="15"
                                      height="2"
                                      rx="1"
                                    ></rect>
                                  </g>
                                </svg>
                              </Link>
                              <Link
                                to={"#"}
                                className="btn btn-danger light"
                                onClick={() => handleDeleteTrailer(trailer.id)}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24px"
                                  height="24px"
                                  viewBox="0 0 24 24"
                                  version="1.1"
                                  className="svg-main-icon"
                                >
                                  <g
                                    stroke="none"
                                    strokeWidth="1"
                                    fill="none"
                                    fillRule="evenodd"
                                  >
                                    <rect
                                      x="0"
                                      y="0"
                                      width="24"
                                      height="24"
                                    ></rect>
                                    <path
                                      d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z"
                                      fill="#000000"
                                      fillRule="nonzero"
                                    ></path>
                                    <path
                                      d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z"
                                      fill="#000000"
                                      opacity="0.3"
                                    ></path>
                                  </g>
                                </svg>
                              </Link>
                            </div>
                          </td>
                        </tr>
                      )
                  )
                ) : (
                  <tr>
                    <td colSpan={10} className="text-center">
                      {isLoading ? (
                        <Loader isLoading={isLoading} />
                      ) : (
                        <div>No Trailer found</div>
                      )}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
        <Paginate
          currentPage={pageMeta.currentPage}
          totalRecords={trailers.totalRecords}
          handlePrevPage={handlePrevPage}
          handleNextPage={handleNextPage}
        />
      </div>
    </>
  );
}
