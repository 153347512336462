import React, { useState,useEffect } from "react";
import {  Button, Modal } from "react-bootstrap";
import PageTitle from "../../../layouts/PageTitle";
import AddEditUserComponent from "../../../components/tms/setting-components/add-edit-user-components";
import { GetProjectAttributeListAPI,GetUserListAPI } from '../../../../services/SettingService';

import { SwatAlertErrorTopRight } from '../../../components/tms/Sweet-alert-component';


function SettingUsersTMS() {
 
    const [loading, setLoading] = useState(true);   
    const [addEditUserModal, setAddEditUserModal] = useState(false);
    const [tableData, setTableData] = useState(null);
    const [sortConfig, setSortConfig] = useState({ key: 'id', direction: 'ascending' });
    const [userSelectedData, setUserSelectedData] = useState(null); // Data from the clicked row
    const [branchList, setBranchList] = useState(null);
    const [PermissionList, setPermissionList] = useState(null);
    const GetUserListApiData = async()=> {

      let GetAllUserList = await GetUserListAPI('');
      if(GetAllUserList) {
        if(GetAllUserList.status==="200"){
           setTableData(GetAllUserList.result);  
           setLoading(false);
        } else {
            SwatAlertErrorTopRight("Server Not Working"); 
        }
      }
      

    }

    const ParentChildController = (newMessage) => {

       GetUserListApiData();
       setAddEditUserModal(false); // Open the modal
      
    };
  const fetchData = async () => {
   
    

      let postData = {};
      postData.project_key = "branch-department";
      postData.request_from = "setting-users";
      const ListResponse = await GetProjectAttributeListAPI(postData);
      if(ListResponse){

        setBranchList(ListResponse.result); 

      }
  };

  const getPermissionGroupAPI = async()=> {

      let postDataOfBranch = {};
      postDataOfBranch.project_key = "permission-groups";
      postDataOfBranch.request_from = "setting-users";
      const ListResponsePermission = await GetProjectAttributeListAPI(postDataOfBranch);
      if(ListResponsePermission){
        setPermissionList(ListResponsePermission.result); 
      }

  }
    useEffect(() => {
      fetchData();
      getPermissionGroupAPI();
      GetUserListApiData();
  },[]);



      const handleSort = (columnKey) => {

        let direction = 'ascending';
        if (sortConfig.key === columnKey && sortConfig.direction === 'ascending') {
         direction = 'descending';
        }
        setSortConfig({ key: columnKey, direction });


        const sortedData = [...tableData].sort((a, b) => {
            if (a[columnKey] < b[columnKey]) {
              return direction === 'ascending' ? -1 : 1;
            }
            if (a[columnKey] > b[columnKey]) {
              return direction === 'ascending' ? 1 : -1;
            }
            return 0;
          });
      
          setTableData(sortedData);


        }

      const getSortArrow = (columnKey) => {
        if (sortConfig.key === columnKey) {
          return sortConfig.direction === 'ascending' ? '↑' : '↓';
        }
        return '↕'; // Default arrow (up and down) when not sorted
      };

      const handleRowClick = (rowData) => {

        console.log("Running....");

        setUserSelectedData(rowData); // Set the selected row data
        setAddEditUserModal(true); // Open the modal
      };
      const showModal = () => {
        console.log("Here");
        setUserSelectedData(false); // Set the selected row data
        setAddEditUserModal(true); // Open the modal
      }
     

  

     
 


    return (
        <>
            <PageTitle motherMenu="Dashboard" activeMenu="Users" />
            <div className="card">
				<div className="card-header">

        {loading ? 
          (
                <p>Loading....</p>
          ):(
            <button className="btn btn-primary add_new" id="nav-forget-tab" type="button"  onClick={() => showModal()}><i className="fa fa-fw fa-plus"></i> Add New User</button>
          )
        }
                </div>
                <div className="card-body">
					<div className="table-responsive">
                           <div className="dataTables_wrapper">
                               
                               
                                                         
                               
                               
                               
                               
                                <table role="table" className="table dataTable display">
                                    <thead>
                                       <tr>
                                            <th onClick={() => handleSort('name')}>Full Name {getSortArrow('name')}</th>
                                            <th onClick={() => handleSort('email')}>Email ID {getSortArrow('email')}</th>
                                            <th onClick={() => handleSort('phone_number')}>Phone Number {getSortArrow('phone_number')}</th>
                                            <th onClick={() => handleSort('permission_groups')}>Permission {getSortArrow('permission_groups')}</th>
                                            <th onClick={() => handleSort('branches_departments')}>Branch/Department List {getSortArrow('branches_departments')}</th>
                                            <th onClick={() => handleSort('id')}>Action {getSortArrow('id')}</th>
                                        </tr>    
                                    </thead>
                                   
                                      
                                    {loading ? 
                                      ( 
                                        <tbody><tr><td colaspane="5">Loading.....  </td></tr></tbody>)
                                      :(
                                      
                                      
                                        <tbody> 


{tableData.map((user,key) => (
                                            <tr key={key} >
                                                
                                                <td>{user.name}</td>
                                                <td>{user.email}</td>
                                                <td>{user.phone_number}</td>
                                                <td>
                                                    <PermissionGroup datastring={user.permission_groups_name} />

                                                 
                                                
                                                </td>
                                                <td>
                                                  <PermissionGroup datastring={user.branches_departments_name} />
                                                  
                                                  
                                                </td>
                                                <td>

                                                   <button onClick={() => handleRowClick(user)} type="button" className="btn btn-primary shadow btn-xs sharp me-1"> 
		                                                  <i className="bi bi-pencil-square"></i>
                                			        	    </button>

                                                </td>
                                                
                                            </tr>
                                        ))}
                                        
                                      

                                    
                                      </tbody>
                                      )}
                                   
                                </table>    
                           </div>





                    </div>
                </div>

                <Modal className="fade bd-example-modal-lg" show={addEditUserModal} size="lg" >
    <Modal.Header>
        <Modal.Title>User</Modal.Title>
          <Button variant="" className="btn-close" onClick={() => setAddEditUserModal(false)} > </Button>
        </Modal.Header>
        <Modal.Body>
            <AddEditUserComponent data={userSelectedData } branchList={branchList} permissionList={PermissionList}  ParentChildController={ParentChildController}/>
        </Modal.Body>                          
</Modal>
            </div>    
        </>
    );


}
function PermissionGroup(props) {
  /* const data = props.datastring.split(',').map(item => item.trim()); */
  const data = (props.datastring ?? '').split(',').map(item => item.trim());

  return (
         <div>
            {data.map((user,key) => (

              <div key={key} className="badge badge-rounded badge-outline-primary">{user}</div>
          
            ))}
         </div>
  )
}
export default SettingUsersTMS;
