import React, { useMemo, useReducer } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import TrailerValidationSchema from "./helpers/TrailerValidationSchema";
import {
  addTrailer,
  updateTrailer,
  getTrailerById,
} from "../../../../../services/CustomServices/TrailerService";
import { Link, useParams, useNavigate } from "react-router-dom";
import { Collapse } from "react-bootstrap";
import PageTitle from "../../../../layouts/PageTitle";
import { toast } from "react-toastify";
import CustomSelect from "../../../../components/CustomComponents/Select";
import { fetchMakeList, transformReducerData } from "./helpers/function";
import {
  trailerInitialValues,
  trl_status_options,
  trl_license_plate_state_province_options,
  trl_trailer_category_options,
  trl_door_style_options,
  trl_ownership_options,
  trl_purchased_or_leased_options,
} from "./helpers/constant";

const debug = false;
const AddTrailer = () => {
  const [state, dispatch] = useReducer(transformReducerData, true);
  const { trailerId } = useParams();
  const [trailer, setTrailer] = React.useState(null);
  const navigate = useNavigate();
  const [trl_make_options, setPwuMakeOptions] = React.useState([]);

  /* const initialValues = trailer || trailerInitialValues; */
  const initialState = useMemo(() => trailer || trailerInitialValues, [trailer]);

  // Get dropdowns value from API on page render
  React.useEffect(() => {
    fetchMakeList().then((options) => {
      setPwuMakeOptions(options);
    });
  }, []);

  // Get Trailer data by ID and set in particuar fields (Call only when trailerId available)
  React.useEffect(() => {
    if (!trailerId) return;
    const fetchTrailer = async () => {
      if (trailerId) {
        try {
          const trailerData = await getTrailerById(trailerId);
          if (
            trailerData &&
            trailerData.result &&
            trailerData.result.length > 0
          ) {
            setTrailer(trailerData.result[0]);
          }
        } catch (error) {
          console.error("Error fetching trailer:", error);
        }
      }
    };

    fetchTrailer();
  }, [trailerId]);

  const onSubmit = async (values, { setSubmitting }) => {
    try {
      await TrailerValidationSchema.validate(values, { abortEarly: false });

      if (trailer && trailer.id) {
        try {
          await updateTrailer(
            trailer.id,
            values
          );
          toast.success("Trailer updated successfully!");
          navigate("/assets/trailer-list", { replace: true });
        } catch (error) {
          toast.error("Error updating trailer!");
        }
      } else {
        try {
          await addTrailer(values);
          toast.success("Trailer added successfully!");
          setTimeout(() => {
            navigate("/assets/trailer-list", { replace: true });
          }, 2000);
        } catch (error) {
          toast.error("Error adding trailer!");
          throw error;
        }
      }
    } catch (error) {
      const errorMessages = error.inner.map((err) => err.message);
      toast.error(`Validation errors: ${errorMessages.join(", ")}`, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      alert(error);
    }
    setSubmitting(false);
  };

  return (
    <Formik
      initialValues={initialState}
      validationSchema={TrailerValidationSchema}
      enableReinitialize // This ensures form is updated with data when editing
      validateOnBlur={false}
      validateOnChange={true}
      validateOnMount={true}
      /* onSubmit={onSubmit} */
      onSubmit={async (values, { setSubmitting, validateForm }) => {
        const errors = await validateForm(values);
        if (Object.keys(errors).length === 0) {
          onSubmit(values, { setSubmitting });
        } else {
          console.log("Trailer form is not valid:", errors);
        }
      }}
    >
      {({
        dirty,
        isSubmitting,
        handleSubmit,
        errors,
        setFieldValue,
        values,
      }) => (
        <Form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-xl-12">
              <PageTitle activeMenu="Add Trailer" motherMenu="Trailer" />
              <div className="mb-3">
                <ul className="d-flex align-items-center flex-wrap">
                  <li>
                    <Link
                      to={"/assets/trailer-list"}
                      className="btn btn-primary btn-sm mx-1"
                    >
                      Trailer List
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="row">
                <div className="col-xl-8 col-xxl-12">
                  <div className="filter cm-content-box box-primary">
                    <div className="content-title py-0">
                      <div className="cpa">Asset Profile</div>
                      <div className="tools">
                        <Link
                          to={"#"}
                          className={`SlideToolHeader ${
                            state.collpase1 ? "collapse" : "expand"
                          }`}
                          onClick={() => dispatch({ type: "collpase1" })}
                        >
                          <i className="fas fa-angle-up"></i>
                        </Link>
                      </div>
                    </div>
                    <Collapse in={!state.collpase1}>
                      <div className="cm-content-body form excerpt">
                        <div className="card-body py-3">
                          <div className="row">
                            <div className="col-xl-4 col-sm-6 col-md-3">
                              <label className=" form-label mb-1">
                                Trailer Number{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <Field
                                type="text"
                                name="trl_trailer_number"
                                className="form-control form-control-sm mb-3"
                                placeholder="Trailer Name"
                              />
                              <ErrorMessage
                                name="trl_trailer_number"
                                component="div"
                                className="text-danger form-text mb-3"
                              />
                            </div>

                            <div className="col-xl-4 col-sm-6 col-md-3">
                              <label className=" form-label mb-1">Status</label>
                              <div className="input-group mb-3">
                                <label className="input-group-text mb-0">
                                  Status
                                </label>
                                <CustomSelect
                                  name="trl_status"
                                  options={trl_status_options}
                                  className="form-control form-control-sm wide"
                                />
                              </div>
                            </div>

                            <div className="col-xl-4 col-sm-6 col-md-3">
                              <label className=" form-label mb-1">Make</label>
                              <div className="input-group mb-3">
                                <label className="input-group-text mb-0">
                                  Make
                                </label>
                                <CustomSelect
                                  name="trl_make"
                                  options={trl_make_options}
                                  className="form-control form-control-sm wide"
                                />
                              </div>
                            </div>

                            <div className="col-xl-4 col-sm-6 col-md-3">
                              <label className=" form-label mb-1">Model</label>
                              <Field
                                type="text"
                                name="trl_model"
                                className="form-control form-control-sm mb-3"
                              />
                            </div>

                            <div className="col-xl-4 col-sm-6 col-md-3">
                              <label className=" form-label mb-1">
                                Model Year
                              </label>
                              <Field
                                type="number"
                                name="trl_model_year"
                                className="form-control form-control-sm mb-3"
                              />
                            </div>

                            <div className="col-xl-4 col-sm-6 col-md-3">
                              <label className=" form-label mb-1">
                                Vehicle ID Number
                              </label>
                              <Field
                                type="text"
                                name="trl_vehicle_id_number"
                                className="form-control form-control-sm mb-3"
                              />
                            </div>

                            <div className="col-xl-4 col-sm-6 col-md-3">
                              <label className=" form-label mb-1">
                                License Plate
                              </label>
                              <Field
                                type="text"
                                name="trl_license_plate"
                                className="form-control form-control-sm mb-3"
                              />
                            </div>

                            <div className="col-xl-4 col-sm-6 col-md-3">
                              <label className=" form-label mb-1">
                                License Plate State/Province
                              </label>
                              <div className="input-group mb-3">
                                <label className="input-group-text mb-0">
                                  State/Province
                                </label>
                                <CustomSelect
                                  name="trl_license_plate_state_province"
                                  options={
                                    trl_license_plate_state_province_options
                                  }
                                  className="form-control form-control-sm wide"
                                />
                              </div>
                            </div>

                            <div className="col-xl-4 col-sm-6 col-md-3">
                              <label className=" form-label mb-1">
                                Trailer Type
                              </label>
                              <Field
                                type="text"
                                name="trl_trailer_type"
                                className="form-control form-control-sm mb-3"
                              />
                            </div>

                            <div className="col-xl-4 col-sm-6 col-md-3">
                              <label className=" form-label mb-1">
                                Trailer Category
                              </label>
                              <div className="input-group mb-3">
                                <label className="input-group-text mb-0">
                                  Category
                                </label>
                                <CustomSelect
                                  name="trl_trailer_category"
                                  options={trl_trailer_category_options}
                                  className="form-control form-control-sm wide"
                                />
                              </div>
                            </div>

                            <div className="col-xl-4 col-sm-6 col-md-3">
                              <label className=" form-label mb-1">
                                Door Style
                              </label>
                              <div className="input-group mb-3">
                                <label className="input-group-text mb-0">
                                  Style
                                </label>
                                <CustomSelect
                                  name="trl_door_style"
                                  options={trl_door_style_options}
                                  className="form-control form-control-sm wide"
                                />
                              </div>
                            </div>

                            <div className="col-xl-4 col-sm-6 col-md-3">
                              <label className=" form-label mb-1">
                                Generator Info
                              </label>
                              <Field
                                type="text"
                                name="trl_generator_info"
                                className="form-control form-control-sm mb-3"
                              />
                            </div>

                            <div className="col-xl-4 col-sm-6 col-md-3">
                              <label className=" form-label mb-1">
                                Insurance Information
                              </label>
                              <Field
                                type="text"
                                name="trl_insurance_information"
                                className="form-control form-control-sm mb-3"
                              />
                            </div>

                            <div className="col-xl-4 col-sm-6 col-md-3">
                              <label className=" form-label mb-1">Length</label>
                              <Field
                                type="text"
                                name="trl_length"
                                className="form-control form-control-sm mb-3"
                              />
                            </div>

                            <div className="col-xl-4 col-sm-6 col-md-3">
                              <label className=" form-label mb-1">Width</label>
                              <Field
                                type="text"
                                name="trl_width"
                                className="form-control form-control-sm mb-3"
                              />
                            </div>

                            <div className="col-xl-4 col-sm-6 col-md-3">
                              <label className=" form-label mb-1">Height</label>
                              <Field
                                type="text"
                                name="trl_height"
                                className="form-control form-control-sm mb-3"
                              />
                            </div>

                            <div className="col-xl-4 col-sm-6 col-md-3">
                              <label className=" form-label mb-1">
                                Number of Axles
                              </label>
                              <Field
                                type="text"
                                name="trl_number_of_axles"
                                className="form-control form-control-sm mb-3"
                              />
                            </div>

                            <div className="col-xl-4 col-sm-6 col-md-3">
                              <label className=" form-label mb-1">
                                Unloaded Vehicle Weight
                              </label>
                              <Field
                                type="text"
                                name="trl_unloaded_vehicle_weight"
                                className="form-control form-control-sm mb-3"
                              />
                            </div>

                            <div className="col-xl-4 col-sm-6 col-md-3">
                              <label className=" form-label mb-1">
                                Gross Vehicle Weight
                              </label>
                              <Field
                                type="text"
                                name="trl_gross_vehicle_weight"
                                className="form-control form-control-sm mb-3"
                              />
                            </div>

                            <div className="col-xl-4 col-sm-6 col-md-3">
                              <label className=" form-label mb-1">Notes</label>
                              <Field
                                as="textarea"
                                name="trl_notes"
                                className="form-control form-control-sm"
                                placeholder=""
                                rows="2"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </Collapse>
                  </div>

                  <div className="filter cm-content-box box-primary">
                    <div className="content-title py-0">
                      <div className="cpa">Ownership Info</div>
                      <div className="tools">
                        <Link
                          to={"#"}
                          className={`SlideToolHeader ${
                            state.collpase2 ? "collapse" : "expand"
                          }`}
                          onClick={() => dispatch({ type: "collpase2" })}
                        >
                          <i className="fas fa-angle-up"></i>
                        </Link>
                      </div>
                    </div>
                    <Collapse in={!state.collpase2}>
                      <div className="cm-content-body form excerpt">
                        <div className="card-body py-3">
                          <div className="row">
                            <div className="col-xl-4 col-sm-6 col-md-3">
                              <label className=" form-label mb-1">
                                Ownership
                              </label>
                              <div className="input-group mb-3">
                                <CustomSelect
                                  name="trl_ownership"
                                  options={trl_ownership_options}
                                  className="form-control form-control-sm wide"
                                />
                              </div>
                            </div>

                            <div className="col-xl-4 col-sm-6 col-md-3">
                              <label className=" form-label mb-1">
                                Purchased or Leased?
                              </label>
                              <div className="input-group mb-3">
                                <CustomSelect
                                  name="trl_purchased_or_leased"
                                  options={trl_purchased_or_leased_options}
                                  className="form-control form-control-sm wide"
                                />
                              </div>
                            </div>

                            <div className="col-xl-4 col-sm-6 col-md-3">
                              <label className=" form-label mb-1">
                                Purchased/Leased From
                              </label>
                              <Field
                                type="text"
                                name="trl_purchased_leased_from"
                                className="form-control form-control-sm mb-3"
                              />
                            </div>

                            <div className="col-xl-4 col-sm-6 col-md-3">
                              <label className=" form-label mb-1">
                                Sold To
                              </label>
                              <Field
                                type="text"
                                name="trl_sold_to"
                                className="form-control form-control-sm mb-3"
                              />
                            </div>

                            <div className="col-xl-4 col-sm-6 col-md-3">
                              <label className=" form-label mb-1">
                                Purchase/Lease Date
                              </label>
                              <Field
                                type="date"
                                name="trl_purchase_lease_date"
                                className="form-control form-control-sm mb-3"
                              />
                            </div>

                            <div className="col-xl-4 col-sm-6 col-md-3">
                              <label className=" form-label mb-1">
                                Sold Date/Lease End Date
                              </label>
                              <Field
                                type="date"
                                name="trl_sold_date_lease_end_date"
                                className="form-control form-control-sm mb-3"
                              />
                            </div>

                            <div className="col-xl-4 col-sm-6 col-md-3">
                              <label className=" form-label mb-1">
                                Purchase/Lease Amount
                              </label>
                              <Field
                                type="number"
                                name="trl_purchase_lease_amount"
                                className="form-control form-control-sm mb-3"
                              />
                            </div>

                            <div className="col-xl-4 col-sm-6 col-md-3">
                              <label className=" form-label mb-1">
                                Sold Amount
                              </label>
                              <Field
                                type="number"
                                name="trl_sold_amount"
                                className="form-control form-control-sm mb-3"
                              />
                            </div>

                            <div className="col-xl-4 col-sm-6 col-md-3">
                              <label className=" form-label mb-1">
                                Factory Price
                              </label>
                              <Field
                                type="number"
                                name="trl_factory_price"
                                className="form-control form-control-sm mb-3"
                              />
                            </div>

                            <div className="col-xl-4 col-sm-6 col-md-3">
                              <label className=" form-label mb-1">
                                Current Value
                              </label>
                              <Field
                                type="number"
                                name="trl_current_value"
                                className="form-control form-control-sm mb-3"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </Collapse>
                  </div>
                </div>
                <div className="col-xl-4 col-xxl-12">
                  <div className="filter cm-content-box box-primary">
                    <div className="content-title py-0">
                      <div className="cpa">Maintenance and Safety</div>
                      <div className="tools">
                        <Link
                          to={"#"}
                          className={`SlideToolHeader ${
                            state.collpase3 ? "collapse" : "expand"
                          }`}
                          onClick={() => dispatch({ type: "collpase3" })}
                        >
                          <i className="fas fa-angle-up"></i>
                        </Link>
                      </div>
                    </div>
                    <Collapse in={!state.collpase3}>
                      <div className="cm-content-body form excerpt">
                        <div className="card-body py-3">
                          <div className="row">
                            <div className="col-sm-6 col-md-4 col-lg-4- col-xl-12 col-xxl-4">
                              <label className=" form-label mb-1">
                                License Plate Expiration
                              </label>
                              <Field
                                type="date"
                                name="trl_license_plate_expiration"
                                className="form-control form-control-sm mb-3"
                              />
                            </div>

                            <div className="col-sm-6 col-md-4 col-lg-4- col-xl-12 col-xxl-4">
                              <label className=" form-label mb-1">
                                Inspection Expiration
                              </label>
                              <Field
                                type="date"
                                name="trl_inspection_expiration"
                                className="form-control form-control-sm mb-3"
                              />
                            </div>

                            <div className="col-sm-6 col-md-4 col-lg-4- col-xl-12 col-xxl-4">
                              <label className=" form-label mb-1">
                                DOT Expiration
                              </label>
                              <Field
                                type="date"
                                name="trl_dot_expiration"
                                className="form-control form-control-sm mb-3"
                              />
                            </div>

                            <div className="col-sm-6 col-md-4 col-lg-4- col-xl-12 col-xxl-4">
                              <label className=" form-label mb-1">
                                Registration Expiration
                              </label>
                              <Field
                                type="date"
                                name="trl_registration_expiration"
                                className="form-control form-control-sm mb-3"
                              />
                            </div>

                            <div className="col-sm-6 col-md-4 col-lg-4- col-xl-12 col-xxl-4">
                              <label className=" form-label mb-1">
                                Insurance Expiration
                              </label>
                              <Field
                                type="date"
                                name="trl_insurance_expiration"
                                className="form-control form-control-sm mb-3"
                              />
                            </div>

                            <div className="col-sm-6 col-md-4 col-lg-4- col-xl-12 col-xxl-4">
                              <label className=" form-label mb-1">
                                Est. Odometer Reading
                              </label>
                              <Field
                                type="text"
                                name="trl_est_odometer_reading"
                                className="form-control form-control-sm mb-3"
                              />
                            </div>

                            <div className="col-sm-6 col-md-4 col-lg-4- col-xl-12 col-xxl-4">
                              <label className=" form-label mb-1">
                                Last Service Date
                              </label>
                              <Field
                                type="date"
                                name="trl_last_service_date"
                                className="form-control form-control-sm mb-3"
                              />
                            </div>

                            <div className="col-sm-6 col-md-4 col-lg-4- col-xl-12 col-xxl-4">
                              <label className=" form-label mb-1">
                                Last Service Mileage
                              </label>
                              <Field
                                type="text"
                                name="trl_last_service_mileage"
                                className="form-control form-control-sm mb-3"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </Collapse>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="form-group mt-3">
            <button
              type="submit"
              className="btn btn-primary"
              disabled={!dirty || isSubmitting}
              onClick={() => {
                if (Object.keys(errors).length > 0) {
                  const firstErrorField = Object.keys(errors)[0];
                  document.getElementsByName(firstErrorField)[0].focus();
                }
              }}
            >
              Submit
            </button>
            {debug && (
              <>
                {/* <pre style={{ textAlign: "left" }}>
                  <strong>Values</strong>
                  <br />
                  {JSON.stringify(values, null, 2)}
                </pre> */}
                <pre style={{ textAlign: "left" }}>
                  <strong>Errors</strong>
                  <br />
                  {JSON.stringify(errors, null, 2)}
                </pre>
              </>
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default AddTrailer;
