import AxiosInstanceWithAuth from "./AxiosInstanceWithAuth";

const getDriversList = async (pageMeta) => {
  try {
    const response = await AxiosInstanceWithAuth.post(`/driver/list`,
      {
        page: pageMeta.currentPage,
        limit: pageMeta.limit,
      }
    );
    return response;
  } catch (error) {
    console.error('Error while getting driver List:', error);
    return error;
  }
}

const deleteDriverById = async (driverId) => {
  try {
    const response = await AxiosInstanceWithAuth.post(`/driver/delete`,
      {
       id: driverId
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error while deleting driver:', error);
    return error;
  }
}

const addDriver = async (driver) => {
  try {
    const response = await AxiosInstanceWithAuth.post("/driver/add", driver);
    return response.data;
  } catch (error) {
    console.error("Error adding driver:", error);
    throw error;
  }
};

const updateDriver = async (id, driver) => {
  try {
    const response = await AxiosInstanceWithAuth.post("/driver/edit", driver);
    return response.data;
  } catch (error) {
    console.error("Error updating driver:", error);
    throw error;
  }
};

const getDriverById = async (driverId) => {
  try {
    const response = await AxiosInstanceWithAuth.post(`/driver/get_driver_by_id`,
      {
        driver_id: driverId
      }
    );
    
    return response.data;
  } catch (error) {
    console.error('Error fetching driver:', error);

    return error;
  }
};

export { getDriversList, deleteDriverById, addDriver, updateDriver, getDriverById };