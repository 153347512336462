import AxiosInstanceWithAuth from "../../../../services/CustomServices/AxiosInstanceWithAuth";

const updateCompanyProfile = async (companyData) => {
  try {
    const response = await AxiosInstanceWithAuth.post("/company/update_profile", companyData);
    return response.data;
  } catch (error) {
    console.error("Error updating power unit:", error);
    throw error;
  }
};

const getCompanyProfileInfo = async () => {
  try {
    const response = await AxiosInstanceWithAuth.post(`company/get_profile`);

    return response.data;
  } catch (error) {
    console.error('Error fetching company profile information:', error);

    return error;
  }
};

const updateUserProfile = async (companyData) => {
  try {
    const response = await AxiosInstanceWithAuth.post("/user/update_profile", companyData);
    return response.data;
  } catch (error) {
    console.error("Error updating power unit:", error);
    throw error;
  }
};

const getUserProfileInfo = async () => {
  try {
    const response = await AxiosInstanceWithAuth.post(`user/get_profile`);

    return response.data;
  } catch (error) {
    console.error('Error fetching company profile information:', error);

    return error;
  }
};

export {updateCompanyProfile, getCompanyProfileInfo, updateUserProfile, getUserProfileInfo};