import React from 'react';

const AboutCompany = (props) => {
  return (
    <div className="col-xl-12 col-xxl-12">
    <div className="card">
        <div className="card-header border-0- pb-0">
        <h4 className="fs-20 font-w600">About {props.companyName || ""}</h4>
        </div>
        <div className="card-body pt-4">
        <p className="">{props.aboutCompany || ""}</p>
        <h4 className="fs-20 font-w600 my-4">Contact</h4>
        <div className="d-flex flex-wrap">
            <div className="d-flex contacts-social align-items-center mb-3  me-sm-5 me-0">
            <span className="me-3">
                <i className="fas fa-phone-alt"></i>
            </span>
            <div>
                <span>Phone</span>
                <h5 className="mb-0 ">{props.companyNumber || ""}</h5>
            </div>
            </div>
            <div className="d-flex contacts-social align-items-center mb-3">
            <span className="me-3">
                <i className="fas fa-envelope-open"></i>
            </span>
            <div>
                <span>Email</span>
                <h5 className="mb-0 ">{props.companyEmail || ""}</h5>
            </div>
            </div>
            <div className="d-flex contacts-social align-items-center mb-3">
            <span className="me-3">
                <i className="fas fa-address-card"></i>
            </span>
            <div>
                <span>Address</span>
                <h5 className="mb-0 ">{props.companyAddress || ""}</h5>
            </div>
            </div>
        </div>
        </div>
    </div>
    </div>
  );
};

export default AboutCompany;