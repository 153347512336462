import AxiosInstanceWithAuth from './CustomServices/AxiosInstanceWithAuth';
export async function SaveUserAPI(postData) {

    try {
        let url =   process.env.REACT_APP_API_URL+'api/v1/user/add';
        if(postData.id) {
            url =   process.env.REACT_APP_API_URL+'api/v1/user/edit';
        }
        const response = await AxiosInstanceWithAuth.post(url, postData);
        return response.data;
      } catch (error) {
        console.error("Error User Attribute ", error.response.data);
        return  error.response.data;
      }

}

export async function GetUserListAPI(postData) {

    try {
        const response = await AxiosInstanceWithAuth.post("/user/list", postData);
        return response.data;
      } catch (error) {
        console.error("Error Project Attribute ", error);
        throw error.response.data;
      }
}

export async function ProjectAttributeSaveAPI(data,project_key) {

    let url = '/project-attribute/add';
    if(data.id){
        url = '/project-attribute/edit';
    } 

    const postData = {
        project_key:project_key,
        id:data.id,
        name:data.name,
        description:data.description
    };

    try {
        const response = await AxiosInstanceWithAuth.post(url, postData);
        return response.data;
      } catch (error) {

        console.error("Error Project Attribute ", error.response.data);
        return  error.response.data;
      }
   
}

export async function GetProjectAttributeListAPI(postData) {

    try {
        const response = await AxiosInstanceWithAuth.post("/project-attribute/list", postData);
        return response.data;
      } catch (error) {
        console.error("Error Project Attribute ", error);
        throw error.response.data;
      }
}

export async function ProjectAttributeDeletedAPI(project_key,id){

    const postData = {
        project_key:project_key,
        id:id
    };
    try {
        const response = await AxiosInstanceWithAuth.post("/project-attribute/deleted", postData);
        return response.data;
      } catch (error) {

        console.error("Error Project Attribute ", error.response.data);
        return  error.response.data;

      }
}