import AxiosInstanceWithAuth from "./AxiosInstanceWithAuth";

const getPowerUnitsList = async (pageMeta) => {
  try {
    const response = await AxiosInstanceWithAuth.post(`/power_unit/list`,
      {
        page: pageMeta.currentPage,
        limit: pageMeta.limit,
      }
    );
    return response;
  } catch (error) {
    console.error('Error while getting power unit List:', error);
    return error;
  }
}

const deletePowerUnitById = async (powerUnitId) => {
  try {
    const response = await AxiosInstanceWithAuth.post(`/power_unit/delete`,
      {
        power_unit_id: powerUnitId
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error while deleting power unit:', error);
    return error;
  }
}

const addPowerUnit = async (powerUnit) => {
  try {
    const response = await AxiosInstanceWithAuth.post("/power_unit/add", powerUnit);
    return response.data;
  } catch (error) {
    console.error("Error adding power unit:", error);
    throw error;
  }
};

const updatePowerUnit = async (id, powerUnit) => {
  try {
    const response = await AxiosInstanceWithAuth.post("/power_unit/edit", powerUnit);
    return response.data;
  } catch (error) {
    console.error("Error updating power unit:", error);
    throw error;
  }
};

const getPowerUnitById = async (powerUnitId) => {
  try {
    const response = await AxiosInstanceWithAuth.post(`/power_unit/get_power_unit_by_id`,
      {
        power_unit_id: powerUnitId
      }
    );
    
    return response.data;
  } catch (error) {
    console.error('Error fetching power unit:', error);

    return error;
  }
};

export { getPowerUnitsList, deletePowerUnitById, addPowerUnit, updatePowerUnit, getPowerUnitById };