import React, { useEffect, useState } from "react";
import { GetProjectImsSettingListAPI,ProjectImsDeletedAPI,SaveImsSettingAPI }  from "../../../../../services/SettingImsService";
import { SwatAlertConfirmDeleted,SwatAlertLodingMsg,SwatAlertSuccessTopRight,SwatAlertErrorTopRight } from '../../../../components/tms/Sweet-alert-component';
function LoadCommodityTmsSetting() {
  
  let project_key = "ims-load-commodity";
  
  const [data, setData] = useState(null);
  const [editingCells, setEditingCells] = useState([]); 
  const [tempValues, setTempValues] = useState({});
  const [loading, setLoading] = useState(true);

  const startEditing = (rowIndex, colKey, value) => {
    if(colKey!=="id") {
         setEditingCells((prev) => [...prev, { rowIndex, colKey }]);
         setTempValues((prev) => ({ ...prev, [`${rowIndex}-${colKey}`]: value }));
    }
    
  };

  const stopEditing = (rowIndex, colKey) => {
    setEditingCells((prev) =>
      prev.filter((cell) => !(cell.rowIndex === rowIndex && cell.colKey === colKey))
    );
    setTempValues((prev) => {
      const updated = { ...prev };
      delete updated[`${rowIndex}-${colKey}`];
      return updated;
    });
  };

  const saveEdit = async (rowIndex, colKey) => {

    SwatAlertLodingMsg("Please Wait...");
    const updatedData = [...data];
    updatedData[rowIndex][colKey] = tempValues[`${rowIndex}-${colKey}`];
    
    let postdata_save = updatedData[rowIndex];
    postdata_save.project_key=project_key;


    if(!postdata_save.name){
      SwatAlertErrorTopRight("Value Required");
      return false;
    }
    if(!postdata_save.grouping){
      SwatAlertErrorTopRight("Grouping Required");
      return false;
    }

    let editresponse = await SaveImsSettingAPI(postdata_save);
    if(editresponse){
        
        if(editresponse.status==="200") {
          stopEditing(rowIndex, colKey);
          
          SwatAlertSuccessTopRight("List Updated");
        } else {
          SwatAlertErrorTopRight(editresponse.message);
        } 
        //getList();
      }
  };

  
  const handleSelectChange = async(rowIndex, colKey, value) => {

       
        const updatedData = [...data];
        updatedData[rowIndex][colKey] = value;
        setData(updatedData);
        console.log(updatedData[rowIndex]);
        let postdata_save = updatedData[rowIndex];

        if(!postdata_save.name){
          SwatAlertErrorTopRight("Value Required");
          return false;
        }
        if(!postdata_save.grouping){
          SwatAlertErrorTopRight("Grouping Required");
          return false;
        }

        SwatAlertLodingMsg("Please Wait...");
        postdata_save.project_key=project_key;
        let editresponse = await SaveImsSettingAPI(postdata_save);
        if(editresponse){
            
            if(editresponse.status==="200") {
              stopEditing(rowIndex, colKey);
              getList();
              SwatAlertSuccessTopRight("List Updated");
            } else {
              SwatAlertErrorTopRight(editresponse.message);
            }
    
      }

  };
  const handleInputChange = (e, rowIndex, colKey) => {
    console.log(colKey);
    console.log(e.target.value);
    setTempValues({
      ...tempValues,
      [`${rowIndex}-${colKey}`]: e.target.value,
    });
  };
  const addNewEquipmentType = async()=> {
    SwatAlertLodingMsg("Please Wait...");
       let addresponse = await SaveImsSettingAPI({"project_key":project_key,"name":"New Value","grouping":"New Value"});
       if(addresponse){
        setEditingCells([]);
           getList();
           SwatAlertSuccessTopRight("List Added");
       
       }

  }
  const deleteRow = async (row) => {
        
      setEditingCells([]); 
       const RDeleted =   await SwatAlertConfirmDeleted();
        if(RDeleted.isConfirmed) {
            SwatAlertLodingMsg("Please Wait...");
             let DeletedResponse = await ProjectImsDeletedAPI(project_key,row.id);
             if(DeletedResponse){
                if(DeletedResponse.status==="200"){
                        getList();
                        SwatAlertSuccessTopRight('Truck Status Successully Deleted');  
                 } else {
                        SwatAlertErrorTopRight(DeletedResponse.message); 
                }
             }
             
        }
        
  }

  const isEditing = (rowIndex, colKey) =>editingCells.some((cell) => cell.rowIndex === rowIndex && cell.colKey === colKey);

  const getList = async()=> {

    let getProjectImsSettingListAPI = await GetProjectImsSettingListAPI({project_key:project_key});
    if(getProjectImsSettingListAPI){
      if(getProjectImsSettingListAPI.status==="200"){
          
           setLoading(false);
           setData(getProjectImsSettingListAPI.result);

        } else {
            SwatAlertErrorTopRight("Server Not Working"); 
        }
        
    }
   
  }

  function getDefaultValueSelect(defaultval,calkey){
      console.log(calkey);
      if(calkey===null){
        return "";
      }
      return calkey;
  }
  
  useEffect(()=>{

        getList();


  },[])
  
    return (

        <>
            <div className="card-header">
				    <button className="btn btn-primary add_new_lms" id="nav-forget-tab" type="button" onClick={()=>addNewEquipmentType()}>
				     <i className="fa fa-fw fa-plus"></i> Add New</button>                
			</div>
            <div className="card-body">
               <div className="table-responsive">    
                   <table role="table" className="table dataTable display">
                                            <thead>
                                            <tr>
                                                <th style={{width:'5%'}}>Order</th>
                                                <th style={{width:'40%'}}>Value</th>
                                                <th style={{width:'40%'}}>Grouping</th>
                                                <th style={{width:'5%'}}>Action</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                              {loading ?

                                                (
                                                    <><tr><td>Loading...</td></tr></>
                                                ):(

                                                  <>

                                            {data.map((row, rowIndex) => (
                                                <tr key={row.id}>
                                                {Object.keys(row).map(
                                                    (colKey) =>
                                                    colKey !== "0" && colKey !== "project_key" && colKey !=="load_posting_mapping" && (

                                                        <td key={colKey}>
                                                          

                                                        {isEditing(rowIndex, colKey) ? (
                                                            <div style={{float: "left",width:"100%"}}>
                                                            <input
                                                                type="text"
                                                                 className="form-control"
                                                                value={tempValues[`${rowIndex}-${colKey}`] || ""}
                                                                onChange={(e) => handleInputChange(e, rowIndex, colKey)}
                                                                autoFocus
                                                                style={{float: "left",width:"80%",borderRadius:"0.5rem 0 0 0.5rem"}}
                                                            />
                                                            <button style={{float: "left",width:"20%",padding:"12px",border:"none"}} onClick={() => saveEdit(rowIndex, colKey)}>Save</button>
                                                           
                                                            </div>
                                                        ) : (


        <>                                                  
        {colKey === "load_posting_mapping" ? (
                                                                       
            <>
            </>
                                                                                             

         ) : (
            <span style={{float:"left",width:"100%"}} onClick={() => startEditing(rowIndex, colKey, row[colKey])}>{row[colKey]}</span>
            )}
        </> 


                                                        )}


                                                        </td>
                                                       


                                                    )
                                                )}

<td>
   

     <button type="button" className="btn btn-danger shadow btn-xs sharp" onClick={() => deleteRow(row)}><i className="fa fa-trash"></i></button>

</td>
                                                </tr>
                                            ))}
                                         </>
                                          )}
                                            </tbody>
                    </table>
                </div> 
            </div>   

        </> 
        
    )
}
export default LoadCommodityTmsSetting