export const transformReducerData = (state, action) => {
    switch (action.type) {
        case "collpase14":
            return { ...state, collpase14: !state.collpase14 };
        case "collpase15":
            return { ...state, collpase15: !state.collpase15 };
        case "collpase16":
            return { ...state, collpase16: !state.collpase16 };
        case "collpase17":
            return { ...state, collpase17: !state.collpase17 };
        case "collpase18":
            return { ...state, collpase18: !state.collpase18 };
        case "collpase19":
            return { ...state, collpase19: !state.collpase19 };
        case "collpase20":
            return { ...state, collpase20: !state.collpase20 };

        case "section20":
            return { ...state, section20: !state.section20 };
        case "section21":
            return { ...state, section21: !state.section21 };
        case "section22":
            return { ...state, section22: !state.section22 };
        case "section23":
            return { ...state, section23: !state.section23 };
        case "section24":
            return { ...state, section24: !state.section24 };
        case "section25":
            return { ...state, section25: !state.section25 };
        default:
            return state;
    }
}

export const transformAPIPayload = (values) => {
    return {
        customer_id: values.id,
        customer_name: values.customer_name,
        customer_mobile_ext: values.customer_mobile_ext,
        customer_mobile: values.customer_mobile,
        customer_branch: values.customer_branch,
        customer_billing_address: values.customer_billing_address,
        customer_billing_email: values.customer_billing_email,
        customer_credit_limit: values.customer_credit_limit,
        customer_payment_terms: values.customer_payment_terms,
        customer_credit_hold: values.customer_credit_hold,
        customer_private_notes: values.customer_private_notes,
        customer_public_notes: values.customer_public_notes,
        customer_meta: values.customer_meta.map((meta, index) => {
            if (meta.id) {
                return {
                    customer_meta_id: meta.id,
                    contact_is_primary: meta.contact_is_primary,
                    contact_name: meta.contact_name,
                    contact_designation: meta.contact_designation,
                    contact_mobile: meta.contact_mobile,
                    contact_mobile_ext: meta.contact_mobile_ext,
                    contact_email: meta.contact_email,
                    contact_fax: meta.contact_fax,
                    contact_add_to_email_list: meta.contact_add_to_email_list,
                };
            } else {
                return {
                    customer_meta_id: 0,
                    user_id: values.user_id,
                    customer_id: values.id,
                    contact_is_primary: meta.contact_is_primary,
                    contact_name: meta.contact_name,
                    contact_designation: meta.contact_designation,
                    contact_mobile: meta.contact_mobile,
                    contact_mobile_ext: meta.contact_mobile_ext,
                    contact_email: meta.contact_email,
                    contact_fax: meta.contact_fax,
                    contact_add_to_email_list: meta.contact_add_to_email_list,
                };
            }
        }),
    };
};