import AxiosInstanceWithAuth from "./AxiosInstanceWithAuth";

const getCustomerList = async (pageMeta) => {
  try {
    const response = await AxiosInstanceWithAuth.post(`/customer/list`, {
      page: pageMeta.currentPage,
      limit: pageMeta.limit,
    });
    return response;
  } catch (error) {
    console.error("Error fetching customer list:", error);
    return null;
  }
};

const deleteCustomerById = async (customerId) => {
  try {
    const response = await AxiosInstanceWithAuth.post(`/customer/delete`, {
      customer_id: customerId,
    });
    return response.data;
  } catch (error) {
    console.error("Error while deleting customer:", error);
    return error;
  }
};

const addCustomer = async (customer) => {
  try {
    const response = await AxiosInstanceWithAuth.post(
      "/customer/add",
      customer
    );
    return response.data;
  } catch (error) {
    console.error("Error adding customer:", error);
    throw error;
  }
};

const updateCustomer = async (id, customer) => {
  try {
    const response = await AxiosInstanceWithAuth.post(
      "/customer/edit",
      customer
    );
    return response.data;
  } catch (error) {
    console.error("Error updating customer:", error);
    throw error;
  }
};

const getCustomerById = async (customerId) => {
  try {
    const response = await AxiosInstanceWithAuth.post(
      `/customer/get_customer_by_id`,
      {
        customer_id: customerId,
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error fetching customer:", error);
    return null;
  }
};

const deleteCustomerContact = async (customerId, contactId) => {
  try {
    const response = await AxiosInstanceWithAuth.post(
      `/customer/contact_delete`,
      {
        customer_id: customerId,
        customer_meta_id: contactId,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching customer:", error);
    return null;
  }
};

export {
  getCustomerList,
  deleteCustomerById,
  addCustomer,
  updateCustomer,
  deleteCustomerContact,
  getCustomerById,
};
