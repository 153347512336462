import React, { Fragment, useState,useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Row, Col, Card,  Tab, Nav } from "react-bootstrap";
import PageTitle from "../../../layouts/PageTitle";
import LoadStatusTmsSetting from "../setting/ims/ims-load-status";
import LoadCommodityTmsSetting from '../setting/ims/LoadCommodityTmsSetting';
import LoadPayItemsTmsSetting from '../setting/ims/LoadPayItemsTmsSetting';
import LoadTruckLengthTmsSetting from '../setting/ims/LoadTruckLengthTmsSetting';
import LoadEquipmentTmsSetting from '../setting/ims/LoadEquipmentTmsSetting';
import LoadTruckStatusTmsSetting from '../setting/ims/LoadTruckStatusTmsSetting';

function ImsSettingTMS() {

  const tabData = [
                    {name: "Load Status",slug: "load-status",icon: "truck-loading",content:<LoadStatusTmsSetting/>},
                    {name: "Commodity",slug: "commodity-status",icon: "luggage-cart",content:<LoadCommodityTmsSetting/>},
                    {name: "Pay Items",slug: "pay-items",icon: "funnel-dollar",content:<LoadPayItemsTmsSetting/>},
                    {name: "Truck Length",slug: "truck-length",icon: "truck",content:<LoadTruckLengthTmsSetting/>},
                    {name:"Truck Status",slug: "truck-status",icon: "truck-status",content:<LoadTruckStatusTmsSetting/>},
                    {name:"Equipment Type",slug: "equipment-type",icon: "truck-monster",content:<LoadEquipmentTmsSetting/>}
                 ];

    const location = useLocation();
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);
    const initialTab = queryParams.get("tab") || tabData[0].slug.toLowerCase();
    const[defaultActiveKey,setDefaultActiveKey] = useState(initialTab);
    const handleTabClick = (tabId) => {
      navigate(`?tab=${tabId}`, { replace: true });
    };


    return (
        <Fragment>
           <PageTitle motherMenu="Setting" activeMenu="Ims" />
           <Row>
                <Col xl={12}>
                    <Card>
                        <Card.Body>
                    
                           <div className="default-tab">
                                    <Tab.Container defaultActiveKey={defaultActiveKey}>
                                        <Nav as="ul" className="nav-tabs">
                                            {tabData.map((data, i) => (

                                                <Nav.Item as="li" key={i}>
                                                    <Nav.Link eventKey={data.slug.toLowerCase()} onClick={() => handleTabClick(data.slug)}>
                                                    <i className={`la la-${data.icon} me-2`} />
                                                    {data.name}
                                                    </Nav.Link>
                                                </Nav.Item>

                                            ))}
                                        </Nav>
                                        <Tab.Content className="pt-4">
                                            {tabData.map((data, i) => (
                                            <Tab.Pane eventKey={data.slug.toLowerCase()} key={i}>
                                                {data.content}
                                            </Tab.Pane>
                                            ))}
                                        </Tab.Content>
                                    </Tab.Container>
                                </div>

                        </Card.Body>    
                    </Card>
                </Col>
           </Row>
        </Fragment>
    )

}
export default ImsSettingTMS;