import React, { useState, useRef, useEffect } from 'react';
import { SketchPicker } from 'react-color';
function LoadStatusTmsSetting() {
  
  const [items, setItems] = useState([
    { id: 1, name: "Item 1", color: "#ff0000" },
    { id: 2, name: "Item 2", color: "#00ff00" },
    { id: 3, name: "Item 3", color: "#0000ff" },
  ]);

  const handleColorChange = (id, color) => {
    setItems((prevItems) =>
      prevItems.map((item) =>
        item.id === id ? { ...item, color: color } : item
      )
    );
  };

  
    return (
        <>
            <div className="card-header">
				<button className="btn btn-primary add_new_lms" id="nav-forget-tab" type="button">
				    <i className="fa fa-fw fa-plus"></i> Add New</button>                
			</div>
            <div className="card-body">
               <div className="table-responsive">    
                     <div className="dataTables_wrapper">
                       
                     <table role="table" className="table dataTable display">
                                <thead>
                                    <tr>
                                        <th>ID</th>   
                                        <th>Value</th>
                                        <th>Color</th>
                                        <th>Grouping</th>
                                        <th>Active</th>
                                        <th>Planning</th>
                                        <th>Ready For Accounting</th>
                                        <th>Misc</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>

                                       
                                </tbody>
                                </table>

                    </div>
                </div>    
            </div>     

        </>
    )

}


export default LoadStatusTmsSetting;