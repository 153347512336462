import AxiosInstanceWithAuth from "./AxiosInstanceWithAuth";

const getCarrierList = async (pageMeta) => {
  try {
    const response = await AxiosInstanceWithAuth.post(`/carrier/list`, {
      page: pageMeta.currentPage,
      limit: pageMeta.limit,
    });
    return response;
  } catch (error) {
    console.error("Error fetching Carrier list:", error);
    return null;
  }
};

const deleteCarrierById = async (carrierId) => {
  try {
    const response = await AxiosInstanceWithAuth.post(`/carrier/delete`, {
      carrier_id: carrierId,
    });
    return response.data;
  } catch (error) {
    console.error("Error while deleting carrier:", error);
    return error;
  }
};

const addCarrier = async (carrier) => {
  try {
    const response = await AxiosInstanceWithAuth.post(
      "/carrier/add",
      carrier
    );
    return response.data;
  } catch (error) {
    console.error("Error adding carrier:", error);
    throw error;
  }
};

const updateCarrier = async (id, carrier) => {
  try {
    const response = await AxiosInstanceWithAuth.post("/carrier/edit", carrier);
    return response.data;
  } catch (error) {
    console.error("Error updating carrier:", error);
    throw error;
  }
};
const getCarrierById = async (carrierId) => {
  try {
    const response = await AxiosInstanceWithAuth.post(
      `/carrier/get_carrier_by_id`,
      {
        carrier_id: carrierId,
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error fetching carrier:", error);
    return null;
  }
};

const deleteCarrierContact = async (carrierId, contactId) => {
  try {
    const response = await AxiosInstanceWithAuth.post(
      `/carrier/contact_delete`,
      {
        carrier_id: carrierId,
        carrier_meta_id: contactId,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching carrier:", error);
    return null;
  }
};

export {
  getCarrierList,
  getCarrierById,
  addCarrier,
  updateCarrier,
  deleteCarrierById,
  deleteCarrierContact,
};
