import { Fragment } from "react";
import { toast } from "react-toastify";
import swal from "sweetalert";
import AxiosInstanceWithAuth from "./AxiosInstanceWithAuth";
import Paginate from "../../jsx/components/CustomComponents/Paginate";
import Loader from "../../jsx/components/CustomComponents/Loader";
import PageTitle from "../../jsx/layouts/PageTitle";

export {
  Loader,
  Paginate,
  PageTitle
};

export const getContactMeta = (location_meta, field_name) => {
  return (
    <>
      {location_meta
        ? location_meta
            .filter((meta) => meta.contact_is_primary === 1)
            .map((meta) => (
              <Fragment key={meta.id}>{meta[field_name]}</Fragment>
            ))
        : null}
    </>
  );
};

export const truncateText = (text, length) => {
  if (text.length > length) {
    return text.substring(0, length) + "...";
  }
  return text;
};

export const confirmDelete = (itemName) => {
  return swal({
    title: "Are you sure?",
    text: `You want to delete this ${itemName}?`,
    icon: "warning",
    buttons: true,
    dangerMode: true,
  })
    .then((willDelete) => willDelete);
};

/**
 * @description
 * Handles deleting an item from the table.
 * @param {Number} itemId - The ID of the item to be deleted.
 * @param {String} itemName - The name of the item to be deleted.
 * @param {Function} deleteItemById - The API function to delete the item by ID.
 * @param {Object} items - The state of the table data.
 * @param {Function} setItems - The function to update the state of the table data.
 */
export const handleDeleteItem = async (itemId, itemName, deleteItemById, items, setItems) => {
  swal({
    title: "Are you sure?",
    text: `You want to delete this ${itemName}?`,
    icon: "warning",
    buttons: true,
    dangerMode: true,
  })
    .then((willDelete) => {
      if (willDelete) {
        deleteItemById(itemId)
          .then((itemDelete) => {
            toast.success(itemDelete.message);
            const index = items.result.findIndex((item) => item.id === itemId);
            if (index !== -1) {
              items.result.splice(index, 1);
              setItems({
                ...items,
                result: [...items.result],
              });
            }
          })
          .catch((error) => {
            console.error(error);
          });
      }
    })
    .catch((error) => {
      console.error(error);
    });
};

export const fetchAllBranchList = async () => {
  try {
    const res = await getBranchList();
    const result = res.data.result;
    var options = result.map((item) => ({
      value: item.id,
      label: item.name,
    }));
    options.unshift({ value: "", label: "Select Branch" });
    return options;
  } catch (error) {
    console.error(error);
    return [];
  }
};

const getBranchList = async () => {
  try{
    const response = await AxiosInstanceWithAuth.post(`/branch/list`,{
      project_key:"branch-department"
    });
    return response;
  }catch(error){
    console.error('Error while retrieving branch list:', error);
    return error;
  }
}

export const searchPlace = async (searchString) => {
  try {
    const response = await AxiosInstanceWithAuth.post(`location/location_search`, {
      location_search: searchString,
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getPlaceDetails = async (placeId) => {
  try {
    const response = await AxiosInstanceWithAuth.post(`location/get_place_details`, {
      place_id: placeId,
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getMakeList = async () => {
  try{
    const response = await AxiosInstanceWithAuth.post(`/power_unit/listvehiclemake`);
    return response;
  }catch(error){
    console.error('Error while retrieving make list in power unit:', error);
    return error;
  }
}