import Swal from 'sweetalert2'; 

export  async function SwatAlertConfirmDeleted(props) {

    const result =  await Swal.fire({
        title: 'Are you sure?',
        text: 'You won\'t be able to revert this!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, keep it',
        allowOutsideClick: false,
        
      });
    return result;
}
export function SwatAlertLodingMsg(props) {

    const loadingSwal = Swal.fire({
        title: 'Wait...',
        text: 'Please wait while we complete the processing....',
        icon: 'info',
        showConfirmButton: false, // Hide the confirm button
        allowOutsideClick: false, // Prevent closing the dialog by clicking outside
        didOpen: () => {
          Swal.showLoading(); // Display the loading spinner
        },
      });

}

export function SwatAlertSuccessTopRight(msg) {

    Swal.fire({

        title: 'Success!',
        text: msg,
        icon: 'success',
        position: 'top-end',  // Position the alert at top-right
        toast: true,          // Make it a toast (non-blocking)
        showConfirmButton: false, // Hide the confirm button
        timer: 3000,          // Auto close after 3 seconds (3000ms)
        timerProgressBar: true,  // Optionally show a progress bar for the timer
        
      });

}  
export function SwatAlertErrorTopRight(msg) {

  Swal.fire({

      title: 'Error!',
      text: msg,
      icon: 'error',
      position: 'top-end',  // Position the alert at top-right
      toast: true,          // Make it a toast (non-blocking)
      showConfirmButton: false, // Hide the confirm button
      timer: 3000,          // Auto close after 3 seconds (3000ms)
      timerProgressBar: true,  // Optionally show a progress bar for the timer
      
    });

} 
