import AxiosInstanceWithAuth from "./AxiosInstanceWithAuth";

const getTrailersList = async (pageMeta) => {
  try {
    const response = await AxiosInstanceWithAuth.post(`/trailers/list`,
      {
        page: pageMeta.currentPage,
        limit: pageMeta.limit,
      }
    );
    return response;
  } catch (error) {
    console.error('Error while getting trailer List:', error);
    return error;
  }
}

const deleteTrailerById = async (trailerId) => {
  try {
    const response = await AxiosInstanceWithAuth.post(`/trailers/delete`,
      {
       id: trailerId
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error while deleting trailer:', error);
    return error;
  }
}

const addTrailer = async (trailer) => {
  try {
    const response = await AxiosInstanceWithAuth.post("/trailers/add", trailer);
    return response.data;
  } catch (error) {
    console.error("Error adding trailer:", error);
    throw error;
  }
};

const updateTrailer = async (id, trailer) => {
  try {
    const response = await AxiosInstanceWithAuth.post("/trailers/edit", trailer);
    return response.data;
  } catch (error) {
    console.error("Error updating trailer:", error);
    throw error;
  }
};

const getTrailerById = async (trailerId) => {
  try {
    const response = await AxiosInstanceWithAuth.post(`/trailers/get_trailer_by_id`,
      {
        trailer_id: trailerId
      }
    );
    
    return response.data;
  } catch (error) {
    console.error('Error fetching trailer:', error);

    return error;
  }
};

export { getTrailersList, deleteTrailerById, addTrailer, updateTrailer, getTrailerById };