import React from 'react';
import { useFormikContext } from 'formik';

const CustomSelect = ({ name, options, ...props }) => {
  const { values, handleChange } = useFormikContext();

  return (
    <select
      name={name}
      value={values[name]}
      onChange={handleChange}
      {...props}
    >
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );
};

export default CustomSelect;