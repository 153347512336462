import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required("Name is required")
    .min(2, "Name must be at least 2 characters long"),

  phone_number: Yup.string()
    .required("Phone Number is required")
    .matches(/^[0-9]+$/, "Invalid phone number format")
    .min(8, "Phone number must be at least 8 characters long")
    .max(15, "Phone number must not exceed 15 characters"),

});

export default validationSchema;
