import React, { useMemo, useReducer } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import validationSchema from "./helpers/CustomerValidationSchema";
import {
  PageTitle,
  fetchAllBranchList,
  searchPlace,
  getPlaceDetails,
} from "../../../../services/CustomServices/CommonService";
import {
  addCustomer,
  updateCustomer,
  getCustomerById,
  deleteCustomerContact,
} from "../../../../services/CustomServices/CustomerService";
import { Link, useParams, useNavigate } from "react-router-dom";
import { Collapse } from "react-bootstrap";
import NoImage from "../../../../images/no-image.jpg";
import { toast } from "react-toastify";
import CustomSelect from "../../../components/CustomComponents/Select";
import Select from "react-select";
import ContactList from "../../../components/CustomComponents/ContactList";
import { transformAPIPayload, transformReducerData } from "./helpers/function";
import {
  initialValues,
  screenOption,
  /* allBranches, */
} from "./helpers/constant";

const debug = false;
const AddCustomer = () => {
  const [state, dispatch] = useReducer(transformReducerData, true);
  const [file, setFile] = React.useState(null);
  const { customerId } = useParams(); // get the customer ID from url
  const [customer, setCustomer] = React.useState(null);
  const [allBranchesOptions, setallBranchesOptions] = React.useState([]);

  const [options, setOptions] = React.useState([]);
  const [searchTerm, setSearchTerm] = React.useState("");
  const [selectedOption, setSelectedOption] = React.useState({
    value: "",
    label: "",
  });

  const handleInputChange = (inputValue) => {
    setSearchTerm(inputValue);
    if (inputValue.length > 2) {
      searchPlace(inputValue).then((res) => {
        setOptions(
          res.result.map((place) => ({
            label: place.address,
            value: place.place_id,
          }))
        );
      });
    }
  };

  const handleSelectChange = (selectedOption, setFieldValue) => {
    setSelectedOption(selectedOption);

    getPlaceDetails(selectedOption.value).then((res) => {
      const response = res.result;
      setFieldValue("customer_name", response.name ?? "");
      setFieldValue(
        "customer_billing_address",
        response.formatted_address ?? ""
      );
      setFieldValue("customer_mobile", response.formatted_phone_number ?? "");
    });
  };

  const customer_meta = customer && customer.customer_meta;
  const navigate = useNavigate();
  const initialState = useMemo(() => customer || initialValues, [customer]);

  React.useEffect(() => {
    fetchAllBranchList().then((options) => {
      setallBranchesOptions(options);
    });
  }, []);

  React.useEffect(() => {
    if (customerId) {
      const fetchCustomer = async () => {
        if (customerId) {
          try {
            const customerData = await getCustomerById(customerId);
            if (
              customerData &&
              customerData.result &&
              customerData.result.length
            ) {
              setCustomer(customerData.result[0]);
            }
          } catch (error) {
            console.error("Error fetching customer:", error);
          }
        }
      };
      fetchCustomer();
    } else {
      setCustomer(initialValues);
    }
  }, [customerId]);

  const onDeleteContact = async (index) => {
    if (
      !customer ||
      !customer.customer_meta ||
      !customer.customer_meta[index]
    ) {
      console.warn(
        "Customer or customer_meta is null or index is out of bounds"
      );
      return;
    }
    const contactId = customer.customer_meta[index].id;
    if (contactId) {
      const contactDataResponse = await deleteCustomerContact(
        customer.id,
        contactId
      );
      /* toast.success("Customer Contact deleted successfully!"); */
      toast.success(contactDataResponse.message);
    }

    // Update the customer state
    const updatedCustomer = { ...customer };
    updatedCustomer.customer_meta = customer.customer_meta.filter(
      (contact, i) => i !== index
    );
    setCustomer(updatedCustomer);
  };

  const fileHandler = (e) => {
    setFile(e.target.files[0]);
  };

  const onSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);
    if (customer && customer.id) {
      try {
        const payload = transformAPIPayload(values);
        const customerDataRessponse = await updateCustomer(
          customer.id,
          payload
        );
        toast.success(customerDataRessponse.message);
        navigate("/customers/customer-list", { replace: true });
      } catch (error) {
        console.error("Error updating customer:", error);
        toast.error("Error updating customer!");
      }
    } else {
      try {
        const customerData = await addCustomer(values);
        toast.success(customerData.message);
        // Navigate to CustomerList page after 2 seconds
        setTimeout(() => {
          navigate("/customers/customer-list", { replace: true });
        }, 2000);
      } catch (error) {
        console.error("Error submitting form:", error);
        toast.error("Error adding customer!");
        throw error;
      }
    }
    setSubmitting(false);
  };

  return (
    <Formik
      initialValues={initialState}
      validationSchema={validationSchema}
      enableReinitialize // This ensures form is updated with customer data when editing
      validateOnBlur={false}
      validateOnChange={true}
      validateOnMount={true}
      onSubmit={async (values, { setSubmitting, validateForm }) => {
        const errors = await validateForm(values);
        if (Object.keys(errors).length === 0) {
          onSubmit(values, { setSubmitting });
        } else {
          console.log("Form is not valid:", errors);
        }
      }}
    >
      {({
        dirty,
        isSubmitting,
        handleSubmit,
        errors,
        setFieldValue,
        values,
      }) => (
        <Form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-xl-12">
              <PageTitle
                activeMenu={customerId ? "Edit Customer" : "Add Customer"}
                motherMenu="Customers"
              />
              <div className="mb-3">
                <ul className="d-flex align-items-center flex-wrap">
                  <li>
                    <Link
                      to={"/customers/customer-list"}
                      className="btn btn-primary btn-sm mx-1"
                    >
                      Customer List
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={"#"}
                      className="btn btn-primary btn-sm open mt-1 mt-md-0"
                      onClick={() => dispatch({ type: "collpase20" })}
                    >
                      Screen Option
                    </Link>
                  </li>
                </ul>
              </div>
              <Collapse in={state.collpase20}>
                <div className="main-check py-2">
                  <div className="row">
                    <h4 className="mb-3">Show on screen</h4>
                    {screenOption.map((item, ind) => (
                      <div className="col-xl-2 col-lg-3 col-sm-4" key={ind}>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            id={`flexCheckDefault-${item.series}`}
                            onChange={() => {
                              dispatch({ type: `section${item.id}` });
                            }}
                            defaultChecked
                          />
                          <label
                            className="form-check-label mb-0 text-nowrap"
                            htmlFor={`flexCheckDefault-${item.series}`}
                          >
                            {item.title}
                          </label>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </Collapse>
              <div className="row">
                <div className="col-xl-8 col-xxl-12">
                  {!state.section20 && (
                    <div className="filter cm-content-box box-primary">
                      <div className="content-title py-0">
                        <div className="cpa">Find Customer</div>
                        <div className="tools">
                          <Link
                            to={"#"}
                            className={`SlideToolHeader ${
                              state.collpase14 ? "collapse" : "expand"
                            }`}
                            onClick={() => dispatch({ type: "collpase14" })}
                          >
                            <i className="fas fa-angle-up"></i>
                          </Link>
                        </div>
                      </div>
                      <Collapse in={!state.collpase14}>
                        <div className="cm-content-body form excerpt">
                          <div className="card-body py-3">
                            <label className="from-label">
                              Find a customer
                            </label>
                            <Select
                              options={options}
                              onInputChange={handleInputChange}
                              onChange={(selectedOption) =>
                                handleSelectChange(
                                  selectedOption,
                                  setFieldValue
                                )
                              }
                              placeholder="Search for a Customer..."
                            />
                            <div className="form-text">
                              Please enter the name or address you want to
                              search for in the box above, and we'll find the
                              best match from the internet.
                            </div>
                          </div>
                        </div>
                      </Collapse>
                    </div>
                  )}
                  {!state.section21 && (
                    <div className="filter cm-content-box box-primary">
                      <div className="content-title py-0">
                        <div className="cpa">Customer Address</div>
                        <div className="tools">
                          <Link
                            to={"#"}
                            className={`SlideToolHeader ${
                              state.collpase15 ? "collapse" : "expand"
                            }`}
                            onClick={() => dispatch({ type: "collpase15" })}
                          >
                            <i className="fas fa-angle-up"></i>
                          </Link>
                        </div>
                      </div>
                      <Collapse in={!state.collpase15}>
                        <div className="cm-content-body form excerpt">
                          <div className="card-body py-3">
                            <div className="row">
                              <div className="col-xl-6 col-sm-6">
                                <label className="form-label">
                                  Customer Name{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <Field
                                  type="text"
                                  name="customer_name"
                                  className="form-control form-control-sm mb-2"
                                  placeholder="Customer Name"
                                />
                                <ErrorMessage
                                  name="customer_name"
                                  component="div"
                                  className="text-danger form-text mb-2"
                                />
                              </div>
                              <div className="col-xl-6 col-sm-6">
                                <label className="form-label">
                                  Billing Email{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <div className="input-group mb-2">
                                  <Field
                                    type="text"
                                    name="customer_billing_email"
                                    className="form-control form-control-sm"
                                    placeholder="Billing Email"
                                  />
                                  <span className="input-group-text">
                                    @example.com
                                  </span>
                                </div>
                                <ErrorMessage
                                  name="customer_billing_email"
                                  component="div"
                                  className="text-danger form-text mb-2"
                                />
                              </div>
                              <div className="col-xl-6 col-sm-6">
                                <label className="form-label">
                                  Mobile & Ext.
                                </label>
                                <div className="input-group mb-2">
                                  <Field
                                    type="text"
                                    name="customer_mobile"
                                    className="form-control form-control-sm"
                                    placeholder="Mobile"
                                  />
                                  <Field
                                    type="text"
                                    name="customer_mobile_ext"
                                    className="form-control form-control-sm"
                                    placeholder="Ext."
                                  />
                                </div>
                                <ErrorMessage
                                  name="customer_mobile"
                                  component="div"
                                  className="text-danger form-text mb-2"
                                />
                              </div>
                              <div className="col-xl-6 col-sm-6">
                                <label className="form-label">
                                  Branch Name
                                </label>
                                <div className="input-group mb-2">
                                  <label className="input-group-text mb-0">
                                    Branch
                                  </label>
                                  <CustomSelect
                                    name="customer_branch"
                                    options={allBranchesOptions}
                                    className="form-control form-control-sm wide"
                                  />
                                </div>
                              </div>
                              <div className="col-xl-12 col-md-12 col-sm-6">
                                <label className="form-label">
                                  Billing Address{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <Field
                                  as="textarea"
                                  name="customer_billing_address"
                                  className="form-control form-control-sm"
                                  placeholder=""
                                  rows="3"
                                />
                                <ErrorMessage
                                  name="customer_billing_address"
                                  component="div"
                                  className="text-danger form-text mb-2"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </Collapse>
                    </div>
                  )}
                  {!state.section22 && (
                    <div className="filter cm-content-box box-primary">
                      <div className="content-title py-0">
                        <div className="cpa">Contact List</div>
                        <div className="tools">
                          <Link
                            to={"#"}
                            onClick={() => dispatch({ type: "collpase16" })}
                            className={`SlideToolHeader ${
                              state.collpase16 ? "collapse" : "expand"
                            }`}
                          >
                            <i className="fas fa-angle-up"></i>
                          </Link>
                        </div>
                      </div>
                      <Collapse in={!state.collpase16}>
                        <div className="cm-content-body form excerpt">
                          <div className="card-body py-3">
                            <div className="row">
                              <div className="col px-1">
                                <label className="from-label">
                                  Contact Name
                                </label>
                              </div>

                              <div className="col px-1">
                                <label className="from-label">
                                  Designation
                                </label>
                              </div>

                              <div className="col px-1">
                                <label className="from-label">
                                  Mobile & Ext.
                                </label>
                              </div>

                              <div className="col px-1">
                                <label className="from-label">Email</label>
                              </div>

                              <div className="col px-1">
                                <label className="from-label">Fax</label>
                              </div>

                              <div className="col px-1">
                                <label className="from-label">
                                  Add to Email List
                                </label>
                              </div>
                            </div>

                            <ContactList
                              metaList={customer_meta}
                              name="customer_meta"
                              values={values}
                              errors={values}
                              placeholder="Contact List"
                              label="Contact List"
                              onDeleteContact={onDeleteContact}
                              setFieldValue={setFieldValue}
                            />
                            <span className="mt-3 d-block">
                              Custom fields can be used to extra metadata to a
                              post that you can use in your theme.
                            </span>
                          </div>
                        </div>
                      </Collapse>
                    </div>
                  )}
                </div>
                <div className="col-xl-4 col-xxl-12">
                  {!state.section23 && (
                    <div className="filter cm-content-box box-primary">
                      <div className="content-title py-0">
                        <div className="cpa">Accounting</div>
                        <div className="tools">
                          <Link
                            to={"#"}
                            onClick={() => dispatch({ type: "collpase17" })}
                            className={`SlideToolHeader ${
                              state.collpase17 ? "collapse" : "expand"
                            }`}
                          >
                            <i className="fas fa-angle-up"></i>
                          </Link>
                        </div>
                      </div>
                      <Collapse in={!state.collpase17}>
                        <div className="cm-content-body form excerpt">
                          <div className="card-body py-3">
                            <div className="row">
                              <div className="col-md-6 px-1">
                                <label className="from-label">
                                  Credit Limit
                                </label>
                                <Field
                                  type="number"
                                  name="customer_credit_limit"
                                  className="form-control form-control-sm px-1"
                                />
                                <div className="form-text">
                                  Enter this customer's credit limit
                                </div>
                              </div>
                              <div className="col-md-6 px-1">
                                <label className="from-label">
                                  Payment Terms
                                </label>
                                <div className="input-group mb-3">
                                  <Field
                                    type="text"
                                    name="customer_payment_terms"
                                    className="form-control form-control-sm w-50 p-1"
                                  />
                                  <span className="input-group-text">days</span>
                                  <div className="form-text">
                                    Enter this customer's default payment terms
                                    in days
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-12 px-1">
                                <label className="from-label">
                                  Credit Hold
                                </label>
                                <div className="form-group mb-0">
                                  <label className="radio-inline me-3">
                                    <Field
                                      className="form-check-input"
                                      type="radio"
                                      name="customer_credit_hold"
                                      value="Enabled"
                                    />{" "}
                                    Credit Hold Enabled
                                  </label>
                                  <label className="radio-inline me-3">
                                    <Field
                                      className="form-check-input"
                                      type="radio"
                                      name="customer_credit_hold"
                                      value="Disabled"
                                    />{" "}
                                    Credit Hold Disabled
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Collapse>
                    </div>
                  )}

                  {!state.section24 && (
                    <div className="filter cm-content-box box-primary">
                      <div className="content-title py-0">
                        <div className="cpa">Notes (Optional)</div>
                        <div className="tools">
                          <Link
                            to={"#"}
                            onClick={() => dispatch({ type: "collpase18" })}
                            className={`SlideToolHeader ${
                              state.collpase18 ? "collapse" : "expand"
                            }`}
                          >
                            <i className="fas fa-angle-up"></i>
                          </Link>
                        </div>
                      </div>
                      <Collapse in={!state.collpase18}>
                        <div className="cm-content-body form excerpt">
                          <div className="card-body py-3">
                            <div className="row">
                              <div className="col-xl-12 col-md-12 col-sm-6">
                                <label className="form-label">
                                  Private Notes{" "}
                                </label>
                                <Field
                                  as="textarea"
                                  name="customer_private_notes"
                                  className="form-control form-control-sm"
                                  placeholder="Private Notes"
                                  rows="3"
                                />
                                <div className="form-text">
                                  This note is private and viewable only by your
                                  organization.
                                </div>
                              </div>

                              <div className="col-xl-12 col-md-12 col-sm-6 mt-3">
                                <label className="form-label">
                                  Public Notes{" "}
                                </label>
                                <Field
                                  as="textarea"
                                  name="customer_public_notes"
                                  className="form-control form-control-sm"
                                  placeholder="Public Notes"
                                  rows="3"
                                />
                                <div className="form-text">
                                  This note used to add customer specific notes
                                  to the load notes on your load documents when
                                  this customer is added to a load.
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Collapse>
                    </div>
                  )}

                  {/* {!state.section25 && (
                    <div className="filter cm-content-box box-primary">
                      <div className="content-title py-0">
                        <div className="cpa">Customer Image</div>
                        <div className="tools">
                          <Link
                            to={"#"}
                            className={`SlideToolHeader ${
                              state.collpase19 ? "collapse" : "expand"
                            }`}
                            onClick={() => dispatch({ type: "collpase19" })}
                          >
                            <i className="fas fa-angle-up"></i>
                          </Link>
                        </div>
                      </div>
                      <Collapse in={!state.collpase19}>
                        <div className="cm-content-body publish-content form excerpt">
                          <div className="card-body py-3">
                            <div className="avatar-upload d-flex align-items-center">
                              <div className=" position-relative ">
                                <div className="avatar-preview">
                                  <div
                                    id="imagePreview"
                                    style={{
                                      backgroundImage: file
                                        ? "url(" +
                                          URL.createObjectURL(file) +
                                          ")"
                                        : "url(" + NoImage + ")",
                                    }}
                                  ></div>
                                </div>
                                <div className="change-btn d-flex align-items-center flex-wrap">
                                  <input
                                    type="file"
                                    onChange={fileHandler}
                                    id="imageUpload"
                                    className="d-none"
                                  />
                                  <label
                                    htmlFor="imageUpload"
                                    className="btn btn-light ms-0"
                                  >
                                    Select Image
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Collapse>
                    </div>
                  )} */}

                </div>
              </div>
            </div>
          </div>

          <div className="form-group mt-3">
            <button
              type="submit"
              className="btn btn-primary"
              disabled={!dirty || isSubmitting}
              onClick={() => {
                if (Object.keys(errors).length > 0) {
                  const firstErrorField = Object.keys(errors)[0];
                  if (Array.isArray(errors[firstErrorField])) {
                    const firstErrorObject = errors[firstErrorField].find(
                      (obj) =>
                        obj !== null &&
                        obj !== undefined &&
                        Object.keys(obj || {}).length > 0
                    );
                    if (firstErrorObject) {
                      const firstErrorKey = Object.keys(firstErrorObject)[0];
                      const errorIndex =
                        errors[firstErrorField].indexOf(firstErrorObject);
                      const errorFieldName = `${firstErrorField}.${errorIndex}.${firstErrorKey}`;
                      const element = document.querySelector(
                        `[name="${errorFieldName}"]`
                      );
                      if (element) {
                        element.focus();
                      }
                    }
                  } else {
                    const element =
                      document.getElementsByName(firstErrorField)[0];
                    if (element) {
                      element.focus();
                    }
                  }
                }
              }}
            >
              Submit
            </button>
            {debug && (
              <>
                <pre style={{ textAlign: "left" }}>
                  <strong>Values</strong>
                  <br />
                  {JSON.stringify(values, null, 2)}
                </pre>
                <pre style={{ textAlign: "left" }}>
                  <strong>Errors</strong>
                  <br />
                  {JSON.stringify(errors, null, 2)}
                </pre>
              </>
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default AddCustomer;
