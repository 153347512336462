import React, { useState, useEffect } from 'react';
import { Button, Modal } from "react-bootstrap";
import PageTitle from "../../../layouts/PageTitle";
import { SwatAlertConfirmDeleted,SwatAlertLodingMsg,SwatAlertSuccessTopRight,SwatAlertErrorTopRight } from '../../../components/tms/Sweet-alert-component';
import { ProjectAttributeSaveAPI,GetProjectAttributeListAPI,ProjectAttributeDeletedAPI } from '../../../../services/SettingService';
function SettingBranchesTMS() {

    const [branchList, setBranchList] = useState(null);
    const [loading, setLoading] = useState(true);
    const [addEditBranchModal, setAddEditBranchModal] = useState(false);
    const [errorMsg,setErrorMsg] = useState(null);
    const [formValues, setFormValues] = useState({
        name: '',
        description: ''
      });

    const fetchData = async () => {

        let postData = {};
        postData.project_key = "branch-department";
        const ListResponse = await GetProjectAttributeListAPI(postData);
        if(ListResponse){
            setBranchList(ListResponse.result); 
            setLoading(false);
        }
    };

    const handleDelete = async (id) => {
        const result =   await SwatAlertConfirmDeleted();
        if(result.isConfirmed) {
            SwatAlertLodingMsg("Please Wait...");
             const DeletedResponse = await ProjectAttributeDeletedAPI('branch-department',id);
             if(DeletedResponse){
                if(DeletedResponse.status==="200"){
                        fetchData();
                        SwatAlertSuccessTopRight('Branch Successully Deleted');  
                 } else {
                        SwatAlertErrorTopRight(DeletedResponse.message); 
                }
             }
        }
    };

    const handleAddEdit = async(data)=> {
        setErrorMsg(null);
        setFormValues(data);
        setAddEditBranchModal(true); // Open the modal

    }
    const addnewBranch = async()=> {
        
        setErrorMsg(null);
        setFormValues({name:'',description:''});
        setAddEditBranchModal(true); // Open the modal

    }


    const  handleSubmit = async (e) => {
        e.preventDefault();
        if(!formValues.name){
            setErrorMsg('Name Required');
            return false;
        }
        if(!formValues.description){
            setErrorMsg('Description Required');
            return false;
        }
        setAddEditBranchModal(false); // Open the modal
        SwatAlertLodingMsg("Please Wait...");
        
        const SaveResponse = await ProjectAttributeSaveAPI(formValues,'branch-department');
        if(SaveResponse) {
            if(SaveResponse.status==="200"){
                SwatAlertSuccessTopRight(SaveResponse.message); 
            } else {
                SwatAlertErrorTopRight(SaveResponse.message); 
            }
        }
       

        fetchData();
         
    };
    const handleInputChange = (event) => {
       
        const { name, value } = event.target;
        setFormValues({
        ...formValues,  // Spread the previous state
        [name]: value   // Dynamically update the specific field
        });
    
      };
    useEffect(() => {
        fetchData();
    },[]);

    

    return (
        <>
            <PageTitle motherMenu="Setting" activeMenu="Branches" />
            <div className="card">
				<div className="card-header">
                     <button onClick={() => addnewBranch()} className="btn btn-primary add_new" id="nav-forget-tab" type="button" data-bs-toggle="modal" data-bs-target="#branchModal">
                        	<i className="fa fa-fw fa-plus"></i> Add New Branch
                        </button>
                </div>
                <div className="card-body">
					<div className="table-responsive">
                           <div className="dataTables_wrapper">
                                <table role="table" className="table dataTable display">
                                    <thead>
                                         <tr>
                                            <th>#</th>
                                            <th>Branch Name</th>
                                            <th>Branch Description</th>
                                            <th>Action</th>
                                            </tr>    
                                    </thead>

                                        {loading ?

                                            (

                                                <tbody><tr><td colaspane="5">Loading.....  </td></tr></tbody>
                                            )  
                                             :
                                            (
                                                <tbody>

                                                        {branchList.map((data,key)=>(
                                                        

                                                        <tr key={key}>

                                                            <td>{key+1}</td>
                                                            <td>{data.name}</td>
                                                            <td>
                                                                {data.description}
                                                                {/*
                                                                <ProjectMeta project_meta={data.project_meta} key_name="description" />
                                                                

                                                                */}
                                                            </td>

                                                            <td>
                                                            {data.type==="default" ?
                                                                (
                                                                     <p></p>   
                                                                ): (
                                                                    <p>
                                                                    <button  onClick={() => handleAddEdit(data)}  type="button" className="btn btn-primary shadow btn-xs sharp me-1"> 
                                                                    <i className="bi bi-pencil-square"></i>
                                                                </button>
    
                                                                <button onClick={() => handleDelete(data.id)} type="button" className="btn btn-danger shadow btn-xs sharp">
                                                                    <i className="fa fa-trash"></i>
                                                                </button>
                                                                    </p>
                                                                

                                                                )
                                                                
                                                            }            

                                                            
                                                            
                                                            </td>
                                                         </tr>         

                                                        )
                                                    )}

                                                </tbody>

                                            )    

                                        }

                                        
                                </table>    
                           </div>
                    </div>
                </div>

                <Modal className="fade bd-example-modal-lg" show={addEditBranchModal} size="lg" >
                    <Modal.Header>
                        <Modal.Title>Branch/Department</Modal.Title>
                    <Button variant="" className="btn-close" onClick={() => setAddEditBranchModal(false)} > </Button>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <form onSubmit={handleSubmit}>
                                <div className="row">

                                <div className="col-xl-12">
                                        <div className="form-group mb-3 row">
                                        <label className="col-lg-4 col-form-label" htmlFor="Name">Branch / Department Name<span className="text-danger">*</span></label>
                                            <div className="col-lg-8">
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="name"
                                                name="name"
                                                placeholder=""
                                                value={formValues.name || ''}
                                                onChange={handleInputChange}
                                            />
                                            </div>  
                                        </div>
                                </div> 

                                <div className="col-xl-12">
                                        <div className="form-group mb-3 row">
                                        <label className="col-lg-4 col-form-label" htmlFor="Name">Description<span className="text-danger">*</span></label>
                                            <div className="col-lg-8">
                                            <textarea
                                                type="text"
                                                className="form-control"
                                                id="description"
                                                name="description"
                                                placeholder=""
                                                value={formValues.description || ''}
                                                onChange={handleInputChange}
                                            />
                                            </div>  
                                        </div>
                                </div> 


 {errorMsg &&
                                        
                            
<div role="alert" className="fade alert alert-danger alert-dismissible show">
    <strong>Error! </strong> {errorMsg}
</div>

 } 
                                </div> 

                                <div className='modal-footer'>
                                     <button type="submit" className="btn btn-primary"  >Save</button>
                                    
                                </div>                  
                            </form>    
                        </div>                
                    </Modal.Body>  

                    

                 </Modal>

            </div>    
        </>
    );


}

export default SettingBranchesTMS;
