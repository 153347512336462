import AxiosInstanceWithAuth from './CustomServices/AxiosInstanceWithAuth';
export async function SaveImsSettingAPI(postData) {

    let url =   '/project-ims-setting/add';
    if(postData.id) {
        url =   '/project-ims-setting/edit';
    }

    try {
        const response = await AxiosInstanceWithAuth.post(url, postData);
        return response.data;
      } catch (error) {
        console.error("Error Project Attribute ", error);
        throw error.response.data;
      }
}

export async function GetProjectImsSettingListAPI(postData) {

   
    try {
        const response = await AxiosInstanceWithAuth.post("/project-ims-setting/list", postData);
        return response.data;
      } catch (error) {
        console.error("Error Project Attribute ", error);
        throw error.response.data;
      }

}

export async function ProjectImsDeletedAPI(project_key,id){

    try {
        const postData = {
            project_key:project_key,
            id:id
        };
        
        const response = await AxiosInstanceWithAuth.post("/project-ims-setting/deleted", postData);
        return response.data;
      } catch (error) {
        console.error("Error Project Attribute ", error);
        throw error.response.data;
      }

}
