export const screenOption = [
  { id: "21", title: "Carrier Address", series: "27" },
  { id: "20", title: "Carrier Details", series: "26" },
  { id: "32", title: "Carrier Insurance", series: "33" },
  { id: "22", title: "Contact List", series: "28" },
  {
    id: "23",
    title: "Customize Units of Measurement (Optional)",
    series: "29",
  },
  { id: "24", title: "Notes (Optional)", series: "30" },
  { id: "25", title: "Carrier Image", series: "31" },
];

export const allBranches = [
    { value: "", label: "Select Branch", color: "#FF8B00" },
    { value: "1", label: "Shared", color: "#FF8B00" },
    { value: "2", label: "Only Me", color: "#FFC400" },
    { value: "3", label: "My Team", color: "#36B37E" },
  ];
  
export const carrier_authority_type_options = [
    { value: 'mc', label: 'MC' },
    { value: 'ff', label: 'FF' },
    { value: 'mx', label: 'MX' }
  ];
  
export const carrier_payment_method_options = [
    { value: '', label: 'Select method' },
    { value: 'standard_pay', label: 'Standard Pay' },
    { value: 'quick_pay', label: 'Quick Pay' },
    { value: 'pay_when_paid', label: 'Pay When Paid' }
  ];
  
export const carrier_weight_unit_options = [
    { value: '', label: 'Use my company\'s default setting' },
    { value: 'lbs', label: 'Pounds' },
    { value: 'kg', label: 'Kilograms' }
  ];
  
export const carrier_distance_unit_options = [
    { value: '', label: 'Use my company\'s default setting' },
    { value: 'mi', label: 'Miles' },
    { value: 'km', label: 'Kilometers' }
  ];
  
export const carrier_temprature_unit_options = [
    { value: '', label: 'Use my company\'s default setting' },
    { value: 'f', label: 'Fahrenheit' },
    { value: 'c', label: 'Celsius' }
  ];

  export const initialContactValue = {
    contact_is_primary: 1,
    contact_name: "",
    contact_designation: "",
    contact_mobile: "",
    contact_mobile_ext: "",
    contact_email: "",
    contact_fax: "",
    contact_add_to_email_list: "",
  };

  export const initialValues = {
    carrier_name: "",
    carrier_mobile: "",
    carrier_mobile_ext: "",
    carrier_branch: "",
    carrier_ok_to_load: "1",
    carrier_address: "",
    carrier_checks_payable_to: "",
    // Carrier Details
    carrier_authority_type: "",
    carrier_mc_ff_mx_number: "",
    carrier_usdot_number: "",
    carrier_tax_id_number: "",
    carrier_payment_terms: "",
    carrier_payment_method: "",
    carrier_1099_vendor: "",
    carrier_document: "",
    // Carrier Insurance
    carrier_primary_insurance_details: "",
    carrier_primary_insurance_expiration: "",
    carrier_cargo_insurance_details: "",
    carrier_cargo_insurance_expiration: "",
    // Customize Units of Measurement (Optional)
    carrier_weight_unit: "",
    carrier_distance_unit: "",
    carrier_temprature_unit: "",
    // Notes (Optional)
    carrier_private_notes: "",
    // Contact List
    carrier_meta: [initialContactValue],
  } 