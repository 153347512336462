import React, { useState,useEffect } from 'react';
import axios from 'axios';
import Select from 'react-select';
import { SaveUserAPI } from '../../../../services/SettingService';
import { SwatAlertConfirmDeleted,SwatAlertLodingMsg,SwatAlertSuccessTopRight,SwatAlertErrorTopRight } from '../../../components/tms/Sweet-alert-component';
// Define the options for the select input







const AddEditUserComponent = ({data,branchList,permissionList,ParentChildController}) => {


  const permission_groups = permissionList;
  //const defaultPermission = ['1', '4']; 
  let defaultPermission =[]; 
  let defaultPermissionGroup = [];
  const options = branchList;
  let defaultValues=[];

  let defaultOptions=[];
  if(data.branches_departments) {

    let branches_departments_list = data.branches_departments;
    if(typeof branches_departments_list === "string") {
      defaultValues = branches_departments_list.split(',').map(item => item.trim());
      defaultOptions = options.filter(option => defaultValues.includes(option.value));
    }
    

  } else {
     defaultOptions = [options.find(user => user.label == "Shared")];
    
  }

  if(data.permission_groups) {

    let permission_groups_list = data.permission_groups;
    if(typeof permission_groups_list === "string") {
      let defaultValues1 = permission_groups_list.split(',').map(item => item.trim());
      defaultPermissionGroup = permission_groups.filter(option => defaultValues1.includes(option.value));
    }

  } else {
    defaultPermissionGroup = [permission_groups.find(user => user.label == "Admin")];
  }
  console.log(defaultPermission);
    


 // const defaultPermissionGroup = permission_groups.filter(permission_groups => defaultPermission.includes(permission_groups.value));

  const [selectedOptions, setSelectedOptions] = useState(defaultOptions);
  const [selectedPermissionGroups, setselectedPermissionGroups] = useState(defaultPermissionGroup);
  const [errorMsg , setErrorMsg] = useState(null);

  

  function LableHeading(props){

    return (
  
      <label className="col-lg-4 col-form-label" htmlFor={props.name}>
          {props.name}
          {props.required_ico &&
              <span className="text-danger">*</span>

          }
      
      </label>
  
    );
  }


  
  const handleChange = (selected,inputKey) => {

      if(inputKey=="permission_group") {

       
        setselectedPermissionGroups(selected || []); // Update state with selected options
        if(selected.length == 0) {
          setselectedPermissionGroups([{value:'1',label:'Permission 1'}]);
        }

      } else {

        

        setSelectedOptions(selected || []); // Update state with selected options

       
       

        const matchedBranch = options.find(user => user.label == "Shared");
         
        
        

        setSelectedOptions((prevSelected) => {
          // Only add newOption if it doesn't already exist in prevSelected
          if (!prevSelected.some(option => option.value === matchedBranch.value)) {
            return [...prevSelected, matchedBranch];
          }
          return prevSelected; // Return the original state if already present
        });


      }
        

        
   

  };

  // Handle form submission
  const handleSubmit = async (e) => {

   console.log(formValues);
    e.preventDefault();

    if(!formValues.name) {
      setErrorMsg("Name Required");
      return false;
    }
    if(!formValues.email) {
      setErrorMsg("Email Required");
      return false;
    }
    if(!formValues.phone_number) {
      setErrorMsg("Phone Number Required");
      return false;
    }


    if(!formValues.id) {

      if(!formValues.password) {
        setErrorMsg("Password Required");
        return false;
      }

    }

    

   

    const branches_departments = selectedOptions.map(option => option.value);
    const permission_groups = selectedPermissionGroups.map(option => option.value);

    formValues.permission_groups=permission_groups;
    formValues.branches_departments=branches_departments;

    

    setErrorMsg("Please Wait...");
    SwatAlertLodingMsg("Please Wait...");
    let SaveResponse = await SaveUserAPI(formValues);
    if(SaveResponse) {
      if(SaveResponse.status==="200"){
          SwatAlertSuccessTopRight(SaveResponse.message); 
          ParentChildController();
      } else {

          setErrorMsg(SaveResponse.message);
          SwatAlertErrorTopRight(SaveResponse.message); 

      }
      
  }

  };

  const [formValues, setFormValues] = useState({
    name: '',
    email: '',
    phone_number: '',
    password:'',
    permission_groups:'',
    branches_departments:'',
    testing_group:''
  });

  const handleInputChange = (event) => {
       
    const { name, value } = event.target;
    setFormValues({
    ...formValues,  // Spread the previous state
    [name]: value   // Dynamically update the specific field
    });

  };

  useEffect(() => {
    setFormValues(data);
  },[data]);



  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className="row">

           <div className="col-xl-12">
              <div className="form-group mb-3 row">
                <LableHeading name="Name" required_ico="yes"/>
                <div className="col-lg-8">
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    name="name"
                    placeholder=""
                    value={formValues.name || ''}
                    onChange={handleInputChange}
                  />
                </div>  
              </div>
            </div>

            <div className="col-xl-12">
              <div className="form-group mb-3 row">
                <LableHeading name="Email" required_ico="yes"/>
                <div className="col-lg-8">
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    name="email"
                    placeholder=""
                    value={formValues.email || ''}
                    onChange={handleInputChange}
                  />
                </div>  
              </div>
            </div>

            <div className="col-xl-12">
              <div className="form-group mb-3 row">
                <LableHeading name="Phone Number" required_ico="yes"/>
                <div className="col-lg-8">
                  <input
                    type="number"
                    className="form-control"
                    id="phone_number"
                    name="phone_number"
                    placeholder=""
                    value={formValues.phone_number || ''}
                    onChange={handleInputChange}
                  />
                </div>  
              </div>
            </div>

            <div className="col-xl-12">
              <div className="form-group mb-3 row">
               <label className="col-lg-4 col-form-label" htmlFor="Password">Password 
                {!formValues.id  &&
                          <span className="text-danger">*</span> 
                }
                
                 
                </label>
                <div className="col-lg-8">
                  <input
                    type="password"
                    className="form-control"
                    id="password"
                    name="password"
                    placeholder=""
                    value={formValues.password || ''}
                    onChange={handleInputChange}
                  />
                </div>  
              </div>
            </div>


            <div className="col-xl-12">
              <div className="form-group mb-3 row">
                <LableHeading name="Permission" required_ico="yes" />
                <div className="col-lg-8">
                  <Select
                      id="permission_group"
                      isMulti
                      options={permission_groups}
                      value={selectedPermissionGroups} // Bind the state to the value prop
                      
                      onChange={(e) => handleChange(e, 'permission_group')}
                      placeholder="Select options"
                    />
                </div>  
              </div>
            </div>


            <div className="col-xl-12">
              <div className="form-group mb-3 row">
                <LableHeading name="Branch" required_ico="yes" />
                <div className="col-lg-8">
                  <Select
                      id="branch"
                      isMulti
                      options={options}
                      value={selectedOptions} // Bind the state to the value prop
                      
                      onChange={(e) => handleChange(e, 'branch')}
                      placeholder="Select options"
                    />
                </div>  
              </div>
            </div>

            {errorMsg &&
                                        
                            
                                        <div role="alert" className="fade alert alert-danger alert-dismissible show">
                                            <strong>Error! </strong> {errorMsg}
                                        </div>
                                        
                                         } 

            

        </div>
        <div className='modal-footer'>
        <button type="submit" className="btn btn-primary" id="save_user" >Save User</button>
        </div>
        
       
      </form>
    </div>
  );
};



export default AddEditUserComponent;