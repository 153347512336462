export const drv_driver_type_options = [
  { value: "", label: "Choose" },
  { value: "single", label: "Single" },
  { value: "team", label: "Team" },
];

export const drv_status_options = [
  { value: "", label: "Choose" },
  { value: "active", label: "Active" },
  { value: "inactive", label: "Inactive" },
];

export const drv_ownership_options = [
  { value: "", label: "Choose" },
  { value: "Company", label: "Company" },
  { value: "Owner/Operator", label: "Owner/Operator" },
];

export const drv_state_province_options = [
  { value: "", label: "Choose" },
  { value: "Alabama", label: "Alabama" },
  { value: "Alberta", label: "Alberta" },
  { value: "Arizona", label: "Arizona" },
  { value: "Arkansas", label: "Arkansas" },
  { value: "British Columbia", label: "British Columbia" },
  { value: "California", label: "California" },
  { value: "Colorado", label: "Colorado" },
  { value: "Connecticut", label: "Connecticut" },
  { value: "Delaware", label: "Delaware" },
  { value: "District Of Columbia", label: "District Of Columbia" },
  { value: "Florida", label: "Florida" },
  { value: "Georgia", label: "Georgia" },
  { value: "Idaho", label: "Idaho" },
  { value: "Illinois", label: "Illinois" },
  { value: "Indiana", label: "Indiana" },
  { value: "Iowa", label: "Iowa" },
  { value: "Kansas", label: "Kansas" },
  { value: "Kentucky", label: "Kentucky" },
  { value: "Louisiana", label: "Louisiana" },
  { value: "Maine", label: "Maine" },
  { value: "Manitoba", label: "Manitoba" },
  { value: "Maryland", label: "Maryland" },
  { value: "Massachusetts", label: "Massachusetts" },
  { value: "Michigan", label: "Michigan" },
  { value: "Minnesota", label: "Minnesota" },
  { value: "Mississippi", label: "Mississippi" },
  { value: "Missouri", label: "Missouri" },
  { value: "Montana", label: "Montana" },
  { value: "Nebraska", label: "Nebraska" },
  { value: "Nevada", label: "Nevada" },
  { value: "New Brunswick", label: "New Brunswick" },
  { value: "New Hampshire", label: "New Hampshire" },
  { value: "New Jersey", label: "New Jersey" },
  { value: "New Mexico", label: "New Mexico" },
  { value: "New York", label: "New York" },
  { value: "Newfoundland and Labrador", label: "Newfoundland and Labrador" },
  { value: "North Carolina", label: "North Carolina" },
  { value: "North Dakota", label: "North Dakota" },
  { value: "Nova Scotia", label: "Nova Scotia" },
  { value: "Ohio", label: "Ohio" },
  { value: "Oklahoma", label: "Oklahoma" },
  { value: "Ontario", label: "Ontario" },
  { value: "Oregon", label: "Oregon" },
  { value: "Pennsylvania", label: "Pennsylvania" },
  { value: "Prince Edward Island", label: "Prince Edward Island" },
  { value: "Quebec", label: "Quebec" },
  { value: "Rhode Island", label: "Rhode Island" },
  { value: "Saskatchewan", label: "Saskatchewan" },
  { value: "South Carolina", label: "South Carolina" },
  { value: "South Dakota", label: "South Dakota" },
  { value: "Tennessee", label: "Tennessee" },
  { value: "Texas", label: "Texas" },
  { value: "Utah", label: "Utah" },
  { value: "Vermont", label: "Vermont" },
  { value: "Virginia", label: "Virginia" },
  { value: "Washington", label: "Washington" },
  { value: "West Virginia", label: "West Virginia" },
  { value: "Wisconsin", label: "Wisconsin" },
  { value: "Wyoming", label: "Wyoming" },
];

export const drv_time_zone_options = [
  { value: "", label: "Choose" },
  { value: "ET (Eastern Time, e.g. New York)", label: "ET (Eastern Time, e.g. New York)" },
  { value: "CT (Central Time, e.g. Chicago)", label: "CT (Central Time, e.g. Chicago)" },
  { value: "MT (Mountain Time, e.g. Salt Lake City)", label: "MT (Mountain Time, e.g. Salt Lake City)" },
  { value: "PT (Pacific Time, e.g. Los Angeles)", label: "PT (Pacific Time, e.g. Los Angeles)" },
  { value: "AT (Atlantic Time, e.g. Halifax)", label: "AT (Atlantic Time, e.g. Halifax)" },
  { value: "KT (Alaska Time, e.g. Anchorage)", label: "KT (Alaska Time, e.g. Anchorage)" },
];

export const drv_cdl_state_options = drv_state_province_options;

export const drv_license_type_class_options = [
  { value: "", label: "Choose" },
  { value: "A", label: "A" },
  { value: "B", label: "B" },
  { value: "C", label: "C" },
  { value: "D", label: "D" },
  { value: "E", label: "E" },
  { value: "F", label: "F" },
  { value: "DJ", label: "DJ" },
  { value: "O", label: "O" },
  { value: "P", label: "P" },
  { value: "R", label: "R" },
  { value: "S", label: "S" },
];

export const drv_license_endorsements_options = [
  { value: "", label: "Choose" },
  { value: "A - Air-brakes", label: "A - Air-brakes" },
  { value: "H - Hazardous Materials", label: "H - Hazardous Materials" },
  { value: "K - No Air-brakes", label: "K - No Air-brakes" },
  { value: "K - Intrastate Only", label: "K - Intrastate Only" },
  { value: "L - Air-brakes", label: "L - Air-brakes" },
  { value: "L - No Air-brakes", label: "L - No Air-brakes" },
  { value: "N - Tank Truck", label: "N - Tank Truck" },
  { value: "P - Passenger Vehicle", label: "P - Passenger Vehicle" },
  { value: "S - School Bus", label: "S - School Bus" },
  { value: "T - Semi-trailer Double or Triple", label: "T - Semi-trailer Double or Triple" },
  { value: "W - Tow Truck", label: "W - Tow Truck" },
  { value: "X - Tank and Hazmat", label: "X - Tank and Hazmat" },
];

export const drv_weight_unit_options = [
  { value: "", label: "Use my company's default setting" },
  { value: "lbs", label: "Pounds" },
  { value: "kg", label: "Kilograms" },
];

export const drv_distance_unit_options = [
  { value: "", label: "Use my company's default setting" },
  { value: "mi", label: "Miles" },
  { value: "km", label: "Kilometers" },
];

export const drv_temprature_unit_options = [
  { value: "", label: "Use my company's default setting" },
  { value: "f", label: "Fahrenheit" },
  { value: "c", label: "Celsius" },
];

export const driverInitialValues = {
  drv_driver_name: "",
  drv_phone_number: "",
  drv_email: "",
  drv_driver_type: "",
  drv_status: "",
  drv_date_of_birth: "",
  drv_ownership: "",
  drv_address: "",
  drv_notes: "",
  drv_location_name: "",
  drv_street_address: "",
  drv_city: "",
  drv_state_province: "",
  drv_postal_code: "",
  drv_country: "",
  drv_time_zone: "",
  drv_commercial_driver_since_year: "",
  drv_type_of_experience: "",
  drv_driving_school: "",
  drv_cdl_number: "",
  drv_cdl_state: "",
  drv_license_type_class: "",
  drv_license_endorsements: "",
  drv_application_date: "",
  drv_hire_date: "",
  drv_termination_date: "",
  drv_rehirable: "",
  drv_bonus_eligibility_date: "",
  drv_employment_notes: "",
  drv_insurance_co: "",
  drv_group_number: "",
  drv_id_number: "",
  drv_license_expiration_date: "",
  drv_twic_card_expiration_date: "",
  drv_hazmat_endorsement_expiration_date: "",
  drv_dot_medical_card_expiration_date: "",
  drv_insurance_expiration_date: "",
  drv_last_road_test_date: "",
  drv_last_alcohol_test_date: "",
  drv_weight_unit: "",
  drv_distance_unit: "",
  drv_temperature_unit: "",
};