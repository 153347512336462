function Paginate({
  handlePrevPage,
  currentPage,
  totalRecords,
  limit,
  handleNextPage,
}) {
  const totalPages = Math.ceil(totalRecords / limit);
  const paginationRange = [];

  for (let i = 1; i <= totalPages; i++) {
    paginationRange.push(i);
  }

  return (
    <div className="d-md-flex d-block align-items-center justify-content-between text-center  flex-wrap mt-md-0 mt-3">
      <div className="mb-md-0 mb-2">
        <h5 className="mb-0">
          Showing {(currentPage - 1) * 10 + 1} to {currentPage * 10} of{" "}
          {/* Showing {(currentPage - 1) * limit + 1} to {currentPage * limit} of{" "} */}
          {totalRecords} entries
        </h5>
      </div>
      <nav>
        <ul className="pagination pagination-circle justify-content-center">
          <li className={`page-item page-indicator ${currentPage <= 1 && "bg-opacity-50"}`}>
            <button disabled={currentPage <= 1} onClick={handlePrevPage} className="page-link">
              <i className="fa fa-angle-double-left" />
            </button>
          </li>
          <li className="page-item active">
            <button onClick={() => {}} className="page-link">
              {currentPage}
            </button>
          </li>
          <li className="page-item page-indicator">
            <button disabled={currentPage >= Math.ceil(totalRecords/limit)} onClick={handleNextPage} className="page-link">
              <i className="fa fa-angle-double-right" />
            </button>
          </li>
        </ul>
      </nav>
    </div>
  );
}

export default Paginate;
