import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required("Name is required")
    .min(2, "Name must be at least 2 characters long"),

  phone_number: Yup.string()
    .required("Phone Number is required")
    .matches(/^[0-9]+$/, "Invalid phone number format")
    .min(8, "Phone number must be at least 8 characters long")
    .max(15, "Phone number must not exceed 15 characters"),

  company_name: Yup.string()
    .required("Company Name is required")
    .min(2, "Company name must be at least 2 characters long")
    .max(100, "Company name must not exceed 100 characters"),

  company_business_type: Yup.string()
    .required("Business Type is required")
    .oneOf(
      [
        "Corporation",
        "Individual",
        "LLC",
        "LLP",
        "Partnership",
        "Sole Proprietor",
      ],
      "Invalid business type"
    ),

  company_currency: Yup.string()
    .required("Currency is required")
    .oneOf(["$"], "Invalid currency"),

  company_number: Yup.string()
    .required("Company Number is required")
    .matches(/^[0-9]+$/, "Invalid company number format")
    .min(5, "Company number must be at least 5 characters long")
    .max(20, "Company number must not exceed 20 characters"),

  company_email: Yup.string()
    .required("Company Email is required")
    .email("Invalid email format"),
});

export default validationSchema;
