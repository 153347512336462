export const transformReducerData = (state, action) => {
  switch (action.type) {
    case "collpase14":
      return { ...state, collpase14: !state.collpase14 };
    case "collpase15":
      return { ...state, collpase15: !state.collpase15 };
    case "collpase16":
      return { ...state, collpase16: !state.collpase16 };
    case "collpase17":
      return { ...state, collpase17: !state.collpase17 };
    case "collpase18":
      return { ...state, collpase18: !state.collpase18 };
    /* case "collpase19":
        return { ...state, collpase19: !state.collpase19 }; */
    case "collpase20":
      return { ...state, collpase20: !state.collpase20 };
    case "collpase21":
      return { ...state, collpase21: !state.collpase21 };

    case "section20":
      return { ...state, section20: !state.section20 };
    case "section21":
      return { ...state, section21: !state.section21 };
    case "section22":
      return { ...state, section22: !state.section22 };
    /* case "section23":
        return { ...state, section23: !state.section23 }; */
    case "section24":
      return { ...state, section24: !state.section24 };
    /* case "section25":
        return { ...state, section25: !state.section25 }; */
    default:
      return state;
  }
};

export const transformAPIPayload = (values) => {
  return {
    carrier_id: values.id,
    carrier_name: values.carrier_name,
    carrier_address: values.carrier_address,
    carrier_mobile: values.carrier_mobile,
    carrier_mobile_ext: values.carrier_mobile_ext,
    carrier_branch: values.carrier_branch,
    carrier_ok_to_load: values.carrier_ok_to_load,
    carrier_checks_payable_to: values.carrier_checks_payable_to,
    carrier_authority_type: values.carrier_authority_type,
    carrier_mc_ff_mx_number: values.carrier_mc_ff_mx_number,
    carrier_usdot_number: values.carrier_usdot_number,
    carrier_tax_id_number: values.carrier_tax_id_number,
    carrier_payment_terms: values.carrier_payment_terms,
    carrier_payment_method: values.carrier_payment_method,
    carrier_1099_vendor: values.carrier_1099_vendor,
    carrier_document: values.carrier_document,
    carrier_primary_insurance_details: values.carrier_primary_insurance_details,
    carrier_primary_insurance_expiration:
      values.carrier_primary_insurance_expiration,
    carrier_cargo_insurance_details: values.carrier_cargo_insurance_details,
    carrier_cargo_insurance_expiration:
      values.carrier_cargo_insurance_expiration,
    carrier_weight_unit: values.carrier_weight_unit,
    carrier_distance_unit: values.carrier_distance_unit,
    carrier_temprature_unit: values.carrier_temprature_unit,
    carrier_private_notes: values.carrier_private_notes,
    carrier_meta: values.carrier_meta.map((meta, index) => {
      if (meta.id) {
        return {
          carrier_meta_id: meta.id,
          contact_is_primary: meta.contact_is_primary,
          contact_name: meta.contact_name,
          contact_designation: meta.contact_designation,
          contact_mobile: meta.contact_mobile,
          contact_mobile_ext: meta.contact_mobile_ext,
          contact_email: meta.contact_email,
          contact_fax: meta.contact_fax,
          contact_add_to_email_list: meta.contact_add_to_email_list,
        };
      } else {
        return {
          carrier_meta_id: 0,
          user_id: values.user_id,
          carrier_id: values.id,
          contact_is_primary: meta.contact_is_primary,
          contact_name: meta.contact_name,
          contact_designation: meta.contact_designation,
          contact_mobile: meta.contact_mobile,
          contact_mobile_ext: meta.contact_mobile_ext,
          contact_email: meta.contact_email,
          contact_fax: meta.contact_fax,
          contact_add_to_email_list: meta.contact_add_to_email_list,
        };
      }
    }),
  };
};
