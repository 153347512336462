/* export const allBranches = [
    { value: "", label: "Select Branch", color: "#FF8B00" },
    { value: "1", label: "Shared", color: "#FF8B00" },
    { value: "2", label: "Only Me", color: "#FFC400" },
    { value: "3", label: "My Team", color: "#36B37E" },
  ]; */

export const screenOption = [
    { id: "20", title: "Find Customer", series: "26" },
    { id: "21", title: "Customer Address", series: "27" },
    { id: "22", title: "Contact List", series: "28" },
    { id: "23", title: "Accounting", series: "29" },
    { id: "24", title: "Notes (Optional)", series: "30" },
    /* { id: "25", title: "Customer Image", series: "31" }, */
  ];

  export const initialContactValue = {
    contact_is_primary: 1,
    contact_name: "",
    contact_designation: "",
    contact_mobile: "",
    contact_mobile_ext: "",
    contact_email: "",
    contact_fax: "",
    contact_add_to_email_list: "",
  };

  export const initialValues = {
    customer_name: "",
    customer_billing_email: "",
    customer_mobile: "",
    customer_mobile_ext: "",
    customer_branch: "",
    customer_credit_limit: "",
    customer_payment_terms: "",
    customer_credit_hold: "Disabled",
    customer_private_notes: "",
    customer_public_notes: "",
    customer_meta: [initialContactValue],
  };