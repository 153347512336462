import React, { useMemo } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import PageTitle from "../../../../layouts/PageTitle";
import { toast } from "react-toastify";
import Select from "../../../../components/CustomComponents/Select";
import AboutCompany from "../AboutCompany";
import ProfileBanner from "../ProfileBanner";
import SocialLinks from "../SocialLinks";
import {
  company_business_type_options,
  company_currency_options,
  docket_type_options,
  initialValues,
  aboutCompanyInitialValues,
  socialCompanyInitialValues,
  bannerCompanyInitialValues,
} from "./helpers/constant";
import validationSchema from "./helpers/CompanyProfileValidationSchema";
import {
  getCompanyProfileInfo,
  updateCompanyProfile,
} from "../TmsProfileService";

//Image
import companyLogo from "./../../../../../images/profile1.jpg";
import userImage from "./../../../../../images/profile/pic1.jpg";

const CompanyProfile = () => {
  const [companyProfileData, setCompanyProfileData] = React.useState(null);

  const initialState = useMemo(
    () =>
      Object.fromEntries(
        Object.entries(initialValues).map(([key, value]) => [
          key,
          companyProfileData?.[key] ?? "",
        ]) // Check the data and replace null and undefined value to empty string
      ),
    [companyProfileData, initialValues]
  );

  const [aboutCompanyData, setAboutCompanyData] = React.useState(
    aboutCompanyInitialValues
  );
  const [socialCompanyData, setSocialCompanyData] = React.useState(
    socialCompanyInitialValues
  );
  const [bannerCompanyData, setBannerCompanyData] = React.useState(
    bannerCompanyInitialValues
  );

  const BannerData = {
    companyLogo: companyLogo,
    userImage: userImage,
  };

  React.useEffect(() => {
    const fetchCompanyProfileData = async () => {
      try {
        const getCompanyProfileData = await getCompanyProfileInfo();
        if (
          getCompanyProfileData &&
          getCompanyProfileData.result &&
          getCompanyProfileData.result.length > 0
        ) {
          const allData = getCompanyProfileData.result[0];
          setCompanyProfileData(allData);
          setAboutCompanyData({
            ...aboutCompanyData,
            companyName: allData.company_name,
            aboutCompany: allData.about_company,
            companyNumber: allData.company_number,
            companyEmail: allData.company_email,
            companyAddress: allData.company_address,
          });

          setSocialCompanyData({
            ...socialCompanyData,
            facebookUrl: allData.facebook_url,
            linkedinUrl: allData.linkedin_url,
            twitterUrl: allData.twitter_url,
            instagramUrl: allData.instagram_url,
          });

          setBannerCompanyData({
            ...bannerCompanyData,
            companyLogo: companyLogo,
            userImage: userImage,
            companyName: allData.company_name,
            businessType: allData.company_business_type,
            companyAddress: allData.company_address,
          });
        }
      } catch (error) {
        console.error("Error fetching power unit:", error);
      }
    };
    fetchCompanyProfileData();
  }, []);

  const onSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);
    try {
      const locationDataResponse = await updateCompanyProfile(values);

      if (locationDataResponse.result.affectedRows) {
        toast.success("Company Profile updated successfully!");
        setCompanyProfileData({
          ...companyProfileData,
          values,
        });

        setAboutCompanyData({
          ...aboutCompanyData,
          companyName: values.company_name,
          aboutCompany: values.about_company,
          companyNumber: values.company_number,
          companyEmail: values.company_email,
          companyAddress: values.company_address,
        });

        setSocialCompanyData({
          ...socialCompanyData,
          facebookUrl: values.facebook_url,
          linkedinUrl: values.linkedin_url,
          twitterUrl: values.twitter_url,
          instagramUrl: values.instagram_url,
        });

        setBannerCompanyData({
          ...bannerCompanyData,
          /* companyLogo: companyLogo,
          userImage: userImage, */
          companyName: values.company_name,
          businessType: values.company_business_type,
          companyAddress: values.company_address,
        });
      } else {
        toast.error("Company Profile not updated!");
      }
    } catch (error) {
      console.error("Error updating company profile:", error);
      toast.error("Error updating company profile!");
    }
    setSubmitting(false);
  };

  return (
    <>
      <div className="row">
        <ProfileBanner
          companyLogo={bannerCompanyData.companyLogo}
          userImage={bannerCompanyData.userImage}
          name={bannerCompanyData.companyName}
          businessType={bannerCompanyData.businessType}
          companyAddress={bannerCompanyData.companyAddress}
        />
        <div className="col-xl-3 col-xxl-4 col-lg-6- col-lg-12 mt-5">
          <div className="row">
            <AboutCompany
              companyName={aboutCompanyData.companyName}
              aboutCompany={aboutCompanyData.aboutCompany}
              companyNumber={aboutCompanyData.companyNumber}
              companyEmail={aboutCompanyData.companyEmail}
              companyAddress={aboutCompanyData.companyAddress}
            />
          </div>
        </div>
        <div className="col-xl-9 col-xxl-8 col-lg-6- col-lg-12 mt-lg-5 mt-0">
          <div className="row">
            <div className="col-xl-12 col-xxl-12">
              <Formik
                initialValues={initialState}
                validationSchema={validationSchema}
                enableReinitialize // This ensures form is updated with data when editing
                validateOnBlur={false}
                validateOnChange={true}
                validateOnMount={true}
                onSubmit={async (values, { setSubmitting, validateForm }) => {
                  const errors = await validateForm(values);
                  if (Object.keys(errors).length === 0) {
                    onSubmit(values, { setSubmitting });
                  } else {
                    console.log("Form is not valid:", errors);
                  }
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  dirty,
                  isSubmitting,
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <PageTitle activeMenu="My Profile" motherMenu="Profile" />
                    <div className="filter cm-content-box box-primary">
                      <div className="card">
                        <div className="card-header border-0- pb-0">
                          <h4 className="fs-20 font-w600">
                            Personal Informartion
                          </h4>
                        </div>
                        <div className="card-body pt-4">
                          <div className="row">
                            <div className="col-xl-4 col-sm-6 col-md-3">
                              <label className=" form-label mb-1">
                                Name <span className="text-danger">*</span>
                              </label>
                              <Field
                                type="text"
                                name="name"
                                className="form-control form-control-sm mb-3"
                                placeholder="Name"
                              />
                              <ErrorMessage
                                name="name"
                                component="div"
                                className="text-danger form-text mb-3"
                              />
                            </div>

                            <div className="col-xl-4 col-sm-6 col-md-3">
                              <label className=" form-label mb-1">
                                Email <span className="text-danger">*</span>
                              </label>
                              <Field
                                type="text"
                                name="email"
                                className="form-control form-control-sm mb-3"
                                placeholder="Email"
                                readOnly={true}
                              />
                              <ErrorMessage
                                name="email"
                                component="div"
                                className="text-danger form-text mb-3"
                              />
                            </div>

                            <div className="col-xl-4 col-sm-6 col-md-3">
                              <label className=" form-label mb-1">
                                Phone Number{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <Field
                                type="text"
                                name="phone_number"
                                className="form-control form-control-sm mb-3"
                                placeholder="Phone Number"
                              />
                              <ErrorMessage
                                name="phone_number"
                                component="div"
                                className="text-danger form-text mb-3"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="filter cm-content-box box-primary">
                      <div className="card">
                        <div className="card-header border-0- pb-0">
                          <h4 className="fs-20 font-w600">
                            Company Information
                          </h4>
                        </div>
                        <div className="card-body pt-4">
                          <div className="row">
                            <div className="col-xl-4 col-sm-6 col-md-3">
                              <label className=" form-label mb-1">
                                Company Name{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <Field
                                type="text"
                                name="company_name"
                                className="form-control form-control-sm mb-3"
                                placeholder="company name"
                              />
                              <ErrorMessage
                                name="company_name"
                                component="div"
                                className="text-danger form-text mb-3"
                              />
                            </div>

                            <div className="col-xl-4 col-sm-6 col-md-3">
                              <label className=" form-label mb-1">
                                Business Type{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <div className="input-group mb-3">
                                <label className="input-group-text mb-0">
                                  Type
                                </label>
                                <Select
                                  name="company_business_type"
                                  options={company_business_type_options}
                                  className="form-control form-control-sm wide"
                                />
                              </div>
                              <ErrorMessage
                                name="company_business_type"
                                component="div"
                                className="text-danger form-text mb-3"
                              />
                            </div>

                            <div className="col-xl-4 col-sm-6 col-md-3">
                              <label className=" form-label mb-1">
                                Currency{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <div className="input-group mb-3">
                                <label className="input-group-text mb-0">
                                  Currency
                                </label>
                                <Select
                                  name="company_currency"
                                  options={company_currency_options}
                                  className="form-control form-control-sm wide"
                                />
                              </div>
                              <ErrorMessage
                                name="company_currency"
                                component="div"
                                className="text-danger form-text mb-3"
                              />
                            </div>

                            <div className="col-xl-4 col-sm-6 col-md-3">
                              <label className=" form-label mb-1">
                                Phone Number{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <Field
                                type="text"
                                name="company_number"
                                className="form-control form-control-sm mb-3"
                                placeholder="Phone Number"
                              />
                              <ErrorMessage
                                name="company_number"
                                component="div"
                                className="text-danger form-text mb-3"
                              />
                            </div>

                            <div className="col-xl-4 col-sm-6 col-md-3">
                              <label className=" form-label mb-1">
                                Alternate Phone Number
                              </label>
                              <Field
                                type="text"
                                name="company_alternative_number"
                                className="form-control form-control-sm mb-3"
                                placeholder="Phone Number"
                              />
                            </div>

                            <div className="col-xl-4 col-sm-6 col-md-3">
                              <label className=" form-label mb-1">
                                Email <span className="text-danger">*</span>
                              </label>
                              <Field
                                type="email"
                                name="company_email"
                                className="form-control form-control-sm mb-3"
                                placeholder="Email"
                              />
                              <ErrorMessage
                                name="company_email"
                                component="div"
                                className="text-danger form-text mb-3"
                              />
                            </div>

                            <div className="col-xl-4 col-sm-6 col-md-3">
                              <label className=" form-label mb-1">
                                Alternate Email
                              </label>
                              <Field
                                type="email"
                                name="company_alternative_email"
                                className="form-control form-control-sm mb-3"
                                placeholder="Email"
                              />
                            </div>

                            <div className="col-xl-4 col-sm-6 col-md-3">
                              <label className=" form-label mb-1">
                                Address
                              </label>
                              <Field
                                as="textarea"
                                name="company_address"
                                className="form-control form-control-sm"
                                placeholder=""
                                rows="2"
                              />
                            </div>

                            <div className="col-xl-4 col-sm-6 col-md-3">
                              <label className=" form-label mb-1">
                                Street Address
                              </label>
                              <Field
                                type="text"
                                name="company_street_address"
                                className="form-control form-control-sm mb-3"
                                placeholder="Street Address"
                              />
                            </div>

                            <div className="col-xl-4 col-sm-6 col-md-3">
                              <label className="form-label">
                                Docket Number
                              </label>
                              <div className="input-group mb-2">
                                <Select
                                  name="docket_type"
                                  options={docket_type_options}
                                  className="form-control form-control-sm custom-react-select input-group-text w-25"
                                />
                                <Field
                                  type="text"
                                  name="docket_number"
                                  className="form-control form-control-sm w-75"
                                  placeholder="DOCKET"
                                />
                              </div>
                            </div>

                            <div className="col-xl-4 col-sm-6 col-md-3">
                              <label className=" form-label mb-1">
                                USDOT Number
                              </label>
                              <Field
                                type="text"
                                name="usdot_number"
                                className="form-control form-control-sm mb-3"
                                placeholder="USDOT Number"
                              />
                            </div>

                            <div className="col-xl-4 col-sm-6 col-md-3">
                              <label className=" form-label mb-1">
                                SCAC Code
                              </label>
                              <Field
                                type="text"
                                name="company_street_address"
                                className="form-control form-control-sm mb-3"
                                placeholder="SCAC Code"
                              />
                            </div>

                            <div className="col-xl-12 col-sm-12 col-md-12">
                              <label className=" form-label mb-1">About</label>
                              <Field
                                as="textarea"
                                name="about_company"
                                className="form-control form-control-sm"
                                placeholder=""
                                rows="3"
                              />
                            </div>
                            <hr className="mt-3"></hr>
                            <div className="col-xl-4 col-sm-6 col-md-3">
                              <label className=" form-label mb-1">
                                Facebook
                              </label>

                              <div className="input-group input-group-sm mb-3">
                                <span className="input-group-text">
                                  www.facebook.com/
                                </span>
                                <Field
                                  type="text"
                                  name="facebook_url"
                                  className="form-control form-control-sm"
                                />
                              </div>
                            </div>

                            <div className="col-xl-4 col-sm-6 col-md-3">
                              <label className=" form-label mb-1">
                                LinkedIn
                              </label>

                              <div className="input-group input-group-sm mb-3">
                                <span className="input-group-text">
                                  https://in.linkedin.com/
                                </span>
                                <Field
                                  type="text"
                                  name="linkedin_url"
                                  className="form-control form-control-sm"
                                />
                              </div>
                            </div>

                            <div className="col-xl-4 col-sm-6 col-md-3">
                              <label className=" form-label mb-1">
                                Twitter
                              </label>
                              <div className="input-group input-group-sm mb-3">
                                <span className="input-group-text">
                                  https://x.com/
                                </span>
                                <Field
                                  type="text"
                                  name="twitter_url"
                                  className="form-control form-control-sm"
                                />
                              </div>
                            </div>

                            <div className="col-xl-4 col-sm-6 col-md-3">
                              <label className=" form-label mb-1">
                                Instagram
                              </label>

                              <div className="input-group input-group-sm mb-3">
                                <span className="input-group-text">
                                  https://www.instagram.com/
                                </span>
                                <Field
                                  type="text"
                                  name="instagram_url"
                                  className="form-control form-control-sm"
                                  placeholder="instagram"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="filter cm-content-box box-primary">
                      <div className="card">
                        <div className="card-header border-0- pb-0">
                          <h4 className="fs-20 font-w600">Socials</h4>
                        </div>
                        <div className="card-body pt-4">
                          <div className="row">
                            <SocialLinks
                              facebookUrl={socialCompanyData.facebookUrl}
                              linkedinUrl={socialCompanyData.linkedinUrl}
                              twitterUrl={socialCompanyData.twitterUrl}
                              instagramUrl={socialCompanyData.instagramUrl}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="form-group mt-3">
                      <button
                        type="submit"
                        className="btn btn-primary"
                        disabled={!dirty || isSubmitting}
                        onClick={() => {
                          if (Object.keys(errors).length > 0) {
                            const firstErrorField = Object.keys(errors)[0];
                            document
                              .getElementsByName(firstErrorField)[0]
                              .focus();
                          }
                        }}
                      >
                        Submit
                      </button>
                      {/* <p>Dirty: {dirty}</p>
                            <p>Submitting: {isSubmitting}</p>
                            {Object.keys(errors).map((fieldName) => (
                              <div key={fieldName} className="error">
                                {errors[fieldName]}
                              </div>
                            ))} */}
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default CompanyProfile;
