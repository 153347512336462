import React, { useMemo, useReducer } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import DriverValidationSchema from "./helpers/DriverValidationSchema";
import {
  addDriver,
  updateDriver,
  getDriverById,
} from "../../../../../services/CustomServices/DriverService";
import { Link, useParams, useNavigate } from "react-router-dom";
import { Collapse } from "react-bootstrap";
import PageTitle from "../../../../layouts/PageTitle";
import { toast } from "react-toastify";
import CustomSelect from "../../../../components/CustomComponents/Select";
import {transformReducerData } from "./helpers/function";
import {
  driverInitialValues,
  drv_driver_type_options,
  drv_status_options,
  drv_ownership_options,
  drv_state_province_options,
  drv_time_zone_options,
  drv_cdl_state_options,
  drv_license_type_class_options,
  drv_license_endorsements_options,
  drv_weight_unit_options,
  drv_distance_unit_options,
  drv_temprature_unit_options,
} from "./helpers/constant";

const debug = true;
const AddDriver = () => {
  const [state, dispatch] = useReducer(transformReducerData, true);
  const { driverId } = useParams();
  const [driver, setDriver] = React.useState(null);
  const navigate = useNavigate();
  /* const initialValues = driver || driverInitialValues; */
  const initialState = useMemo(() => driver || driverInitialValues, [driver]);

  // Get Driver data by ID and set in particuar fields (Call only when driverId available)
  React.useEffect(() => {
    if (!driverId) return;
    const fetchDriver = async () => {
      if (driverId) {
        try {
          const driverData = await getDriverById(driverId);
          if (driverData && driverData.result && driverData.result.length > 0) {
            setDriver(driverData.result[0]);
          }
        } catch (error) {
          console.error("Error fetching driver:", error);
        }
      }
    };

    fetchDriver();
  }, [driverId]);

  const onSubmit = async (values, { setSubmitting }) => {
    try {
      await DriverValidationSchema.validate(values, { abortEarly: false });

      if (driver && driver.id) {
        try {
          await updateDriver(driver.id, values);
          toast.success("Driver updated successfully!");
          navigate("/assets/driver-list", { replace: true });
        } catch (error) {
          toast.error("Error updating driver!");
        }
      } else {
        try {
          await addDriver(values);
          toast.success("Driver added successfully!");
          setTimeout(() => {
            navigate("/assets/driver-list", { replace: true });
          }, 2000);
        } catch (error) {
          toast.error("Error adding driver!");
          throw error;
        }
      }
    } catch (error) {
      const errorMessages = error.inner.map((err) => err.message);
      toast.error(`Validation errors: ${errorMessages.join(", ")}`, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      alert(error);
    }
    setSubmitting(false);
  };

  return (
    <Formik
      initialValues={initialState}
      validationSchema={DriverValidationSchema}
      enableReinitialize // This ensures form is updated with data when editing
      validateOnBlur={false}
      validateOnChange={true}
      validateOnMount={true}
      /* onSubmit={onSubmit} */
      onSubmit={async (values, { setSubmitting, validateForm }) => {
        const errors = await validateForm(values);
        if (Object.keys(errors).length === 0) {
          onSubmit(values, { setSubmitting });
        } else {
          console.log("Driver form is not valid:", errors);
        }
      }}
    >
      {({
        dirty,
        isSubmitting,
        handleSubmit,
        errors,
        setFieldValue,
        values,
      }) => (
        <Form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-xl-12">
              <PageTitle activeMenu="Add Driver" motherMenu="Driver" />
              <div className="mb-3">
                <ul className="d-flex align-items-center flex-wrap">
                  <li>
                    <Link
                      to={"/assets/driver-list"}
                      className="btn btn-primary btn-sm mx-1"
                    >
                      Driver List
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="row">
                <div className="col-xl-8 col-xxl-12">
                  <div className="filter cm-content-box box-primary">
                    <div className="content-title py-0">
                      <div className="cpa">Driver Profile</div>
                      <div className="tools">
                        <Link
                          to={"#"}
                          className={`SlideToolHeader ${
                            state.collpase1 ? "collapse" : "expand"
                          }`}
                          onClick={() => dispatch({ type: "collpase1" })}
                        >
                          <i className="fas fa-angle-up"></i>
                        </Link>
                      </div>
                    </div>
                    <Collapse in={!state.collpase1}>
                      <div className="cm-content-body form excerpt">
                        <div className="card-body py-3">
                          <div className="row">
                            <div className="col-xl-4 col-sm-6 col-md-3">
                              <label className=" form-label mb-1">
                                Driver Name{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <Field
                                type="text"
                                name="drv_driver_name"
                                className="form-control form-control-sm mb-3"
                              />
                              <ErrorMessage
                                name="drv_driver_name"
                                component="div"
                                className="text-danger form-text mb-3"
                              />
                            </div>

                            <div className="col-xl-4 col-sm-6 col-md-3">
                              <label className=" form-label mb-1">
                                Driver Number
                              </label>
                              <Field
                                type="text"
                                name="drv_phone_number"
                                className="form-control form-control-sm mb-3"
                              />
                            </div>

                            <div className="col-xl-4 col-sm-6 col-md-3">
                              <label className=" form-label mb-1">
                                Driver Email{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <Field
                                type="text"
                                name="drv_email"
                                className="form-control form-control-sm mb-3"
                              />
                              <ErrorMessage
                                name="drv_email"
                                component="div"
                                className="text-danger form-text mb-3"
                              />
                            </div>

                            <div className="col-xl-4 col-sm-6 col-md-3">
                              <label className=" form-label mb-1">
                                Driver Type
                              </label>
                              <div className="input-group mb-3">
                                <label className="input-group-text mb-0">
                                  Type
                                </label>
                                <CustomSelect
                                  name="drv_driver_type"
                                  options={drv_driver_type_options}
                                  className="form-control form-control-sm wide"
                                />
                              </div>
                            </div>

                            <div className="col-xl-4 col-sm-6 col-md-3">
                              <label className=" form-label mb-1">Status</label>
                              <div className="input-group mb-3">
                                <label className="input-group-text mb-0">
                                  Status
                                </label>
                                <CustomSelect
                                  name="drv_status"
                                  options={drv_status_options}
                                  className="form-control form-control-sm wide"
                                />
                              </div>
                            </div>

                            <div className="col-xl-4 col-sm-6 col-md-3">
                              <label className=" form-label mb-1">
                                Date of Birth
                              </label>
                              <Field
                                type="date"
                                name="drv_date_of_birth"
                                className="form-control form-control-sm mb-3"
                              />
                            </div>

                            <div className="col-xl-4 col-sm-6 col-md-3">
                              <label className=" form-label mb-1">
                                Ownership
                              </label>
                              <div className="input-group mb-3">
                                <CustomSelect
                                  name="drv_ownership"
                                  options={drv_ownership_options}
                                  className="form-control form-control-sm wide"
                                />
                              </div>
                            </div>

                            <div className="col-xl-4 col-sm-6 col-md-3">
                              <label className=" form-label mb-1">
                                Address
                              </label>
                              <Field
                                as="textarea"
                                name="drv_address"
                                className="form-control form-control-sm"
                                rows="2"
                              />
                            </div>

                            <div className="col-xl-4 col-sm-6 col-md-3">
                              <label className=" form-label mb-1">Notes</label>
                              <Field
                                as="textarea"
                                name="drv_notes"
                                className="form-control form-control-sm"
                                rows="2"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </Collapse>
                  </div>

                  <div className="filter cm-content-box box-primary">
                    <div className="content-title py-0">
                      <div className="cpa">Driver Home Terminal</div>
                      <div className="tools">
                        <Link
                          to={"#"}
                          className={`SlideToolHeader ${
                            state.collpase2 ? "collapse" : "expand"
                          }`}
                          onClick={() => dispatch({ type: "collpase2" })}
                        >
                          <i className="fas fa-angle-up"></i>
                        </Link>
                      </div>
                    </div>
                    <Collapse in={!state.collpase2}>
                      <div className="cm-content-body form excerpt">
                        <div className="card-body py-3">
                          <div className="row">
                            <div className="col-xl-4 col-sm-6 col-md-3">
                              <label className=" form-label mb-1">
                                Location Name
                              </label>
                              <Field
                                type="text"
                                name="drv_location_name"
                                className="form-control form-control-sm mb-3"
                              />
                            </div>

                            <div className="col-xl-4 col-sm-6 col-md-3">
                              <label className=" form-label mb-1">
                                Street Address
                              </label>
                              <Field
                                type="text"
                                name="drv_street_address"
                                className="form-control form-control-sm mb-3"
                              />
                            </div>

                            <div className="col-xl-4 col-sm-6 col-md-3">
                              <label className=" form-label mb-1">City</label>
                              <Field
                                type="text"
                                name="drv_city"
                                className="form-control form-control-sm mb-3"
                              />
                            </div>

                            <div className="col-xl-4 col-sm-6 col-md-3">
                              <label className=" form-label mb-1">
                                State/Province
                              </label>
                              <div className="input-group mb-3">
                                <CustomSelect
                                  name="drv_state_province"
                                  options={drv_state_province_options}
                                  className="form-control form-control-sm wide"
                                />
                              </div>
                            </div>

                            <div className="col-xl-4 col-sm-6 col-md-3">
                              <label className=" form-label mb-1">
                                Postal Code
                              </label>
                              <Field
                                type="text"
                                name="drv_postal_code"
                                className="form-control form-control-sm mb-3"
                              />
                            </div>

                            <div className="col-xl-4 col-sm-6 col-md-3">
                              <label className=" form-label mb-1">
                                Country
                              </label>
                              <Field
                                type="text"
                                name="drv_country"
                                className="form-control form-control-sm mb-3"
                              />
                            </div>

                            <div className="col-xl-4 col-sm-6 col-md-3">
                              <label className=" form-label mb-1">
                                Time Zone
                              </label>
                              <div className="input-group mb-3">
                                <CustomSelect
                                  name="drv_time_zone"
                                  options={drv_time_zone_options}
                                  className="form-control form-control-sm wide"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Collapse>
                  </div>

                  <div className="filter cm-content-box box-primary">
                    <div className="content-title py-0">
                      <div className="cpa">Driver Experience</div>
                      <div className="tools">
                        <Link
                          to={"#"}
                          className={`SlideToolHeader ${
                            state.collpase2 ? "collapse" : "expand"
                          }`}
                          onClick={() => dispatch({ type: "collpase3" })}
                        >
                          <i className="fas fa-angle-up"></i>
                        </Link>
                      </div>
                    </div>
                    <Collapse in={!state.collpase3}>
                      <div className="cm-content-body form excerpt">
                        <div className="card-body py-3">
                          <div className="row">
                            <div className="col-xl-4 col-sm-6 col-md-3">
                              <label className=" form-label mb-1">
                                Commercial Driver Since Year
                              </label>
                              <Field
                                type="text"
                                name="drv_commercial_driver_since_year"
                                className="form-control form-control-sm mb-3"
                              />
                            </div>

                            <div className="col-xl-4 col-sm-6 col-md-3">
                              <label className=" form-label mb-1">
                                Type of Experience
                              </label>
                              <Field
                                type="text"
                                name="drv_type_of_experience"
                                className="form-control form-control-sm mb-3"
                              />
                            </div>

                            <div className="col-xl-4 col-sm-6 col-md-3">
                              <label className=" form-label mb-1">
                                Driving School
                              </label>
                              <Field
                                type="text"
                                name="drv_driving_school"
                                className="form-control form-control-sm mb-3"
                              />
                            </div>

                            <div className="col-xl-4 col-sm-6 col-md-3">
                              <label className=" form-label mb-1">
                                CDL Number
                              </label>
                              <Field
                                type="text"
                                name="drv_cdl_number"
                                className="form-control form-control-sm mb-3"
                              />
                            </div>

                            <div className="col-xl-4 col-sm-6 col-md-3">
                              <label className=" form-label mb-1">
                                CDL State
                              </label>
                              <div className="input-group mb-3">
                                <CustomSelect
                                  name="drv_cdl_state"
                                  options={drv_cdl_state_options}
                                  className="form-control form-control-sm wide"
                                />
                              </div>
                            </div>

                            <div className="col-xl-4 col-sm-6 col-md-3">
                              <label className=" form-label mb-1">
                                License Type/Class
                              </label>
                              <div className="input-group mb-3">
                                <CustomSelect
                                  name="drv_license_type_class"
                                  options={drv_license_type_class_options}
                                  className="form-control form-control-sm wide"
                                />
                              </div>
                            </div>

                            <div className="col-xl-4 col-sm-6 col-md-3">
                              <label className=" form-label mb-1">
                                License Endorsements
                              </label>
                              <div className="input-group mb-3">
                                <CustomSelect
                                  name="drv_license_endorsements"
                                  options={drv_license_endorsements_options}
                                  className="form-control form-control-sm wide"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Collapse>
                  </div>

                  <div className="filter cm-content-box box-primary">
                    <div className="content-title py-0">
                      <div className="cpa">Driver Employment</div>
                      <div className="tools">
                        <Link
                          to={"#"}
                          className={`SlideToolHeader ${
                            state.collpase1 ? "collapse" : "expand"
                          }`}
                          onClick={() => dispatch({ type: "collpase4" })}
                        >
                          <i className="fas fa-angle-up"></i>
                        </Link>
                      </div>
                    </div>
                    <Collapse in={!state.collpase4}>
                      <div className="cm-content-body form excerpt">
                        <div className="card-body py-3">
                          <div className="row">
                            <div className="col-xl-4 col-sm-6 col-md-3">
                              <label className=" form-label mb-1">
                                Application Date
                              </label>
                              <Field
                                type="date"
                                name="drv_application_date"
                                className="form-control form-control-sm mb-3"
                              />
                            </div>

                            <div className="col-xl-4 col-sm-6 col-md-3">
                              <label className=" form-label mb-1">
                                Hire Date
                              </label>
                              <Field
                                type="date"
                                name="drv_hire_date"
                                className="form-control form-control-sm mb-3"
                              />
                            </div>

                            <div className="col-xl-4 col-sm-6 col-md-3">
                              <label className=" form-label mb-1">
                                Termination Date
                              </label>
                              <Field
                                type="date"
                                name="drv_termination_date"
                                className="form-control form-control-sm mb-3"
                              />
                            </div>

                            <div className="col-xl-4 col-sm-6 col-md-3">
                              <label className=" form-label mb-1">
                                Rehirable?
                              </label>
                              <Field
                                type="text"
                                name="drv_rehirable"
                                className="form-control form-control-sm mb-3"
                              />
                            </div>

                            <div className="col-xl-4 col-sm-6 col-md-3">
                              <label className=" form-label mb-1">
                                Bonus Eligibility Date
                              </label>
                              <Field
                                type="date"
                                name="drv_bonus_eligibility_date"
                                className="form-control form-control-sm mb-3"
                              />
                            </div>

                            <div className="col-xl-4 col-sm-6 col-md-3">
                              <label className=" form-label mb-1">
                                Employment Notes
                              </label>
                              <Field
                                as="textarea"
                                name="drv_employment_notes"
                                className="form-control form-control-sm"
                                rows="2"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </Collapse>
                  </div>

                  <div className="filter cm-content-box box-primary">
                    <div className="content-title py-0">
                      <div className="cpa">Driver Insurance</div>
                      <div className="tools">
                        <Link
                          to={"#"}
                          className={`SlideToolHeader ${
                            state.collpase2 ? "collapse" : "expand"
                          }`}
                          onClick={() => dispatch({ type: "collpase5" })}
                        >
                          <i className="fas fa-angle-up"></i>
                        </Link>
                      </div>
                    </div>
                    <Collapse in={!state.collpase5}>
                      <div className="cm-content-body form excerpt">
                        <div className="card-body py-3">
                          <div className="row">
                            <div className="col-xl-4 col-sm-6 col-md-3">
                              <label className=" form-label mb-1">
                                Insurance Co.
                              </label>
                              <Field
                                type="text"
                                name="drv_insurance_co"
                                className="form-control form-control-sm mb-3"
                              />
                            </div>

                            <div className="col-xl-4 col-sm-6 col-md-3">
                              <label className=" form-label mb-1">
                                Group Number
                              </label>
                              <Field
                                type="text"
                                name="drv_group_number"
                                className="form-control form-control-sm mb-3"
                              />
                            </div>

                            <div className="col-xl-4 col-sm-6 col-md-3">
                              <label className=" form-label mb-1">
                                Id Number
                              </label>
                              <Field
                                type="text"
                                name="drv_id_number"
                                className="form-control form-control-sm mb-3"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </Collapse>
                  </div>
                  
                </div>

                <div className="col-xl-4 col-xxl-12">
                  <div className="filter cm-content-box box-primary">
                    <div className="content-title py-0">
                      <div className="cpa">Driver Safety</div>
                      <div className="tools">
                        <Link
                          to={"#"}
                          className={`SlideToolHeader ${
                            state.collpase3 ? "collapse" : "expand"
                          }`}
                          onClick={() => dispatch({ type: "collpase6" })}
                        >
                          <i className="fas fa-angle-up"></i>
                        </Link>
                      </div>
                    </div>
                    <Collapse in={!state.collpase6}>
                      <div className="cm-content-body form excerpt">
                        <div className="card-body py-3">
                          <div className="row">
                            <div className="col-sm-6 col-md-4 col-lg-4- col-xl-12 col-xxl-4">
                              <label className=" form-label mb-1">
                                License Expiration Date
                              </label>
                              <Field
                                type="date"
                                name="drv_license_expiration_date"
                                className="form-control form-control-sm mb-3"
                              />
                            </div>

                            <div className="col-sm-6 col-md-4 col-lg-4- col-xl-12 col-xxl-4">
                              <label className=" form-label mb-1">
                                TWIC Card Expiration Date
                              </label>
                              <Field
                                type="date"
                                name="drv_twic_card_expiration_date"
                                className="form-control form-control-sm mb-3"
                              />
                            </div>

                            <div className="col-sm-6 col-md-4 col-lg-4- col-xl-12 col-xxl-4">
                              <label className=" form-label mb-1">
                                Hazmat Endorsement Expiration Date
                              </label>
                              <Field
                                type="date"
                                name="drv_hazmat_endorsement_expiration_date"
                                className="form-control form-control-sm mb-3"
                              />
                            </div>

                            <div className="col-sm-6 col-md-4 col-lg-4- col-xl-12 col-xxl-4">
                              <label className=" form-label mb-1">
                                DOT Medical Card Expiration Date
                              </label>
                              <Field
                                type="date"
                                name="drv_dot_medical_card_expiration_date"
                                className="form-control form-control-sm mb-3"
                              />
                            </div>

                            <div className="col-sm-6 col-md-4 col-lg-4- col-xl-12 col-xxl-4">
                              <label className=" form-label mb-1">
                                Insurance Expiration Date
                              </label>
                              <Field
                                type="date"
                                name="drv_insurance_expiration_date"
                                className="form-control form-control-sm mb-3"
                              />
                            </div>

                            <div className="col-sm-6 col-md-4 col-lg-4- col-xl-12 col-xxl-4">
                              <label className=" form-label mb-1">
                                Last Road Test Date
                              </label>
                              <Field
                                type="date"
                                name="drv_last_road_test_date"
                                className="form-control form-control-sm mb-3"
                              />
                            </div>

                            <div className="col-sm-6 col-md-4 col-lg-4- col-xl-12 col-xxl-4">
                              <label className=" form-label mb-1">
                                Last Drug Test Date
                              </label>
                              <Field
                                type="date"
                                name="drv_last_drug_test_date"
                                className="form-control form-control-sm mb-3"
                              />
                            </div>

                            <div className="col-sm-6 col-md-4 col-lg-4- col-xl-12 col-xxl-4">
                              <label className=" form-label mb-1">
                                Last Alcohol Test Date
                              </label>
                              <Field
                                type="date"
                                name="drv_last_alcohol_test_date"
                                className="form-control form-control-sm mb-3"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </Collapse>
                  </div>

                  <div className="filter cm-content-box box-primary">
                      <div className="content-title py-0">
                        <div className="cpa">
                          Customize Units of Measurement (Optional)
                        </div>
                        <div className="tools">
                          <Link
                            to={"#"}
                            onClick={() => dispatch({ type: "collpase7" })}
                            className={`SlideToolHeader ${
                              state.collpase17 ? "collapse" : "expand"
                            }`}
                          >
                            <i className="fas fa-angle-up"></i>
                          </Link>
                        </div>
                      </div>
                      <Collapse in={!state.collpase7}>
                        <div className="cm-content-body form excerpt">
                          <div className="card-body py-3">
                            <div className="row">
                              <div className="col-sm-6 col-md-4 col-lg-4- col-xl-12 col-xxl-4">
                                <label className="form-label mb-1">
                                  Weight Unit
                                </label>
                                <CustomSelect
                                  name="drv_weight_unit"
                                  options={drv_weight_unit_options}
                                  className="form-control form-control-sm custom-react-select mb-3"
                                />
                              </div>

                              <div className="col-sm-6 col-md-4 col-lg-4- col-xl-12 col-xxl-4">
                                <label className="form-label mb-1">
                                  Distance Unit
                                </label>
                                <CustomSelect
                                  name="drv_distance_unit"
                                  options={drv_distance_unit_options}
                                  className="form-control form-control-sm custom-react-select mb-3"
                                />
                              </div>

                              <div className="col-sm-6 col-md-4 col-lg-4- col-xl-12 col-xxl-4">
                                <label className="form-label mb-1">
                                  Temperature Unit
                                </label>
                                <CustomSelect
                                  name="drv_temperature_unit"
                                  options={drv_temprature_unit_options}
                                  className="form-control form-control-sm custom-react-select mb-3"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </Collapse>
                    </div>

            
                </div>
              </div>
            </div>
          </div>

          <div className="form-group mt-3">
            <button
              type="submit"
              className="btn btn-primary"
              disabled={!dirty || isSubmitting}
              onClick={() => {
                if (Object.keys(errors).length > 0) {
                  const firstErrorField = Object.keys(errors)[0];
                  document.getElementsByName(firstErrorField)[0].focus();
                }
              }}
            >
              Submit
            </button>
            {debug && (
              <>
                <pre style={{ textAlign: "left" }}>
                  <strong>Values</strong>
                  <br />
                  {JSON.stringify(values, null, 2)}
                </pre>
                <pre style={{ textAlign: "left" }}>
                  <strong>Errors</strong>
                  <br />
                  {JSON.stringify(errors, null, 2)}
                </pre>
              </>
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default AddDriver;
