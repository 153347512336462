export const company_business_type_options = [
    { value: '', label: "Choose" },
    { value: "Corporation", label: "Corporation" },
    { value: "Individual", label: "Individual" },
    { value: "LLC", label: "LLC" },
    { value: "LLP", label: "LLP" },
    { value: "Partnership", label: "Partnership" },
    { value: "Sole Proprietor", label: "Sole Proprietor" },
  ];

export const company_currency_options = [
  { value: '', label: "Choose" },
  { value: "$", label: "$ USD" }
];

export const docket_type_options = [
  { value: "mc", label: "MC" },
  { value: "ff", label: "FF" },
  { value: "mx", label: "MX" },
];

export const initialValues = {
  name: '',
  email: '',
  phone_number: '',
  company_name: '',
  company_business_type: '',
  company_currency: '',
  company_number: '',
  company_alternative_number: '',
  company_email: '',
  company_alternative_email: '',
  company_address: '',
  company_street_address: '',
  docket_type: '',
  docket_number: '',
  usdot_number: '',
  scac_code: '',
  about_company: '',
  facebook_url: '',
  linkedin_url: '',
  twitter_url: '',
  instagram_url: '',
};

export const aboutCompanyInitialValues = {
    companyName: '',
    aboutCompany: '',
    companyNumber: '',
    companyEmail: '',
    companyAddress: '',
};

export const socialCompanyInitialValues = {
    facebookUrl: '',
    linkedinUrl: '',
    twitterUrl: '',
    instagramUrl: '',
};
export const bannerCompanyInitialValues = {
    companyLogo: '',
    userImage: '',
    name: '',
    businessType: '',
    companyAddress: '',
};