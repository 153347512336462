export const initialValues = {
  name: '',
  email: '',
  phone_number: '',
};

export const aboutCompanyInitialValues = {
    companyName: '',
    aboutCompany: '',
    companyNumber: '',
    companyEmail: '',
    companyAddress: '',
};

export const socialCompanyInitialValues = {
    facebookUrl: '',
    linkedinUrl: '',
    twitterUrl: '',
    instagramUrl: '',
};
export const bannerCompanyInitialValues = {
    companyLogo: '',
    userImage: '',
    name: '',
    businessType: '',
    companyAddress: '',
};