import * as Yup from 'yup';

const DriverValidationSchema = Yup.object().shape({
  drv_driver_name: Yup.string()
    .required('Driver name is required'),
    drv_email: Yup.string()
    .required('Driver emaul is required')
    .email('Invalid email format'),
});

export default DriverValidationSchema;