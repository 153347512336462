import React, { useMemo, useReducer } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import CarrierValidationSchema from "./helpers/CarrierValidationSchema";
import {
  PageTitle,
  fetchAllBranchList,
  /* searchPlace,
  getPlaceDetails, */
} from "../../../../services/CustomServices/CommonService";
import {
  addCarrier,
  updateCarrier,
  getCarrierById,
  deleteCarrierContact,
} from "../../../../services/CustomServices/CarrierService";
import { Link, useParams, useNavigate } from "react-router-dom";
import { Collapse } from "react-bootstrap";

import { toast } from "react-toastify";
import CustomSelect from "../../../components/CustomComponents/Select";

import ContactList from "../../../components/CustomComponents/ContactList";
import { transformAPIPayload, transformReducerData } from "./helpers/function";
import {
  carrier_authority_type_options,
  carrier_payment_method_options,
  carrier_weight_unit_options,
  carrier_distance_unit_options,
  carrier_temprature_unit_options,
} from "./helpers/constant";
import { initialValues, screenOption } from "./helpers/constant";

const debug = false;
const AddCarrier = () => {
  const [state, dispatch] = useReducer(transformReducerData, true);

  const { carrierId } = useParams(); // get the carrier Id from url
  const [carrier, setCarrier] = React.useState(null);
  const [allBranchesOptions, setallBranchesOptions] = React.useState([]);

  const carrier_meta = carrier && carrier.carrier_meta;
  const navigate = useNavigate();
  const initialState = useMemo(() => carrier || initialValues, [carrier]);

  React.useEffect(() => {
    fetchAllBranchList().then((options) => {
      setallBranchesOptions(options);
    });
  }, []);

  React.useEffect(() => {
    if (carrierId) {
      const fetchCarrier = async () => {
        if (carrierId) {
          try {
            const carrierData = await getCarrierById(carrierId);
            if (
              carrierData &&
              carrierData.result &&
              carrierData.result.length
            ) {
              setCarrier(carrierData.result[0]);
            }
          } catch (error) {
            console.error("Error fetching carrier:", error);
          }
        }
      };
      fetchCarrier();
    } else {
      setCarrier(initialValues);
    }
  }, [carrierId]);

  const onDeleteContact = async (index) => {
    if (!carrier || !carrier.carrier_meta || !carrier.carrier_meta[index]) {
      console.warn("carrier or carrier_meta is null or index is out of bounds");
      return;
    }
    const contactId = carrier.carrier_meta[index].id;
    if (contactId) {
      const contactDataResponse = await deleteCarrierContact(
        carrier.id,
        contactId
      );
      /* toast.success("Carrier Contact deleted successfullyy!"); */
      toast.success(contactDataResponse.message);
    }

    // Update the carrier state
    const updatedCarrier = { ...carrier };
    updatedCarrier.carrier_meta = carrier.carrier_meta.filter(
      (contact, i) => i !== index
    );
    setCarrier(updatedCarrier);
  };

  const onSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);
    if (carrier && carrier.id) {
      try {
        const payload = transformAPIPayload(values);
        const carrierDataRessponse = await updateCarrier(carrier.id, payload);
        /*  toast.success("Carrier updated successfully!"); */
        toast.success(carrierDataRessponse.message);
        navigate("/carriers/carrier-list", { replace: true });
      } catch (error) {
        console.error("Error updating carrier:", error);
        toast.error("Error updating carrier!");
      }
    } else {
      try {
        const carrierDataResult = await addCarrier(values);
        /* toast.success("Carrier added successfully!"); */
        toast.success(carrierDataResult.message);
        // Navigate to CarrierList page after 2 seconds
        setTimeout(() => {
          navigate("/carriers/carrier-list", { replace: true });
        }, 2000);
      } catch (error) {
        console.error("Error submitting form:", error);
        toast.error("Error adding carrier!");
        throw error;
      }
    }

    setSubmitting(false);
  };

  return (
    <Formik
      initialValues={initialState}
      validationSchema={CarrierValidationSchema}
      enableReinitialize // This ensures form is updated with carrier data when editing
      validateOnBlur={false}
      validateOnChange={true}
      validateOnMount={true}
      onSubmit={async (values, { setSubmitting, validateForm }) => {
        const errors = await validateForm(values);
        if (Object.keys(errors).length === 0) {
          onSubmit(values, { setSubmitting });
        } else {
          console.log("Form is not valid:", errors);
        }
      }}
    >
      {/* {({dirty, isSubmitting, handleSubmit, errors }) => ( */}
      {({
        dirty,
        isSubmitting,
        handleSubmit,
        errors,
        setFieldValue,
        values,
      }) => (
        <Form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-xl-12">
              <PageTitle
                activeMenu={carrierId ? "Edit Carrier" : "Add Carrier"}
                motherMenu="Carriers"
              />
              <div className="mb-3">
                <ul className="d-flex align-items-center flex-wrap">
                  <li>
                    <Link
                      to={"/carriers/carrier-list"}
                      className="btn btn-primary btn-sm mx-1"
                    >
                      Carrier List
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={"#"}
                      className="btn btn-primary btn-sm open mt-1 mt-md-0"
                      onClick={() => dispatch({ type: "collpase20" })}
                    >
                      Screen Option
                    </Link>
                  </li>
                </ul>
              </div>
              <Collapse in={state.collpase20}>
                <div className="main-check py-2">
                  <div className="row">
                    <h4 className="mb-3">Show on screen</h4>
                    {screenOption.map((item, ind) => (
                      <div className="col-xl-2 col-lg-3 col-sm-4" key={ind}>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            id={`flexCheckDefault-${item.series}`}
                            onChange={() => {
                              dispatch({ type: `section${item.id}` });
                            }}
                            defaultChecked
                          />
                          <label
                            className="form-check-label mb-0 text-nowrap"
                            htmlFor={`flexCheckDefault-${item.series}`}
                          >
                            {item.title}
                          </label>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </Collapse>
              <div className="row">
                <div className="col-xl-8 col-xxl-12">
                  {!state.section21 && (
                    <div className="filter cm-content-box box-primary">
                      <div className="content-title py-0">
                        <div className="cpa">Carrier Address</div>
                        <div className="tools">
                          <Link
                            to={"#"}
                            className={`SlideToolHeader ${
                              state.collpase15 ? "collapse" : "expand"
                            }`}
                            onClick={() => dispatch({ type: "collpase15" })}
                          >
                            <i className="fas fa-angle-up"></i>
                          </Link>
                        </div>
                      </div>
                      <Collapse in={!state.collpase15}>
                        <div className="cm-content-body form excerpt">
                          <div className="card-body py-3">
                            <div className="row">
                              <div className="col-xl-6 col-sm-6">
                                <label className="form-label">
                                  Carrier Name{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <Field
                                  type="text"
                                  name="carrier_name"
                                  className="form-control form-control-sm mb-2"
                                  /* initialValue={carrier ? carrier.carrier_name : ''} */
                                  placeholder="Carrier Name"
                                />
                                <ErrorMessage
                                  name="carrier_name"
                                  component="div"
                                  className="text-danger form-text mb-2"
                                />
                              </div>
                              <div className="col-xl-6 col-sm-6">
                                <label className="form-label">
                                  Mobile & Ext.
                                </label>
                                <div className="input-group mb-2">
                                  <Field
                                    type="text"
                                    name="carrier_mobile"
                                    className="form-control form-control-sm"
                                    placeholder="Mobile"
                                  />
                                  <Field
                                    type="text"
                                    name="carrier_mobile_ext"
                                    className="form-control form-control-sm"
                                    placeholder="Ext."
                                  />
                                </div>
                                <ErrorMessage
                                  name="carrier_mobile"
                                  component="div"
                                  className="text-danger form-text mb-2"
                                />
                              </div>
                              <div className="col-xl-6 col-sm-6">
                                <label className="form-label">
                                  Branch Name{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <div className="input-group mb-2">
                                  <label className="input-group-text mb-0">
                                    Branch
                                  </label>
                                  <CustomSelect
                                    name="carrier_branch"
                                    options={allBranchesOptions}
                                    className="form-control form-control-sm wide"
                                  />
                                </div>
                                <ErrorMessage
                                  name="carrier_branch"
                                  component="div"
                                  className="text-danger form-text mb-2"
                                />
                              </div>
                              <div className="col-xl-6 col-sm-6">
                                <label className="form-label">Ok to Load</label>
                                <div>
                                  <span>
                                    <label className="radio-inline mx-3">
                                      <Field
                                        type="radio"
                                        name="carrier_ok_to_load"
                                        className="form-check-input mt-0"
                                        value="1"
                                      />{" "}
                                      Yes
                                    </label>
                                  </span>
                                  <span>
                                    <label className="radio-inline me-3">
                                      <Field
                                        type="radio"
                                        name="carrier_ok_to_load"
                                        className="form-check-input mt-0"
                                        value="0"
                                      />{" "}
                                      No
                                    </label>
                                  </span>
                                </div>
                              </div>
                              <div className="col-xl-6 col-md-6 col-sm-6">
                                <label className="form-label">
                                  Carrier Address{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <Field
                                  as="textarea"
                                  name="carrier_address"
                                  className="form-control form-control-sm"
                                  placeholder=""
                                  rows="3"
                                />
                                <ErrorMessage
                                  name="carrier_address"
                                  component="div"
                                  className="text-danger form-text mb-2"
                                />
                              </div>
                              <div className="col-xl-6 col-sm-6">
                                <label className="form-label">
                                  Checks payable to
                                </label>
                                <Field
                                  type="text"
                                  name="carrier_checks_payable_to"
                                  className="form-control form-control-sm mb-2"
                                  /* initialValue={carrier ? carrier.carrier_name : ''} */
                                  placeholder="Checks payable to"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </Collapse>
                    </div>
                  )}
                  {!state.section20 && (
                    <div className="filter cm-content-box box-primary">
                      <div className="content-title py-0">
                        <div className="cpa">Carrier Details</div>
                        <div className="tools">
                          <Link
                            to={"#"}
                            className={`SlideToolHeader ${
                              state.collpase21 ? "collapse" : "expand"
                            }`}
                            onClick={() => dispatch({ type: "collpase21" })}
                          >
                            <i className="fas fa-angle-up"></i>
                          </Link>
                        </div>
                      </div>
                      <Collapse in={!state.collpase21}>
                        <div className="cm-content-body form excerpt">
                          <div className="card-body py-3">
                            <div className="row">
                              <div className="col-xl-6 col-sm-6">
                                <label className="form-label">
                                  MC/FF/MX Number
                                </label>
                                <div className="input-group mb-2">
                                  <CustomSelect
                                    name="carrier_authority_type"
                                    options={carrier_authority_type_options}
                                    className="form-control form-control-sm custom-react-select input-group-text w-25"
                                  />
                                  <Field
                                    type="text"
                                    name="carrier_mc_ff_mx_number"
                                    className="form-control form-control-sm w-75"
                                    placeholder="MC/FF/MX Number"
                                  />
                                </div>
                              </div>
                              <div className="col-xl-6 col-sm-6">
                                <label className="form-label">
                                  USDOT Number
                                </label>
                                <Field
                                  type="text"
                                  name="carrier_usdot_number"
                                  className="form-control form-control-sm mb-2"
                                  placeholder="USDOT Number"
                                />
                              </div>
                              <div className="col-xl-6 col-sm-6">
                                <label className="form-label">
                                  Tax ID Number
                                </label>
                                <Field
                                  type="text"
                                  name="carrier_tax_id_number"
                                  className="form-control form-control-sm mb-2"
                                  placeholder="Tax ID Number"
                                />
                              </div>
                              <div className="col-xl-6 col-sm-6">
                                <label className="form-label">
                                  Payment Terms
                                </label>
                                <div className="input-group mb-2">
                                  <Field
                                    type="number"
                                    name="carrier_payment_terms"
                                    className="form-control form-control-sm wide"
                                  ></Field>
                                  <label className="input-group-text mb-0">
                                    Days
                                  </label>
                                </div>
                              </div>

                              <div className="col-xl-6 col-sm-6">
                                <label className="form-label">
                                  Payment Method
                                </label>
                                <CustomSelect
                                  name="carrier_payment_method"
                                  options={carrier_payment_method_options}
                                  className="form-control form-control-sm custom-react-select"
                                />
                              </div>

                              <div className="col-xl-2 col-sm-2">
                                <label className="form-label">
                                  1099 Vendor?
                                </label>
                                <div>
                                  <span>
                                    <label className="checkbox-inline mx-3">
                                      <Field
                                        type="checkbox"
                                        name="carrier_1099_vendor"
                                        value="1"
                                        className="form-check-input mt-0"
                                      />{" "}
                                      Yes
                                    </label>
                                  </span>
                                </div>
                              </div>

                              <div className="col-xl-4 col-sm-4">
                                <label className="form-label">
                                  Upload Document
                                </label>
                                <div className="input-group">
                                  <div className="from-file">
                                    <Field
                                      type="file"
                                      name="carrier_document"
                                      className="form-file-input form-control"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Collapse>
                    </div>
                  )}
                  {!state.section22 && (
                    <div className="filter cm-content-box box-primary">
                      <div className="content-title py-0">
                        <div className="cpa">Contact List</div>
                        <div className="tools">
                          <Link
                            to={"#"}
                            onClick={() => dispatch({ type: "collpase16" })}
                            className={`SlideToolHeader ${
                              state.collpase16 ? "collapse" : "expand"
                            }`}
                          >
                            <i className="fas fa-angle-up"></i>
                          </Link>
                        </div>
                      </div>
                      <Collapse in={!state.collpase16}>
                        <div className="cm-content-body form excerpt">
                          <div className="card-body py-3">
                            <div className="row">
                              <div className="col px-1">
                                <label className="from-label">
                                  Contact Name
                                </label>
                              </div>

                              <div className="col px-1">
                                <label className="from-label">
                                  Designation
                                </label>
                              </div>

                              <div className="col px-1">
                                <label className="from-label">
                                  Mobile & Ext.
                                </label>
                              </div>

                              <div className="col px-1">
                                <label className="from-label">Email</label>
                              </div>

                              <div className="col px-1">
                                <label className="from-label">Fax</label>
                              </div>

                              <div className="col px-1">
                                <label className="from-label">
                                  Add to Email List
                                </label>
                              </div>
                            </div>

                            <ContactList
                              metaList={carrier_meta}
                              name="carrier_meta"
                              values={values}
                              errors={values}
                              placeholder="Contact List"
                              label="Contact List"
                              onDeleteContact={onDeleteContact}
                              setFieldValue={setFieldValue}
                            />
                            <span className="mt-3 d-block">
                              Custom fields can be used to extra metadata to a
                              post that you can use in your theme.
                            </span>
                          </div>
                        </div>
                      </Collapse>
                    </div>
                  )}
                </div>
                <div className="col-xl-4 col-xxl-12">
                  {!state.section32 && (
                    <div className="filter cm-content-box box-primary">
                      <div className="content-title py-0">
                        <div className="cpa">Carrier Insurance</div>
                        <div className="tools">
                          <Link
                            to={"#"}
                            className={`SlideToolHeader ${
                              state.collpase14 ? "collapse" : "expand"
                            }`}
                            onClick={() => dispatch({ type: "collpase14" })}
                          >
                            <i className="fas fa-angle-up"></i>
                          </Link>
                        </div>
                      </div>
                      <Collapse in={!state.collpase14}>
                        <div className="cm-content-body form excerpt">
                          <div className="card-body py-3">
                            <div className="row">
                              <div className="col-xl-12 col-md-12 col-sm-6">
                                <label className="form-label">
                                  Primary Insurance (BIPD) Details
                                </label>
                                <Field
                                  as="textarea"
                                  name="carrier_primary_insurance_details"
                                  className="form-control form-control-sm"
                                  placeholder="Primary Insurance (BIPD) Details"
                                  rows="3"
                                />
                              </div>

                              <div className="col-xl-12 col-md-12 col-sm-6">
                                <label className="form-label">
                                  Primary Insurance Expiration{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <Field
                                  type="date"
                                  name="carrier_primary_insurance_expiration"
                                  className="form-control form-control-sm mb-2"
                                />
                                <ErrorMessage
                                  name="carrier_primary_insurance_expiration"
                                  component="div"
                                  className="text-danger form-text mb-2"
                                />
                              </div>

                              <div className="col-xl-12 col-md-12 col-sm-6">
                                <label className="form-label">
                                  Cargo Insurance Details
                                </label>
                                <Field
                                  as="textarea"
                                  name="carrier_cargo_insurance_details"
                                  className="form-control form-control-sm"
                                  placeholder="Cargo Insurance Details"
                                  rows="3"
                                />
                              </div>

                              <div className="col-xl-12 col-md-12 col-sm-6">
                                <label className="form-label">
                                  Cargo Insurance Expiration
                                </label>
                                <Field
                                  type="date"
                                  name="carrier_cargo_insurance_expiration"
                                  className="form-control form-control-sm mb-2"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </Collapse>
                    </div>
                  )}

                  {!state.section23 && (
                    <div className="filter cm-content-box box-primary">
                      <div className="content-title py-0">
                        <div className="cpa">
                          Customize Units of Measurement (Optional)
                        </div>
                        <div className="tools">
                          <Link
                            to={"#"}
                            onClick={() => dispatch({ type: "collpase17" })}
                            className={`SlideToolHeader ${
                              state.collpase17 ? "collapse" : "expand"
                            }`}
                          >
                            <i className="fas fa-angle-up"></i>
                          </Link>
                        </div>
                      </div>
                      <Collapse in={!state.collpase17}>
                        <div className="cm-content-body form excerpt">
                          <div className="card-body py-3">
                            <div className="row">
                              <div className="col-xl-12 col-md-12 col-sm-6">
                                <label className="form-label">
                                  Weight Unit
                                </label>
                                <CustomSelect
                                  name="carrier_weight_unit"
                                  options={carrier_weight_unit_options}
                                  className="form-control form-control-sm custom-react-select"
                                />
                              </div>

                              <div className="col-xl-12 col-md-12 col-sm-6">
                                <label className="form-label">
                                  Distance Unit
                                </label>
                                <CustomSelect
                                  name="carrier_distance_unit"
                                  options={carrier_distance_unit_options}
                                  className="form-control form-control-sm custom-react-select"
                                />
                              </div>

                              <div className="col-xl-12 col-md-12 col-sm-6">
                                <label className="form-label">
                                  Temperature Unit
                                </label>
                                <CustomSelect
                                  name="carrier_temprature_unit"
                                  options={carrier_temprature_unit_options}
                                  className="form-control form-control-sm custom-react-select"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </Collapse>
                    </div>
                  )}

                  {!state.section24 && (
                    <div className="filter cm-content-box box-primary">
                      <div className="content-title py-0">
                        <div className="cpa">Notes (Optional)</div>
                        <div className="tools">
                          <Link
                            to={"#"}
                            onClick={() => dispatch({ type: "collpase18" })}
                            className={`SlideToolHeader ${
                              state.collpase18 ? "collapse" : "expand"
                            }`}
                          >
                            <i className="fas fa-angle-up"></i>
                          </Link>
                        </div>
                      </div>
                      <Collapse in={!state.collpase18}>
                        <div className="cm-content-body form excerpt">
                          <div className="card-body py-3">
                            <div className="row">
                              <div className="col-xl-12 col-md-12 col-sm-6">
                                <label className="form-label">
                                  Private Notes{" "}
                                </label>
                                <Field
                                  as="textarea"
                                  name="carrier_private_notes"
                                  className="form-control form-control-sm"
                                  placeholder="Private Notes"
                                  rows="3"
                                />
                                <div className="form-text">
                                  This note is private and viewable only by your
                                  organization.
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Collapse>
                    </div>
                  )}

                  {/* {!state.section25 && (
                    <div className="filter cm-content-box box-primary">
                      <div className="content-title py-0">
                        <div className="cpa">Carrier Image</div>
                        <div className="tools">
                          <Link
                            to={"#"}
                            className={`SlideToolHeader ${
                              state.collpase19 ? "collapse" : "expand"
                            }`}
                            onClick={() => dispatch({ type: "collpase19" })}
                          >
                            <i className="fas fa-angle-up"></i>
                          </Link>
                        </div>
                      </div>
                      <Collapse in={!state.collpase19}>
                        <div className="cm-content-body publish-content form excerpt">
                          <div className="card-body py-3">
                            
                          </div>
                        </div>
                      </Collapse>
                    </div>
                  )} */}
                </div>
              </div>
            </div>
          </div>

          <div className="form-group mt-3">
            <button
              type="submit"
              className="btn btn-primary"
              disabled={!dirty || isSubmitting}
              onClick={() => {
                if (Object.keys(errors).length > 0) {
                  const firstErrorField = Object.keys(errors)[0];
                  if (Array.isArray(errors[firstErrorField])) {
                    const firstErrorObject = errors[firstErrorField].find(
                      (obj) =>
                        obj !== null &&
                        obj !== undefined &&
                        Object.keys(obj || {}).length > 0
                    );
                    if (firstErrorObject) {
                      const firstErrorKey = Object.keys(firstErrorObject)[0];
                      const errorIndex =
                        errors[firstErrorField].indexOf(firstErrorObject);
                      const errorFieldName = `${firstErrorField}.${errorIndex}.${firstErrorKey}`;
                      const element = document.querySelector(
                        `[name="${errorFieldName}"]`
                      );
                      if (element) {
                        element.focus();
                      }
                    }
                  } else {
                    const element =
                      document.getElementsByName(firstErrorField)[0];
                    if (element) {
                      element.focus();
                    }
                  }
                }
              }}
            >
              Submit
            </button>
            {debug && (
              <>
                {/* <pre style={{ textAlign: "left" }}>
                  <strong>Values</strong>
                  <br />
                  {JSON.stringify(values, null, 2)}
                </pre> */}
                <pre style={{ textAlign: "left" }}>
                  <strong>Errors</strong>
                  <br />
                  {JSON.stringify(errors, null, 2)}
                </pre>
              </>
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default AddCarrier;
