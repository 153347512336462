import { lazy, Suspense, useEffect } from 'react';
/// Components
import Index from './jsx/index';
import { connect, useDispatch } from 'react-redux';
import {  Route, Routes, useLocation , useNavigate , useParams } from 'react-router-dom';
// action
import { checkAutoLogin,AccountLogout } from './services/AuthService';
import { isAuthenticated } from './store/selectors/AuthSelectors';
/// Style
import "./vendor/bootstrap-select/dist/css/bootstrap-select.min.css";
import "./css/style.css";

const SignUp = lazy(() => import('./jsx/pages/Registration'));
const ForgotPassword = lazy(() => import('./jsx/pages/ForgotPassword'));
const PasswordReset = lazy(() => import('./jsx/pages/PasswordReset'));
const EmailVerification = lazy(() => import('./jsx/pages/EmailVerification'));


const Login = lazy(() => { 
    return new Promise(resolve => {
		setTimeout(() => resolve(import('./jsx/pages/Login')), 500);
	});
});

function withRouter(Component) {
    function ComponentWithRouterProp(props) {
      let location = useLocation();
      let navigate = useNavigate();
      let params = useParams();
      
      return (
        <Component
          {...props}
          router={{ location, navigate, params }}
        />
      );
    }
  
    return ComponentWithRouterProp;
}


function checkLoginValidation(location) {
   
    const login_page = ['/sign-up', '/login', '/forgot-password','/password-reset','/password-reset/','/email-verification'];
    let userDetails = localStorage.getItem("userDetails");
    
    if(userDetails==null){
       
        if(!login_page.includes(location.pathname)) {
            window.location.href='/login';
            return false;
        }
    }  else {

        if(login_page.includes(location.pathname)) {
            window.location.href='/dashboard';
        }

    }
   
}

function App (props) {
    let location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    useEffect(() => {
        checkAutoLogin(dispatch, navigate);
    }, []);

 
    
    let routeblog = (  
        <Routes>
            <Route path='/' element={<Login />} exact/>
            <Route path='/login' element={<Login />} exact/>
            <Route path='/sign-up' element={<SignUp />} exact/>
            <Route path='/forgot-password' element={<ForgotPassword />} exact/>
            <Route path='/password-reset' element={<PasswordReset />} exact/>
            <Route  path='/email-verification' element={<EmailVerification login_id="" />} exact/>
            <Route path='/logout' element={<AccountLogout />} exact/>
        </Routes>
    );
    if (props.isAuthenticated) {
		return (
			<>
                <Suspense fallback={
                    <div id="preloader">
                        <div className="sk-three-bounce">
                            <div className="sk-child sk-bounce1"></div>
                            <div className="sk-child sk-bounce2"></div>
                            <div className="sk-child sk-bounce3"></div>
                        </div>
                    </div>  
                   }
                >
                    <Index />
                </Suspense>
            </>
        );
	
	}else{
        checkLoginValidation(location);
		return (
			<div className="vh-100">
                <Suspense fallback={
                    <div id="preloader">
                        <div className="sk-three-bounce">
                            <div className="sk-child sk-bounce1"></div>
                            <div className="sk-child sk-bounce2"></div>
                            <div className="sk-child sk-bounce3"></div>
                        </div>
                    </div>
                  }
                >
                    {routeblog}
                </Suspense>
			</div>
		);
	}
};

const mapStateToProps = (state) => {
    return {
        isAuthenticated: isAuthenticated(state),
    };
};

export default withRouter(connect(mapStateToProps)(App)); 