export const transformReducerData = (state, action) => {
  switch (action.type) {
    case "collpase1":
      return { ...state, collpase1: !state.collpase1 };
    case "collpase2":
      return { ...state, collpase2: !state.collpase2 };
    case "collpase3":
      return { ...state, collpase3: !state.collpase3 };
    case "collpase4":
      return { ...state, collpase4: !state.collpase4 };
    case "collpase5":
      return { ...state, collpase5: !state.collpase5 };
    case "collpase6":
      return { ...state, collpase6: !state.collpase6 };
    case "collpase7":
      return { ...state, collpase7: !state.collpase7 };

    default:
      return state;
  }
};
