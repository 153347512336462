import axios from "axios";
import { store } from "../../store/store";
import { validateTokenAction } from "../../store/actions/AuthActions";

const AxiosInstanceWithAuth = axios.create({
  /* baseURL: `https://cors-anywhere.herokuapp.com/https://www.finsintms.com/tms-react-node-api/api/v1`, */
  baseURL: `https://www.finsintms.com/tms-react-node-api/api/v1`,
});

AxiosInstanceWithAuth.interceptors.request.use((config) => {
  const state = store.getState();
  const token = state.auth.auth.idToken;
  if (token) {
    config.params = config.params || {};
    config.headers["Authorization"] = `Bearer ${token}`;
    store.dispatch(validateTokenAction(token));
  }
  return config;
});

AxiosInstanceWithAuth.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401 || error.response.status === 403) {
      store.dispatch({ type: "INVALIDATE_TOKEN" });
      window.location.href = "/logout";
    }
    return Promise.reject(error);
  }
);

export default AxiosInstanceWithAuth;
