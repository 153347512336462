import React, { Fragment } from "react";
import { Row, Col, Card } from "react-bootstrap";
import PageTitle from "../../../layouts/PageTitle";
import { UserBranchManagement,LoadManagement } from "../setting/dashboard-user-branch-management";

function Dashboard() {
   
    return (
        <>
            <PageTitle motherMenu="Home" activeMenu="Settings" />
            <Row>

                <Col xl={4} lg={4}>
                <Card>
                    <Card.Header className="pb-0 d-block">
                        <h5 className="card-title"><i className="bi bi-building"></i>Company Settings</h5>
                        <p>These settings affect all users in your company</p>
                    </Card.Header>
                    <Card.Body>
                         <UserBranchManagement/> 
                    </Card.Body>
                </Card>
                </Col>
                <Col xl={4} lg={4}>
                <Card>
                    <Card.Header className="pb-0 d-block">
                        <h5 className="card-title"><i className="bi bi-people"></i>User and Branch  Management</h5>
                        <p>These settings affect all users in your company</p>
                    </Card.Header>
                    <Card.Body>
                          <UserBranchManagement/>  
                    </Card.Body>
                </Card>
                </Col>

                <Col xl={4} lg={4}>
                <Card>
                    <Card.Header className="pb-0 d-block">
                        <h5 className="card-title"><i className="bi bi-star"></i>Premium Features and Billing Details</h5>
                        <p>These settings affect all users in your company</p>
                    </Card.Header>
                    <Card.Body>
                    <UserBranchManagement/> 
                    </Card.Body>
                </Card>
                </Col>
                <Col xl={4} lg={4}>
                <Card>
                    <Card.Header className="pb-0 d-block">
                        <h5 className="card-title"><i className="bi bi-star"></i>Premium Features and Billing Details</h5>
                        <p>These settings affect all users in your company</p>
                    </Card.Header>
                    <Card.Body>
                    <UserBranchManagement/> 
                    </Card.Body>
                </Card>
                </Col>
                <Col xl={4} lg={4}>
                <Card>
                    <Card.Header className="pb-0 d-block">
                        <h5 className="card-title"><i className="bi bi-star"></i>Premium Features and Billing Details</h5>
                        <p>These settings affect all users in your company</p>
                    </Card.Header>
                    <Card.Body>
                    <UserBranchManagement/> 
                    </Card.Body>
                </Card>
                </Col>
                <Col xl={4} lg={4}>
                <Card>
                    <Card.Header className="pb-0 d-block">
                        <h5 className="card-title"><i className="bi bi-star"></i>Load Management</h5>
                        <p>These settings affect all users in your company</p>
                    </Card.Header>
                    <Card.Body>
                     <LoadManagement/> 
                    </Card.Body>
                </Card>
                </Col>

            </Row>
        </>
    );


}
export default Dashboard;
