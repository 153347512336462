export const pwu_status_options = [
  { value: "", label: "Choose" },
  { value: "active", label: "Active" },
  { value: "inactive", label: "Inactive" },
];

export const pwu_power_unit_type_options = [
  { value: "", label: "Choose" },
  { value: "Bus", label: "Bus" },
  { value: "Self-Propelled", label: "Self-Propelled" },
  { value: "Straight Truck", label: "Straight Truck" },
  { value: "Tractor", label: "Tractor" },
  { value: "Truck", label: "Truck" },
];

export const pwu_fuel_type_options = [
  { value: "", label: "Choose" },
  { value: "Special Diesel", label: "Special Diesel" },
  { value: "Gasoline", label: "Gasoline" },
  { value: "Gasohol", label: "Gasohol" },
  { value: "Propane", label: "Propane" },
  { value: "LNG", label: "LNG" },
  { value: "CNG", label: "CNG" },
  { value: "Ethanol", label: "Ethanol" },
  { value: "Methanol", label: "Methanol" },
  { value: "E-85", label: "E-85" },
  { value: "M-85", label: "M-85" },
  { value: "A55", label: "A55" },
  { value: "Biodiesel", label: "Biodiesel" },
  { value: "Electricity", label: "Electricity" },
  { value: "Hydrogen", label: "Hydrogen" },
];

export const pwu_license_plate_state_province_options = [
  { value: "", label: "Choose" },
  { value: "Alabama", label: "Alabama" },
  { value: "Alaska", label: "Alaska" },
  { value: "Arizona", label: "Arizona" },
  { value: "Arkansas", label: "Arkansas" },
  { value: "Byram", label: "Byram" },
  { value: "California", label: "California" },
  { value: "Cokato", label: "Cokato" },
  { value: "Colorado", label: "Colorado" },
  { value: "Connecticut", label: "Connecticut" },
  { value: "Delaware", label: "Delaware" },
  { value: "District of Columbia", label: "District of Columbia" },
  { value: "Florida", label: "Florida" },
  { value: "Georgia", label: "Georgia" },
  { value: "Hawaii", label: "Hawaii" },
  { value: "Idaho", label: "Idaho" },
  { value: "Illinois", label: "Illinois" },
  { value: "Indiana", label: "Indiana" },
  { value: "Iowa", label: "Iowa" },
  { value: "Kansas", label: "Kansas" },
  { value: "Kentucky", label: "Kentucky" },
  { value: "Louisiana", label: "Louisiana" },
  { value: "Lowa", label: "Lowa" },
  { value: "Maine", label: "Maine" },
  { value: "Maryland", label: "Maryland" },
  { value: "Massachusetts", label: "Massachusetts" },
  { value: "Medfield", label: "Medfield" },
  { value: "Michigan", label: "Michigan" },
  { value: "Minnesota", label: "Minnesota" },
  { value: "Mississippi", label: "Mississippi" },
  { value: "Missouri", label: "Missouri" },
  { value: "Montana", label: "Montana" },
  { value: "Nebraska", label: "Nebraska" },
  { value: "Nevada", label: "Nevada" },
  { value: "New Hampshire", label: "New Hampshire" },
  { value: "New Jersey", label: "New Jersey" },
  { value: "New Jersy", label: "New Jersy" },
  { value: "New Mexico", label: "New Mexico" },
  { value: "New York", label: "New York" },
  { value: "North Carolina", label: "North Carolina" },
  { value: "North Dakota", label: "North Dakota" },
  { value: "Ohio", label: "Ohio" },
  { value: "Oklahoma", label: "Oklahoma" },
  { value: "Ontario", label: "Ontario" },
  { value: "Oregon", label: "Oregon" },
  { value: "Pennsylvania", label: "Pennsylvania" },
  { value: "Ramey", label: "Ramey" },
  { value: "Rhode Island", label: "Rhode Island" },
  { value: "South Carolina", label: "South Carolina" },
  { value: "South Dakota", label: "South Dakota" },
  { value: "Sublimity", label: "Sublimity" },
  { value: "Tennessee", label: "Tennessee" },
  { value: "Texas", label: "Texas" },
  { value: "Trimble", label: "Trimble" },
  { value: "Utah", label: "Utah" },
  { value: "Vermont", label: "Vermont" },
  { value: "Virginia", label: "Virginia" },
  { value: "Washington", label: "Washington" },
  { value: "West Virginia", label: "West Virginia" },
  { value: "Wisconsin", label: "Wisconsin" },
  { value: "Wyoming", label: "Wyoming" },
];

export const pwu_irp_registered_options = [
  { value: "", label: "Choose" },
  { value: "Alabama", label: "Alabama" },
  { value: "Alberta", label: "Alberta" },
  { value: "Arizona", label: "Arizona" },
  { value: "Arkansas", label: "Arkansas" },
  { value: "British Columbia", label: "British Columbia" },
  { value: "California", label: "California" },
  { value: "Colorado", label: "Colorado" },
  { value: "Connecticut", label: "Connecticut" },
  { value: "Delaware", label: "Delaware" },
  { value: "District Of Columbia", label: "District Of Columbia" },
  { value: "Florida", label: "Florida" },
  { value: "Georgia", label: "Georgia" },
  { value: "Idaho", label: "Idaho" },
  { value: "Illinois", label: "Illinois" },
  { value: "Indiana", label: "Indiana" },
  { value: "Iowa", label: "Iowa" },
  { value: "Kansas", label: "Kansas" },
  { value: "Kentucky", label: "Kentucky" },
  { value: "Louisiana", label: "Louisiana" },
  { value: "Maine", label: "Maine" },
  { value: "Manitoba", label: "Manitoba" },
  { value: "Maryland", label: "Maryland" },
  { value: "Massachusetts", label: "Massachusetts" },
  { value: "Michigan", label: "Michigan" },
  { value: "Minnesota", label: "Minnesota" },
  { value: "Mississippi", label: "Mississippi" },
  { value: "Missouri", label: "Missouri" },
  { value: "Montana", label: "Montana" },
  { value: "Nebraska", label: "Nebraska" },
  { value: "Nevada", label: "Nevada" },
  { value: "New Brunswick", label: "New Brunswick" },
  { value: "New Hampshire", label: "New Hampshire" },
  { value: "New Jersey", label: "New Jersey" },
  { value: "New Mexico", label: "New Mexico" },
  { value: "New York", label: "New York" },
  { value: "Newfoundland and Labrador", label: "Newfoundland and Labrador" },
  { value: "North Carolina", label: "North Carolina" },
  { value: "North Dakota", label: "North Dakota" },
  { value: "Nova Scotia", label: "Nova Scotia" },
  { value: "Ohio", label: "Ohio" },
  { value: "Oklahoma", label: "Oklahoma" },
  { value: "Ontario", label: "Ontario" },
  { value: "Oregon", label: "Oregon" },
  { value: "Pennsylvania", label: "Pennsylvania" },
  { value: "Prince Edward Island", label: "Prince Edward Island" },
  { value: "Quebec", label: "Quebec" },
  { value: "Rhode Island", label: "Rhode Island" },
  { value: "Saskatchewan", label: "Saskatchewan" },
  { value: "South Carolina", label: "South Carolina" },
  { value: "South Dakota", label: "South Dakota" },
  { value: "Tennessee", label: "Tennessee" },
  { value: "Texas", label: "Texas" },
  { value: "Utah", label: "Utah" },
  { value: "Vermont", label: "Vermont" },
  { value: "Virginia", label: "Virginia" },
  { value: "Washington", label: "Washington" },
  { value: "West Virginia", label: "West Virginia" },
  { value: "Wisconsin", label: "Wisconsin" },
  { value: "Wyoming", label: "Wyoming" },
];

export const pwu_ifta_registered_options = pwu_irp_registered_options;

export const pwu_ownership_options = [
  { value: "", label: "Choose" },
  { value: "Company", label: "Company" },
  { value: "Owner/Operator", label: "Owner/Operator" },
];

export const pwu_purchased_or_leased_options = [
  { value: "", label: "Choose" },
  { value: "Purchased", label: "Purchased" },
  { value: "Leased", label: "Leased" },
];

export const powerUnitInitialValues = {
    pwu_power_unit_number: "",
    pwu_status: "",
    pwu_make: "",
    pwu_model: "",
    pwu_model_year: "",
    pwu_vehicle_id_number: "",
    pwu_power_unit_type: "",
    pwu_engine_type: "",
    pwu_transmission_type: "",
    pwu_fuel_type: "",
    pwu_horsepower: "",
    pwu_license_plate: "",
    pwu_license_plate_state_province: "",
    pwu_insurance_information: "",
    pwu_insurance_policy: "",
    pwu_insurance_effective_date: "",
    pwu_length: "",
    pwu_width: "",
    pwu_height: "",
    pwu_number_of_axles: "",
    pwu_unloaded_vehicle_weight: "",
    pwu_gross_vehicle_weight: "",
    pwu_irp_registered: "",
    pwu_ifta_registered: "",
    pwu_irp_account: "",
    pwu_ifta_account: "",
    pwu_notes: "",
    pwu_ownership: "",
    pwu_purchased_or_leased: "",
    pwu_purchased_leased_from: "",
    pwu_sold_to: "",
    pwu_purchase_lease_date: "",
    pwu_sold_date_lease_end_date: "",
    pwu_purchase_lease_amount: "",
    pwu_sold_amount: "",
    pwu_factory_price: "",
    pwu_current_value: "",
    pwu_license_plate_expiration: "",
    pwu_inspection_expiration: "",
    pwu_dot_expiration: "",
    pwu_registration_expiration: "",
    pwu_irp_expiration: "",
    pwu_ifta_expiration: "",
    pwu_insurance_expiration: "",
    pwu_est_odometer_reading: "",
    pwu_last_oil_change_date: "",
    pwu_last_oil_change_mileage: "",
    pwu_last_tune_up_mileage: "",
    pwu_last_service_date: "",
    pwu_last_service_mileage: "",
  };