import * as Yup from "yup";

const CarrierValidationSchema = Yup.object().shape({
  carrier_name: Yup.string().required("Carrier name is required"),
  carrier_mobile: Yup.string()
  .matches(
    /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
    "Invalid mobile format"
  ),
  carrier_branch: Yup.string().required("Branch is required"),
  carrier_address: Yup.string().required("Carrier address is required"),
  carrier_primary_insurance_expiration: Yup.string().required(
    "Primary Insurance Expiration is required"
  ),
  carrier_meta: Yup.array().of(
    Yup.object().shape({
      contact_name: Yup.string().required("Required"),
      contact_mobile: Yup.string()
        .matches(
          /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
          "Invalid contact mobile format"
        )
        .required("Required"),
      /* contact_mobile_ext: Yup.string().required("Required"),
        contact_designation: Yup.string().required("Required"),
        contact_email: Yup.string()
          .email("Must be a valid email")
          .required("Required"),
        contact_fax: Yup.string().required("Required"), */
    })
  ),
});

export default CarrierValidationSchema;
