import React, { useMemo, useReducer } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import PowerUnitValidationSchema from "./helpers/PowerUnitValidationSchema";
import {
  addPowerUnit,
  updatePowerUnit,
  getPowerUnitById,
} from "../../../../../services/CustomServices/PowerUnitService";
import { Link, useParams, useNavigate } from "react-router-dom";
import { Collapse } from "react-bootstrap";
import PageTitle from "../../../../layouts/PageTitle";
import { toast } from "react-toastify";
import CustomSelect from "../../../../components/CustomComponents/Select";
import { fetchMakeList, transformReducerData } from "./helpers/function";
import {
  powerUnitInitialValues,
  pwu_status_options,
  pwu_power_unit_type_options,
  pwu_fuel_type_options,
  pwu_license_plate_state_province_options,
  pwu_irp_registered_options,
  pwu_ifta_registered_options,
  pwu_ownership_options,
  pwu_purchased_or_leased_options,
} from "./helpers/constant";

const AddPowerUnit = () => {
  const [state, dispatch] = useReducer(transformReducerData, true);
  const { powerUnitId } = useParams();
  const [powerUnit, setPowerUnit] = React.useState(null);
  const navigate = useNavigate();
  const [pwu_make_options, setPwuMakeOptions] = React.useState([]);

  /* const initialValues = powerUnit || powerUnitInitialValues; */
  const initialState = useMemo(() => powerUnit || powerUnitInitialValues, [powerUnit]);

  // Get dropdowns value from API on page render
  React.useEffect(() => {
    fetchMakeList().then((options) => {
      setPwuMakeOptions(options);
    });
  }, []);

  // Get Power Unit data by ID and set in particuar fields (Call only when powerUnitId available)
  React.useEffect(() => {
    if (!powerUnitId) return;
    const fetchPowerUnit = async () => {
      if (powerUnitId) {
        try {
          const powerUnitData = await getPowerUnitById(powerUnitId);
          if (
            powerUnitData &&
            powerUnitData.result &&
            powerUnitData.result.length > 0
          ) {
            setPowerUnit(powerUnitData.result[0]);
          }
        } catch (error) {
          console.error("Error fetching power unit:", error);
        }
      }
    };

    fetchPowerUnit();
  }, [powerUnitId]);

  const onSubmit = async (values, { setSubmitting }) => {
    try {
      await PowerUnitValidationSchema.validate(values, { abortEarly: false });

      if (powerUnit && powerUnit.id) {
        try {
          await updatePowerUnit(
            powerUnit.id,
            values
          );
          toast.success("Power Unit updated successfully!");
          navigate("/assets/power-unit-list", { replace: true });
        } catch (error) {
          toast.error("Error updating power unit!");
        }
      } else {
        try {
          await addPowerUnit(values);
          toast.success("Power Unit added successfully!");
          setTimeout(() => {
            navigate("/assets/power-unit-list", { replace: true });
          }, 2000);
        } catch (error) {
          toast.error("Error adding power unit!");
          throw error;
        }
      }
    } catch (error) {
      const errorMessages = error.inner.map((err) => err.message);
      toast.error(`Validation errors: ${errorMessages.join(", ")}`, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      alert(error);
    }
    setSubmitting(false);
  };

  return (
    <Formik
      initialValues={initialState}
      validationSchema={PowerUnitValidationSchema}
      enableReinitialize // This ensures form is updated with data when editing
      validateOnBlur={false}
      validateOnChange={true}
      validateOnMount={true}
      /* onSubmit={onSubmit} */
      onSubmit={async (values, { setSubmitting, validateForm }) => {
        const errors = await validateForm(values);
        if (Object.keys(errors).length === 0) {
          onSubmit(values, { setSubmitting });
        } else {
          console.log("Power unit form is not valid:", errors);
        }
      }}
    >
      {({
        dirty,
        isSubmitting,
        handleSubmit,
        errors,
        setFieldValue,
        values,
      }) => (
        <Form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-xl-12">
              <PageTitle activeMenu="Add Power Unit" motherMenu="Power Unit" />
              <div className="mb-3">
                <ul className="d-flex align-items-center flex-wrap">
                  <li>
                    <Link
                      to={"/assets/power-unit-list"}
                      className="btn btn-primary btn-sm mx-1"
                    >
                      Power Unit List
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="row">
                <div className="col-xl-8 col-xxl-12">
                  <div className="filter cm-content-box box-primary">
                    <div className="content-title py-0">
                      <div className="cpa">Asset Profile</div>
                      <div className="tools">
                        <Link
                          to={"#"}
                          className={`SlideToolHeader ${
                            state.collpase1 ? "collapse" : "expand"
                          }`}
                          onClick={() => dispatch({ type: "collpase1" })}
                        >
                          <i className="fas fa-angle-up"></i>
                        </Link>
                      </div>
                    </div>
                    <Collapse in={!state.collpase1}>
                      <div className="cm-content-body form excerpt">
                        <div className="card-body py-3">
                          <div className="row">
                            <div className="col-xl-4 col-sm-6 col-md-3">
                              <label className=" form-label mb-1">
                                Power Unit Number{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <Field
                                type="text"
                                name="pwu_power_unit_number"
                                className="form-control form-control-sm mb-3"
                                placeholder="Power Unit Name"
                              />
                              <ErrorMessage
                                name="pwu_power_unit_number"
                                component="div"
                                className="text-danger form-text mb-3"
                              />
                            </div>

                            <div className="col-xl-4 col-sm-6 col-md-3">
                              <label className=" form-label mb-1">Status</label>
                              <div className="input-group mb-3">
                                <label className="input-group-text mb-0">
                                  Status
                                </label>
                                <CustomSelect
                                  name="pwu_status"
                                  options={pwu_status_options}
                                  className="form-control form-control-sm wide"
                                />
                              </div>
                            </div>

                            <div className="col-xl-4 col-sm-6 col-md-3">
                              <label className=" form-label mb-1">Make</label>
                              <div className="input-group mb-3">
                                <label className="input-group-text mb-0">
                                  Make
                                </label>
                                <CustomSelect
                                  name="pwu_make"
                                  options={pwu_make_options}
                                  className="form-control form-control-sm wide"
                                />
                              </div>
                            </div>

                            <div className="col-xl-4 col-sm-6 col-md-3">
                              <label className=" form-label mb-1">Model</label>
                              <Field
                                type="text"
                                name="pwu_model"
                                className="form-control form-control-sm mb-3"
                              />
                            </div>

                            <div className="col-xl-4 col-sm-6 col-md-3">
                              <label className=" form-label mb-1">
                                Model Year
                              </label>
                              <Field
                                type="number"
                                name="pwu_model_year"
                                className="form-control form-control-sm mb-3"
                              />
                            </div>

                            <div className="col-xl-4 col-sm-6 col-md-3">
                              <label className=" form-label mb-1">
                                Vehicle ID Number
                              </label>
                              <Field
                                type="text"
                                name="pwu_vehicle_id_number"
                                className="form-control form-control-sm mb-3"
                              />
                            </div>

                            <div className="col-xl-4 col-sm-6 col-md-3">
                              <label className=" form-label mb-1">
                                Power Unit Type
                              </label>
                              <div className="input-group mb-3">
                                <label className="input-group-text mb-0">
                                  Unit Type
                                </label>
                                <CustomSelect
                                  name="pwu_power_unit_type"
                                  options={pwu_power_unit_type_options}
                                  className="form-control form-control-sm wide"
                                />
                              </div>
                            </div>

                            <div className="col-xl-4 col-sm-6 col-md-3">
                              <label className=" form-label mb-1">
                                Engine Type
                              </label>
                              <Field
                                type="text"
                                name="pwu_engine_type"
                                className="form-control form-control-sm mb-3"
                              />
                            </div>

                            <div className="col-xl-4 col-sm-6 col-md-3">
                              <label className=" form-label mb-1">
                                Transmission Type
                              </label>
                              <Field
                                type="text"
                                name="pwu_transmission_type"
                                className="form-control form-control-sm mb-3"
                              />
                            </div>

                            <div className="col-xl-4 col-sm-6 col-md-3">
                              <label className=" form-label mb-1">
                                Fuel Type
                              </label>
                              <div className="input-group mb-3">
                                <label className="input-group-text mb-0">
                                  Fuel Type
                                </label>
                                <CustomSelect
                                  name="pwu_fuel_type"
                                  options={pwu_fuel_type_options}
                                  className="form-control form-control-sm wide"
                                />
                              </div>
                            </div>

                            <div className="col-xl-4 col-sm-6 col-md-3">
                              <label className=" form-label mb-1">
                                Horsepower
                              </label>
                              <Field
                                type="text"
                                name="pwu_horsepower"
                                className="form-control form-control-sm mb-3"
                              />
                            </div>

                            <div className="col-xl-4 col-sm-6 col-md-3">
                              <label className=" form-label mb-1">
                                License Plate
                              </label>
                              <Field
                                type="text"
                                name="pwu_license_plate"
                                className="form-control form-control-sm mb-3"
                              />
                            </div>

                            <div className="col-xl-4 col-sm-6 col-md-3">
                              <label className=" form-label mb-1">
                                License Plate State/Province
                              </label>
                              <div className="input-group mb-3">
                                <label className="input-group-text mb-0">
                                  State/Province
                                </label>
                                <CustomSelect
                                  name="pwu_license_plate_state_province"
                                  options={
                                    pwu_license_plate_state_province_options
                                  }
                                  className="form-control form-control-sm wide"
                                />
                              </div>
                            </div>

                            <div className="col-xl-4 col-sm-6 col-md-3">
                              <label className=" form-label mb-1">
                                Insurance Information
                              </label>
                              <Field
                                type="text"
                                name="pwu_insurance_information"
                                className="form-control form-control-sm mb-3"
                              />
                            </div>

                            <div className="col-xl-4 col-sm-6 col-md-3">
                              <label className=" form-label mb-1">
                                Insurance Policy #
                              </label>
                              <Field
                                type="text"
                                name="pwu_insurance_policy"
                                className="form-control form-control-sm mb-3"
                              />
                            </div>

                            <div className="col-xl-4 col-sm-6 col-md-3">
                              <label className=" form-label mb-1">
                                Insurance Effective Date
                              </label>
                              <Field
                                type="date"
                                name="pwu_insurance_effective_date"
                                className="form-control form-control-sm mb-3"
                              />
                            </div>

                            <div className="col-xl-4 col-sm-6 col-md-3">
                              <label className=" form-label mb-1">Length</label>
                              <Field
                                type="text"
                                name="pwu_length"
                                className="form-control form-control-sm mb-3"
                              />
                            </div>

                            <div className="col-xl-4 col-sm-6 col-md-3">
                              <label className=" form-label mb-1">Width</label>
                              <Field
                                type="text"
                                name="pwu_width"
                                className="form-control form-control-sm mb-3"
                              />
                            </div>

                            <div className="col-xl-4 col-sm-6 col-md-3">
                              <label className=" form-label mb-1">Height</label>
                              <Field
                                type="text"
                                name="pwu_height"
                                className="form-control form-control-sm mb-3"
                              />
                            </div>

                            <div className="col-xl-4 col-sm-6 col-md-3">
                              <label className=" form-label mb-1">
                                Number of Axles
                              </label>
                              <Field
                                type="text"
                                name="pwu_number_of_axles"
                                className="form-control form-control-sm mb-3"
                              />
                            </div>

                            <div className="col-xl-4 col-sm-6 col-md-3">
                              <label className=" form-label mb-1">
                                Unloaded Vehicle Weight
                              </label>
                              <Field
                                type="text"
                                name="pwu_unloaded_vehicle_weight"
                                className="form-control form-control-sm mb-3"
                              />
                            </div>

                            <div className="col-xl-4 col-sm-6 col-md-3">
                              <label className=" form-label mb-1">
                                Gross Vehicle Weight
                              </label>
                              <Field
                                type="text"
                                name="pwu_gross_vehicle_weight"
                                className="form-control form-control-sm mb-3"
                              />
                            </div>

                            <div className="col-xl-4 col-sm-6 col-md-3">
                              <label className=" form-label mb-1">
                                IRP Registered?
                              </label>
                              <div className="input-group mb-3">
                                <label className="input-group-text mb-0">
                                  IRP
                                </label>
                                <CustomSelect
                                  name="pwu_irp_registered"
                                  options={pwu_irp_registered_options}
                                  className="form-control form-control-sm wide"
                                />
                              </div>
                            </div>

                            <div className="col-xl-4 col-sm-6 col-md-3">
                              <label className=" form-label mb-1">
                                IFTA Registered?
                              </label>
                              <div className="input-group mb-3">
                                <label className="input-group-text mb-0">
                                  IFTA
                                </label>
                                <CustomSelect
                                  name="pwu_ifta_registered"
                                  options={pwu_ifta_registered_options}
                                  className="form-control form-control-sm wide"
                                />
                              </div>
                            </div>

                            <div className="col-xl-4 col-sm-6 col-md-3">
                              <label className=" form-label mb-1">
                                IRP Account #
                              </label>
                              <Field
                                type="text"
                                name="pwu_irp_account"
                                className="form-control form-control-sm mb-3"
                              />
                            </div>

                            <div className="col-xl-4 col-sm-6 col-md-3">
                              <label className=" form-label mb-1">
                                IFTA Account #
                              </label>
                              <Field
                                type="text"
                                name="pwu_ifta_account"
                                className="form-control form-control-sm mb-3"
                              />
                            </div>

                            <div className="col-xl-4 col-sm-6 col-md-3">
                              <label className=" form-label mb-1">Notes</label>
                              <Field
                                as="textarea"
                                name="pwu_notes"
                                className="form-control form-control-sm"
                                placeholder=""
                                rows="2"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </Collapse>
                  </div>

                  <div className="filter cm-content-box box-primary">
                    <div className="content-title py-0">
                      <div className="cpa">Ownership Info</div>
                      <div className="tools">
                        <Link
                          to={"#"}
                          className={`SlideToolHeader ${
                            state.collpase2 ? "collapse" : "expand"
                          }`}
                          onClick={() => dispatch({ type: "collpase2" })}
                        >
                          <i className="fas fa-angle-up"></i>
                        </Link>
                      </div>
                    </div>
                    <Collapse in={!state.collpase2}>
                      <div className="cm-content-body form excerpt">
                        <div className="card-body py-3">
                          <div className="row">
                            <div className="col-xl-4 col-sm-6 col-md-3">
                              <label className=" form-label mb-1">
                                Ownership
                              </label>
                              <div className="input-group mb-3">
                                <CustomSelect
                                  name="pwu_ownership"
                                  options={pwu_ownership_options}
                                  className="form-control form-control-sm wide"
                                />
                              </div>
                            </div>

                            <div className="col-xl-4 col-sm-6 col-md-3">
                              <label className=" form-label mb-1">
                                Purchased or Leased?
                              </label>
                              <div className="input-group mb-3">
                                <CustomSelect
                                  name="pwu_purchased_or_leased"
                                  options={pwu_purchased_or_leased_options}
                                  className="form-control form-control-sm wide"
                                />
                              </div>
                            </div>

                            <div className="col-xl-4 col-sm-6 col-md-3">
                              <label className=" form-label mb-1">
                                Purchased/Leased From
                              </label>
                              <Field
                                type="text"
                                name="pwu_purchased_leased_from"
                                className="form-control form-control-sm mb-3"
                              />
                            </div>

                            <div className="col-xl-4 col-sm-6 col-md-3">
                              <label className=" form-label mb-1">
                                Sold To
                              </label>
                              <Field
                                type="text"
                                name="pwu_sold_to"
                                className="form-control form-control-sm mb-3"
                              />
                            </div>

                            <div className="col-xl-4 col-sm-6 col-md-3">
                              <label className=" form-label mb-1">
                                Purchase/Lease Date
                              </label>
                              <Field
                                type="date"
                                name="pwu_purchase_lease_date"
                                className="form-control form-control-sm mb-3"
                              />
                            </div>

                            <div className="col-xl-4 col-sm-6 col-md-3">
                              <label className=" form-label mb-1">
                                Sold Date/Lease End Date
                              </label>
                              <Field
                                type="date"
                                name="pwu_sold_date_lease_end_date"
                                className="form-control form-control-sm mb-3"
                              />
                            </div>

                            <div className="col-xl-4 col-sm-6 col-md-3">
                              <label className=" form-label mb-1">
                                Purchase/Lease Amount
                              </label>
                              <Field
                                type="number"
                                name="pwu_purchase_lease_amount"
                                className="form-control form-control-sm mb-3"
                              />
                            </div>

                            <div className="col-xl-4 col-sm-6 col-md-3">
                              <label className=" form-label mb-1">
                                Sold Amount
                              </label>
                              <Field
                                type="number"
                                name="pwu_sold_amount"
                                className="form-control form-control-sm mb-3"
                              />
                            </div>

                            <div className="col-xl-4 col-sm-6 col-md-3">
                              <label className=" form-label mb-1">
                                Factory Price
                              </label>
                              <Field
                                type="number"
                                name="pwu_factory_price"
                                className="form-control form-control-sm mb-3"
                              />
                            </div>

                            <div className="col-xl-4 col-sm-6 col-md-3">
                              <label className=" form-label mb-1">
                                Current Value
                              </label>
                              <Field
                                type="number"
                                name="pwu_current_value"
                                className="form-control form-control-sm mb-3"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </Collapse>
                  </div>
                </div>
                <div className="col-xl-4 col-xxl-12">
                  <div className="filter cm-content-box box-primary">
                    <div className="content-title py-0">
                      <div className="cpa">Maintenance and Safety</div>
                      <div className="tools">
                        <Link
                          to={"#"}
                          className={`SlideToolHeader ${
                            state.collpase3 ? "collapse" : "expand"
                          }`}
                          onClick={() => dispatch({ type: "collpase3" })}
                        >
                          <i className="fas fa-angle-up"></i>
                        </Link>
                      </div>
                    </div>
                    <Collapse in={!state.collpase3}>
                      <div className="cm-content-body form excerpt">
                        <div className="card-body py-3">
                          <div className="row">
                            <div className="col-sm-6 col-md-4 col-lg-4- col-xl-12 col-xxl-4">
                              <label className=" form-label mb-1">
                                License Plate Expiration
                              </label>
                              <Field
                                type="date"
                                name="pwu_license_plate_expiration"
                                className="form-control form-control-sm mb-3"
                              />
                            </div>

                            <div className="col-sm-6 col-md-4 col-lg-4- col-xl-12 col-xxl-4">
                              <label className=" form-label mb-1">
                                Inspection Expiration
                              </label>
                              <Field
                                type="date"
                                name="pwu_inspection_expiration"
                                className="form-control form-control-sm mb-3"
                              />
                            </div>

                            <div className="col-sm-6 col-md-4 col-lg-4- col-xl-12 col-xxl-4">
                              <label className=" form-label mb-1">
                                DOT Expiration
                              </label>
                              <Field
                                type="date"
                                name="pwu_dot_expiration"
                                className="form-control form-control-sm mb-3"
                              />
                            </div>

                            <div className="col-sm-6 col-md-4 col-lg-4- col-xl-12 col-xxl-4">
                              <label className=" form-label mb-1">
                                Registration Expiration
                              </label>
                              <Field
                                type="date"
                                name="pwu_registration_expiration"
                                className="form-control form-control-sm mb-3"
                              />
                            </div>

                            <div className="col-sm-6 col-md-4 col-lg-4- col-xl-12 col-xxl-4">
                              <label className=" form-label mb-1">
                                IRP Expiration
                              </label>
                              <Field
                                type="date"
                                name="pwu_irp_expiration"
                                className="form-control form-control-sm mb-3"
                              />
                            </div>

                            <div className="col-sm-6 col-md-4 col-lg-4- col-xl-12 col-xxl-4">
                              <label className=" form-label mb-1">
                                IFTA Expiration
                              </label>
                              <Field
                                type="date"
                                name="pwu_ifta_expiration"
                                className="form-control form-control-sm mb-3"
                              />
                            </div>

                            <div className="col-sm-6 col-md-4 col-lg-4- col-xl-12 col-xxl-4">
                              <label className=" form-label mb-1">
                                Insurance Expiration
                              </label>
                              <Field
                                type="date"
                                name="pwu_insurance_expiration"
                                className="form-control form-control-sm mb-3"
                              />
                            </div>

                            <div className="col-sm-6 col-md-4 col-lg-4- col-xl-12 col-xxl-4">
                              <label className=" form-label mb-1">
                                Est. Odometer Reading
                              </label>
                              <Field
                                type="text"
                                name="pwu_est_odometer_reading"
                                className="form-control form-control-sm mb-3"
                              />
                            </div>

                            <div className="col-sm-6 col-md-4 col-lg-4- col-xl-12 col-xxl-4">
                              <label className=" form-label mb-1">
                                Last Oil Change Date
                              </label>
                              <Field
                                type="date"
                                name="pwu_last_oil_change_date"
                                className="form-control form-control-sm mb-3"
                              />
                            </div>

                            <div className="col-sm-6 col-md-4 col-lg-4- col-xl-12 col-xxl-4">
                              <label className=" form-label mb-1">
                                Last Oil Change Mileage
                              </label>
                              <Field
                                type="text"
                                name="pwu_last_oil_change_mileage"
                                className="form-control form-control-sm mb-3"
                              />
                            </div>

                            <div className="col-sm-6 col-md-4 col-lg-4- col-xl-12 col-xxl-4">
                              <label className=" form-label mb-1">
                                Last Tune up Date
                              </label>
                              <Field
                                type="date"
                                name="pwu_last_tune_up_date"
                                className="form-control form-control-sm mb-3"
                              />
                            </div>

                            <div className="col-sm-6 col-md-4 col-lg-4- col-xl-12 col-xxl-4">
                              <label className=" form-label mb-1">
                                Last Tune up Mileage
                              </label>
                              <Field
                                type="text"
                                name="pwu_last_tune_up_mileage"
                                className="form-control form-control-sm mb-3"
                              />
                            </div>

                            <div className="col-sm-6 col-md-4 col-lg-4- col-xl-12 col-xxl-4">
                              <label className=" form-label mb-1">
                                Last Service Date
                              </label>
                              <Field
                                type="date"
                                name="pwu_last_service_date"
                                className="form-control form-control-sm mb-3"
                              />
                            </div>

                            <div className="col-sm-6 col-md-4 col-lg-4- col-xl-12 col-xxl-4">
                              <label className=" form-label mb-1">
                                Last Service Mileage
                              </label>
                              <Field
                                type="text"
                                name="pwu_last_service_mileage"
                                className="form-control form-control-sm mb-3"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </Collapse>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="form-group mt-3">
            <button
              type="submit"
              className="btn btn-primary"
              disabled={!dirty || isSubmitting}
              onClick={() => {
                if (Object.keys(errors).length > 0) {
                  const firstErrorField = Object.keys(errors)[0];
                  document.getElementsByName(firstErrorField)[0].focus();
                }
              }}
            >
              Submit
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default AddPowerUnit;
