/* export const allBranches = [
    { value: "", label: "Select Branch", color: "#FF8B00" },
    { value: "1", label: "Shared", color: "#FF8B00" },
    { value: "2", label: "Only Me", color: "#FFC400" },
    { value: "3", label: "My Team", color: "#36B37E" },
  ]; */

export const allClasses = [
  { value: "", label: "Select Class", color: "#FF8B00" },
  { value: "1", label: "Class 1", color: "#FF8B00" },
  { value: "2", label: "Class 2", color: "#FFC400" },
  { value: "3", label: "Class 3", color: "#36B37E" },
];

export const locationRequirements = [
  { value: "1", label: "Liftgate Service Needed" },
  { value: "2", label: "Appointment Required" },
  { value: "3", label: "Inside Pickup/Delivery" },
  { value: "4", label: "Call Before Pickup/Delivery" },
];

export const screenOption = [
  { id: "20", title: "Find Location", series: "26" },
  { id: "21", title: "Location Address", series: "27" },
  { id: "22", title: "Contact List", series: "28" },
  /* { id: "23", title: "Accounting", series: "29" }, */
  { id: "24", title: "Notes (Optional)", series: "30" },
  { id: "25", title: "Location Image", series: "31" },
];

export const initialContactValue = {
  contact_is_primary: 1,
  contact_name: "",
  contact_designation: "",
  contact_mobile: "",
  contact_mobile_ext: "",
  contact_email: "",
  contact_fax: "",
  contact_add_to_email_list: "",
};

export const initialValues = {
  location_name: "",
  location_address: "",
  location_mobile: "",
  location_mobile_ext: "",
  location_branch: "",
  location_class: "",
  location_requirements: "",
  location_private_notes: "",
  location_public_notes: "",
  location_meta: [initialContactValue],
};