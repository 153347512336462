import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import PageTitle from "../../../layouts/PageTitle";
import { ProjectAttributeSaveAPI,GetProjectAttributeListAPI,ProjectAttributeDeletedAPI } from '../../../../services/SettingService';
import { SwatAlertLodingMsg,SwatAlertSuccessTopRight,SwatAlertErrorTopRight,SwatAlertConfirmDeleted } from '../../../components/tms/Sweet-alert-component';
function SettingPermissionGroupsTMS() {

    const menu_list = [
                        {id:"1",name:"Dashboard"},
                        {id:"2",name:"Customer"},
                        {id:"3",name:"location"},
                        {id:"4",name:"Carrier"},
                        {id:"5",name:"Settings"}
    ];

    const [MenuList,setMenuList] = useState(menu_list);
    const [PermissionList,setPermissionList] = useState(null);
    const[Loading,setLoading] = useState(true);
    const [addEditPermissionGroupModal, setaddEditPermissionGroupModal] = useState(false);
    const [errorMsg,setErrorMsg] = useState(null);
    const [formValues, setFormValues] = useState({
        name: '',
        copy_from:''
      });

    const fetchPermissionGroupData = async () => {

        let postData = {};
        postData.project_key = "permission-groups";
        const ListResponse = await GetProjectAttributeListAPI(postData);
        if(ListResponse){
            if(ListResponse.status==="200"){
                const dataPermission = [...ListResponse.result].sort((a, b) => a.id - b.id); 
                setPermissionList(dataPermission)
                setLoading(false);
            } else {
                SwatAlertErrorTopRight("Server Not Working"); 
            }
        } else {
            SwatAlertErrorTopRight("Server Not Working"); 
        }
    };


    

    useEffect(() => {
       
        fetchPermissionGroupData();
      },[]);
 
      const handleInputChange = (event) => {
       
        const { name, value } = event.target;
        setFormValues({
        ...formValues,  // Spread the previous state
        [name]: value   // Dynamically update the specific field
        });
    
      };   
     const handleSubmit = async(e)=>{

        e.preventDefault();
        if(!formValues.name) {
            setErrorMsg("Group Name Required");
            return false;
        }
        SwatAlertLodingMsg("Please Wait...");
        formValues.project_key="permission-groups";
        const SaveResponse = await ProjectAttributeSaveAPI(formValues,'permission-groups');
        console.log(SaveResponse);
        if(SaveResponse) {
            if(SaveResponse.status==="200"){
                SwatAlertSuccessTopRight(SaveResponse.message); 
            } else {
                SwatAlertErrorTopRight(SaveResponse.message); 
            }
        }
        setaddEditPermissionGroupModal(false)
         fetchPermissionGroupData();    

     } 
     const ModelOpenPermissionGroup = (action,data) => {
        setErrorMsg("");
        setaddEditPermissionGroupModal(true);
        setFormValues({name:''});
        if(action=="edit"){
            setFormValues(data);
        }
        console.log(data);
     }

     const DeletedGroup = async(id) => {

        const result =   await SwatAlertConfirmDeleted();
        if(result.isConfirmed) {
            SwatAlertLodingMsg("Please Wait...");
           
             const DeletedResponse = await ProjectAttributeDeletedAPI('permission-groups',id);
             if(DeletedResponse){
                if(DeletedResponse.status==="200"){

                    fetchPermissionGroupData();
                    setaddEditPermissionGroupModal(false);
                    SwatAlertSuccessTopRight('Permission Groups Successully Deleted');  
                 } else {
                    SwatAlertErrorTopRight(DeletedResponse.message); 
                }
             }
        }

     }
    
    return (
        <>
            <PageTitle motherMenu="Setting" activeMenu="Users" />
            <div className="card">
				<div className="card-header">
                     <button className="btn btn-primary add_new btn-sm" id="nav-forget-tab" type="button" onClick={() => ModelOpenPermissionGroup('add','')} >
                        	<i className="fa fa-fw fa-plus"></i> Add New Permission Group
                        </button>
                </div>
                <div className="card-body">
					<div className="table-responsive">
                           <div className="dataTables_wrapper">
                                <table role="table" className="finsintms table table-bordered table-hover verticle-middle text-center table-responsive-sm">
                                       
                                <thead>
                                  
                                        {Loading ? (
                                            <tr>
                                               <td>Loading...</td> 
                                              
                                            </tr>   

                                        ):(
                                            
                                            <tr>
                                                <th></th>         
                                                {PermissionList.map((data,key)=>(
                                            
                                                    <th style={{textAlign:"center"}} key={key} scope="col">
                                                        {data.name}

                                                            {key===0 ? (
                                                                 <i className="fa fa-lock text-danger"></i>  
                                                            ):(
                                                                <i className="bi bi-pencil-square text-success" onClick={() => ModelOpenPermissionGroup('edit',data)}></i>  
                                                            )
                                                            }
                                                      
                                        		                                                 		
                                                        

                                                    </th>
                                            
                                                ))}
                                           </tr>
                                        
                                        )}    
                                 </thead>      
                                <thead>
                                    
                                    
                                {MenuList.map((data,key)=>(

                                    <tr key={key}>    
                                        <td>{data.name}</td>
                                            
                                        {!Loading &&   
                                            <>
                                            {PermissionList.map((data2,key2)=>(

                                                <td key={key2}><input type="checkbox" /></td>

                                              ))} 
                                            </>
                                        }
                                    </tr>

                                ))}     
                                 


                                </thead>
                                    
                                </table>    
                           </div>
                    </div>
                </div>

                <Modal className="fade bd-example-modal-lg" show={addEditPermissionGroupModal} size="lg" >
                    <Modal.Header>
                        <Modal.Title>Permission Group</Modal.Title>
                    <Button variant="" className="btn-close" onClick={() => setaddEditPermissionGroupModal(false)} > </Button>
                    </Modal.Header>
                    <Modal.Body>
                         <div>
                          <form onSubmit={handleSubmit}>   
                                 <div className="row">
                                    <div className="col-xl-12">
                                                <div className="form-group mb-3 row">
                                                <label className="col-lg-4 col-form-label" htmlFor="Name"> Group Name<span className="text-danger">*</span></label>
                                                    <div className="col-lg-8">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="name"
                                                        name="name"
                                                        placeholder=""
                                                        value={formValues.name || ''}
                                                        onChange={handleInputChange}
                                                    />
                                                    </div>  
                                                </div>
                                        </div> 

                                        <div className="col-xl-12">
                                                <div className="form-group mb-3 row">
                                                <label className="col-lg-4 col-form-label" htmlFor="Name">  Copy permissions from </label>
                                                    <div className="col-lg-8">
                                                        <select className="form-control" id="copy_from" name="copy_from" onChange={handleInputChange}>
                                                        {!Loading &&
                                                            <>
                                                            <option value="0">Start From Scratch</option>
                                                            {PermissionList.map((data,key)=>(
                                                                <option value={data.id} key={key}>{data.name}</option>
                                                            ))}
                                                            </>
                                                        }
                                                        </select>
                                                    </div>  
                                                </div>
                                        </div> 
               {errorMsg &&

                    <div role="alert" className="fade alert alert-danger alert-dismissible show">
                    <strong>Error! </strong> {errorMsg}
                    </div>

               }                                             
                                       

                                </div>  
                                <div className='modal-footer'>

                                     <button type="submit" className="btn btn-primary"  >Save Permission Group</button>
                                    {formValues.id &&

                                        <button type="button" className="btn btn-primary " onClick={() => DeletedGroup(formValues.id)}>Delete Group</button>
                                    }                        
                                     
                                    
                                </div>       
                           </form>    
                        </div>        
                    </Modal.Body>
                </Modal>
            </div>    
        </>
    );


}
export default SettingPermissionGroupsTMS;
