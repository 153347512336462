export const trailerInitialValues = {
  trl_trailer_number: "",
  trl_status: "",
  trl_make: "",
  trl_model: "",
  trl_model_year: "",
  trl_vehicle_id_number: "",
  trl_license_plate: "",
  trl_license_plate_state_province: "",
  trl_trailer_type: "",
  trl_trailer_category: "",
  trl_door_style: "",
  trl_generator_info: "",
  trl_insurance_information: "",
  trl_length: "",
  trl_width: "",
  trl_height: "",
  trl_number_of_axles: "",
  trl_unloaded_vehicle_weight: "",
  trl_gross_vehicle_weight: "",
  trl_notes: "",
  trl_ownership: "",
  trl_purchased_or_leased: "",
  trl_purchased_leased_from: "",
  trl_sold_to: "",
  trl_purchase_lease_date: "",
  trl_sold_date_lease_end_date: "",
  trl_purchase_lease_amount: "",
  trl_sold_amount: "",
  trl_factory_price: "",
  trl_current_value: "",
  trl_license_plate_expiration: "",
  trl_inspection_expiration: "",
  trl_dot_expiration: "",
  trl_registration_expiration: "",
  trl_insurance_expiration: "",
  trl_est_odometer_reading: "",
  trl_last_service_date: "",
  trl_last_service_mileage: "",
};

export const trl_status_options = [
  { value: "", label: "Choose" },
  { value: "active", label: "Active" },
  { value: "inactive", label: "Inactive" },
];

export const trl_license_plate_state_province_options = [
  { value: "", label: "Choose" },
  { value: "Alabama", label: "Alabama" },
  { value: "Alaska", label: "Alaska" },
  { value: "Arizona", label: "Arizona" },
  { value: "Arkansas", label: "Arkansas" },
  { value: "Byram", label: "Byram" },
  { value: "California", label: "California" },
  { value: "Cokato", label: "Cokato" },
  { value: "Colorado", label: "Colorado" },
  { value: "Connecticut", label: "Connecticut" },
  { value: "Delaware", label: "Delaware" },
  { value: "District of Columbia", label: "District of Columbia" },
  { value: "Florida", label: "Florida" },
  { value: "Georgia", label: "Georgia" },
  { value: "Hawaii", label: "Hawaii" },
  { value: "Idaho", label: "Idaho" },
  { value: "Illinois", label: "Illinois" },
  { value: "Indiana", label: "Indiana" },
  { value: "Iowa", label: "Iowa" },
  { value: "Kansas", label: "Kansas" },
  { value: "Kentucky", label: "Kentucky" },
  { value: "Louisiana", label: "Louisiana" },
  { value: "Lowa", label: "Lowa" },
  { value: "Maine", label: "Maine" },
  { value: "Maryland", label: "Maryland" },
  { value: "Massachusetts", label: "Massachusetts" },
  { value: "Medfield", label: "Medfield" },
  { value: "Michigan", label: "Michigan" },
  { value: "Minnesota", label: "Minnesota" },
  { value: "Mississippi", label: "Mississippi" },
  { value: "Missouri", label: "Missouri" },
  { value: "Montana", label: "Montana" },
  { value: "Nebraska", label: "Nebraska" },
  { value: "Nevada", label: "Nevada" },
  { value: "New Hampshire", label: "New Hampshire" },
  { value: "New Jersey", label: "New Jersey" },
  { value: "New Jersy", label: "New Jersy" },
  { value: "New Mexico", label: "New Mexico" },
  { value: "New York", label: "New York" },
  { value: "North Carolina", label: "North Carolina" },
  { value: "North Dakota", label: "North Dakota" },
  { value: "Ohio", label: "Ohio" },
  { value: "Oklahoma", label: "Oklahoma" },
  { value: "Ontario", label: "Ontario" },
  { value: "Oregon", label: "Oregon" },
  { value: "Pennsylvania", label: "Pennsylvania" },
  { value: "Ramey", label: "Ramey" },
  { value: "Rhode Island", label: "Rhode Island" },
  { value: "South Carolina", label: "South Carolina" },
  { value: "South Dakota", label: "South Dakota" },
  { value: "Sublimity", label: "Sublimity" },
  { value: "Tennessee", label: "Tennessee" },
  { value: "Texas", label: "Texas" },
  { value: "Trimble", label: "Trimble" },
  { value: "Utah", label: "Utah" },
  { value: "Vermont", label: "Vermont" },
  { value: "Virginia", label: "Virginia" },
  { value: "Washington", label: "Washington" },
  { value: "West Virginia", label: "West Virginia" },
  { value: "Wisconsin", label: "Wisconsin" },
  { value: "Wyoming", label: "Wyoming" },
];

export const trl_trailer_category_options = [
  { value: "", label: "Choose" },
  { value: "Booster/Stinger", label: "Booster/Stinger" },
  { value: "Chassis/Sealed Container", label: "Chassis/Sealed Container" },
  { value: "Dolly/Jeep", label: "Dolly/Jeep" },
  { value: "Flip/Single Axle", label: "Flip/Single Axle" },
  { value: "In Tow", label: "In Tow" },
  { value: "Mobile Home", label: "Mobile Home" },
  { value: "Modular Home", label: "Modular Home" },
  { value: "Trailer", label: "Trailer" },
  { value: "Well Servicing Unit", label: "Well Servicing Unit" },
];

export const trl_door_style_options = [
  { value: "", label: "Choose" },
  { value: "Ramp Gate Door", label: "Ramp Gate Door" },
  { value: "Roll Up Door", label: "Roll Up Door" },
  { value: "Swing Doors", label: "Swing Doors" },
];

export const trl_ownership_options = [
  { value: "", label: "Choose" },
  { value: "Company", label: "Company" },
  { value: "Owner/Operator", label: "Owner/Operator" },
];

export const trl_purchased_or_leased_options = [
  { value: "", label: "Choose" },
  { value: "Purchased", label: "Purchased" },
  { value: "Leased", label: "Leased" },
];
