import React from "react";
import { Field, ErrorMessage, FieldArray } from "formik";
import swal from "sweetalert";

const ContactList = ({ name, values, setFieldValue, onDeleteContact }) => {
  const handleAddContact = (arrayHelpers) => {
    const newContact = {
      contact_is_primary: 0,
      contact_name: "",
      contact_designation: "",
      contact_mobile: "",
      contact_mobile_ext: "",
      contact_email: "",
      contact_fax: "",
      contact_add_to_email_list: "",
    };

    arrayHelpers.push(newContact);
  };

  const handleRemoveContact = (arrayHelpers, index) => {
    swal({
      title: "Are you sure?",
      text: "You want to delete this contact?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        arrayHelpers.remove(index);
        onDeleteContact(index);
      }
    });
  };

  const handlePrimarySelection = (index) => {
    values[name].forEach((_, i) => {
      setFieldValue(`${name}.${i}.contact_is_primary`, i === index ? 1 : 0);
    });
  };

  return (
    <>
      <FieldArray
        name={name}
        render={(arrayHelpers) => (
          <>
            {Array.isArray(values[name]) && values[name].length > 0 ? (
              values[name].map((contact, index) => (
                <div key={index} className="row mb-3">
                  {/* Contact Name */}
                  <div className="col px-1">
                    <div className="input-group">
                      <div className="input-group-text">
                        <Field
                          type="radio"
                          name={`${name}.contact_is_primary`}
                          className="form-check-input mt-0"
                          checked={contact.contact_is_primary === 1}
                          onChange={() => handlePrimarySelection(index)}
                        />
                      </div>
                      <Field
                        type="text"
                        name={`${name}.${index}.contact_name`}
                        className="form-control form-control-sm px-1"
                        placeholder="Contact Name"
                      />
                    </div>
                    <ErrorMessage
                      name={`${name}.${index}.contact_name`}
                      component="div"
                      className="text-danger form-text"
                    />
                  </div>

                  {/* Designation */}
                  <div className="col px-1">
                    <Field
                      type="text"
                      name={`${name}.${index}.contact_designation`}
                      className="form-control form-control-sm px-1"
                      placeholder="Designation"
                    />
                    <ErrorMessage
                      name={`${name}.${index}.contact_designation`}
                      component="div"
                      className="text-danger form-text"
                    />
                  </div>

                  {/* Mobile and Extension */}
                  <div className="col px-1">
                    <div className="input-group">
                      <Field
                        type="tel"
                        name={`${name}.${index}.contact_mobile`}
                        className="form-control form-control-sm px-1 w-50"
                        placeholder="Mobile"
                      />
                      <Field
                        type="tel"
                        name={`${name}.${index}.contact_mobile_ext`}
                        className="form-control form-control-sm px-1"
                        placeholder="Ext."
                      />
                    </div>
                    <ErrorMessage
                      name={`${name}.${index}.contact_mobile`}
                      component="div"
                      className="text-danger form-text"
                    />
                    {/* <ErrorMessage
                      name={`${name}.${index}.contact_mobile_ext`}
                      component="div"
                      className="text-danger form-text"
                    /> */}
                  </div>

                  {/* Email */}
                  <div className="col px-1">
                    <Field
                      type="email"
                      name={`${name}.${index}.contact_email`}
                      className="form-control form-control-sm px-1"
                      placeholder="Email"
                    />
                    <ErrorMessage
                      name={`${name}.${index}.contact_email`}
                      component="div"
                      className="text-danger form-text"
                    />
                  </div>

                  {/* Fax */}
                  <div className="col px-1">
                    <Field
                      type="text"
                      name={`${name}.${index}.contact_fax`}
                      className="form-control form-control-sm px-1"
                      placeholder="Fax"
                    />
                    <ErrorMessage
                      name={`${name}.${index}.contact_fax`}
                      component="div"
                      className="text-danger form-text"
                    />
                  </div>

                  {/* Add to Email List */}
                  <div className="col px-1">
                    <Field
                      type="checkbox"
                      name={`${name}.${index}.contact_add_to_email_list`}
                      className="form-check-input mt-0"
                      value="1"
                    />
                    {/* Remove Button */}
                    {/* <div className="col px-1"> */}
                    <button
                      type="button"
                      className="btn btn-danger btn-xs float-end"
                      onClick={() => handleRemoveContact(arrayHelpers, index)}
                      disabled={values?.[name]?.length === 1}
                    >
                      <i className="fas fa-trash"></i>
                    </button>
                    {/* </div> */}
                  </div>
                </div>
              ))
            ) : (
              <div className="col-12 px-1">
                <p>No contacts found.</p>
              </div>
            )}

            {/* Add Contact Button */}
            <div className="col px-1">
              <button
                type="button"
                className="btn btn-primary btn-sm"
                onClick={() => handleAddContact(arrayHelpers)}
              >
                + Add Contact
              </button>
            </div>
          </>
        )}
      />
    </>
  );
};

export default ContactList;
