import React, { useMemo, useReducer } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import validationSchema from "./helpers/LocationValidationSchema";
import {
  PageTitle,
  fetchAllBranchList,
  searchPlace,
  getPlaceDetails,
} from "../../../../services/CustomServices/CommonService";
import {
  addLocation,
  updateLocation,
  getLocationById,
  deleteLocationContact,
} from "../../../../services/CustomServices/LocationService";
import { Link, useParams, useNavigate } from "react-router-dom";
import { Collapse } from "react-bootstrap";
import { toast } from "react-toastify";
import CustomSelect from "../../../components/CustomComponents/Select";
import Select from "react-select";
import ContactList from "../../../components/CustomComponents/ContactList";
import { transformAPIPayload, transformReducerData } from "./helpers/function";
import {
  initialValues,
  screenOption,
  /* allBranches, */
  allClasses,
  locationRequirements,
} from "./helpers/constant";

const debug = false;
const AddLocation = () => {
  const [state, dispatch] = useReducer(transformReducerData, true);

  const { locationId } = useParams(); // Assuming you are using React Router to get the location ID
  const [location, setLocation] = React.useState(null);
  const [allBranchesOptions, setallBranchesOptions] = React.useState([]);

  const [options, setOptions] = React.useState([]);
  /* const [searchTerm, setSearchTerm] = React.useState(""); */
  const [selectedOption, setSelectedOption] = React.useState({
    value: "",
    label: "",
  });

  const handleInputChange = (inputValue) => {
    /* setSearchTerm(inputValue); */
    if (inputValue.length > 2) {
      searchPlace(inputValue).then((res) => {
        setOptions(
          res.result.map((place) => ({
            label: place.address,
            value: place.place_id,
          }))
        );
      });
    }
  };

  const handleSelectChange = (selectedOption, setFieldValue) => {
    setSelectedOption(selectedOption);

    getPlaceDetails(selectedOption.value).then((res) => {
      const response = res.result;
      setFieldValue("location_name", response.name ?? "");
      setFieldValue("location_address", response.formatted_address ?? "");
      setFieldValue("location_mobile", response.formatted_phone_number ?? "");
    });
  };

  const location_meta = location && location.location_meta;
  const navigate = useNavigate();
  const initialState = useMemo(() => location || initialValues, [location]);

  // Get dropdowns value from API on page render
  React.useEffect(() => {
    fetchAllBranchList().then((options) => {
      setallBranchesOptions(options);
    });
  }, []);

  React.useEffect(() => {
    if (locationId) {
      const fetchLocation = async () => {
        if (locationId) {
          try {
            const locationData = await getLocationById(locationId);
            if (
              locationData &&
              locationData.result &&
              locationData.result.length
            ) {
              setLocation(locationData.result[0]);
            }
          } catch (error) {
            console.error("Error fetching location:", error);
          }
        }
      };
      fetchLocation();
    } else {
      setLocation(initialValues);
    }
  }, [locationId]);

  const onDeleteContact = async (index) => {
    if (
      !location ||
      !location.location_meta ||
      !location.location_meta[index]
    ) {
      console.warn(
        "location or location_meta is null or index is out of bounds"
      );
      return;
    }
    const contactId = location.location_meta[index].id;
    if (contactId) {
      await deleteLocationContact(location.id, contactId);
    }
    const updatedLocation = { ...location };
    updatedLocation.location_meta = location.location_meta.filter(
      (contact, i) => i !== index
    );
    toast.success("Location Contact deleted successfully!");
    setLocation(updatedLocation);
  };

  const onSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);
    if (location && location.id) {
      try {
        const payload = transformAPIPayload(values);
        const locationDataResponse = await updateLocation(location.id, payload);
        toast.success(locationDataResponse.message);
        navigate("/locations/location-list", { replace: true });
      } catch (error) {
        console.error("Error updating location:", error);
        toast.error("Error updating location!");
      }
    } else {
      try {
        const locationData = await addLocation(values);
        toast.success(locationData.message);
        // Navigate to LocationList page after 2 seconds
        setTimeout(() => {
          navigate("/locations/location-list", { replace: true });
        }, 2000);
      } catch (error) {
        console.error("Error submitting form:", error);
        toast.error("Error adding location!");
        throw error;
      }
    }
    setSubmitting(false);
  };

  return (
    <Formik
      initialValues={initialState}
      validationSchema={validationSchema}
      enableReinitialize // This ensures form is updated with location data when editing
      validateOnBlur={false}
      validateOnChange={true}
      validateOnMount={true}
      onSubmit={async (values, { setSubmitting, validateForm }) => {
        const errors = await validateForm(values);
        if (Object.keys(errors).length === 0) {
          onSubmit(values, { setSubmitting });
        } else {
          console.log("Location form is not valid:", errors);
        }
      }}
    >
      {({
        dirty,
        isSubmitting,
        handleSubmit,
        errors,
        setFieldValue,
        values,
      }) => (
        <Form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-xl-12">
              <PageTitle
                activeMenu={locationId ? "Edit Location" : "Add Location "}
                motherMenu="Locations"
              />
              <div className="mb-3">
                <ul className="d-flex align-items-center flex-wrap">
                  <li>
                    <Link
                      to={"/locations/location-list"}
                      className="btn btn-primary btn-sm mx-1"
                    >
                      Location List
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={"#"}
                      className="btn btn-primary btn-sm open mt-1 mt-md-0"
                      onClick={() => dispatch({ type: "collpase20" })}
                    >
                      Screen Option
                    </Link>
                  </li>
                </ul>
              </div>
              <Collapse in={state.collpase20}>
                <div className="main-check py-2">
                  <div className="row">
                    <h4 className="mb-3">Show on screen</h4>
                    {screenOption.map((item, ind) => (
                      <div className="col-xl-2 col-lg-3 col-sm-4" key={ind}>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            id={`flexCheckDefault-${item.series}`}
                            onChange={() => {
                              dispatch({ type: `section${item.id}` });
                            }}
                            defaultChecked
                          />
                          <label
                            className="form-check-label mb-0 text-nowrap"
                            htmlFor={`flexCheckDefault-${item.series}`}
                          >
                            {item.title}
                          </label>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </Collapse>
              <div className="row">
                <div className="col-xl-8 col-xxl-12">
                  {!state.section20 && (
                    <div className="filter cm-content-box box-primary">
                      <div className="content-title py-0">
                        <div className="cpa">Find Location</div>
                        <div className="tools">
                          <Link
                            to={"#"}
                            className={`SlideToolHeader ${
                              state.collpase14 ? "collapse" : "expand"
                            }`}
                            onClick={() => dispatch({ type: "collpase14" })}
                          >
                            <i className="fas fa-angle-up"></i>
                          </Link>
                        </div>
                      </div>
                      <Collapse in={!state.collpase14}>
                        <div className="cm-content-body form excerpt">
                          <div className="card-body py-3">
                            <label className="from-label">
                              Find a location
                            </label>
                            <Select
                              options={options}
                              onInputChange={handleInputChange}
                              onChange={(selectedOption) =>
                                handleSelectChange(
                                  selectedOption,
                                  setFieldValue
                                )
                              }
                              placeholder="Search for a location..."
                            />
                            <div className="form-text">
                              Please enter the name or address you want to
                              search for in the box above, and we'll find the
                              best match from the internet.
                            </div>
                          </div>
                        </div>
                      </Collapse>
                    </div>
                  )}
                  {!state.section21 && (
                    <div className="filter cm-content-box box-primary">
                      <div className="content-title py-0">
                        <div className="cpa">Location Address</div>
                        <div className="tools">
                          <Link
                            to={"#"}
                            className={`SlideToolHeader ${
                              state.collpase15 ? "collapse" : "expand"
                            }`}
                            onClick={() => dispatch({ type: "collpase15" })}
                          >
                            <i className="fas fa-angle-up"></i>
                          </Link>
                        </div>
                      </div>
                      <Collapse in={!state.collpase15}>
                        <div className="cm-content-body form excerpt">
                          <div className="card-body py-3">
                            <div className="row">
                              <div className="col-xl-6 col-sm-6">
                                <label className="form-label">
                                  Location Name{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <Field
                                  type="text"
                                  name="location_name"
                                  className="form-control form-control-sm mb-2"
                                  placeholder="Location Name"
                                />
                                <ErrorMessage
                                  name="location_name"
                                  component="div"
                                  className="text-danger form-text mb-2"
                                />
                              </div>
                              <div className="col-xl-6 col-sm-6">
                                <label className="form-label">
                                  Mobile & Ext.
                                </label>
                                <div className="input-group mb-2">
                                  <Field
                                    type="text"
                                    name="location_mobile"
                                    className="form-control form-control-sm"
                                    placeholder="Mobile"
                                  />
                                  <Field
                                    type="text"
                                    name="location_mobile_ext"
                                    className="form-control form-control-sm"
                                    placeholder="Ext."
                                  />
                                </div>
                                <ErrorMessage
                                  name="location_mobile"
                                  component="div"
                                  className="text-danger form-text mb-2"
                                />
                              </div>
                              <div className="col-xl-6 col-sm-6">
                                <label className="form-label">
                                  Branch Name
                                </label>
                                <div className="input-group mb-2">
                                  <label className="input-group-text mb-0">
                                    Branch
                                  </label>
                                  <CustomSelect
                                    name="location_branch"
                                    options={allBranchesOptions}
                                    className="form-control form-control-sm wide"
                                  />
                                </div>
                                <ErrorMessage
                                  name="location_branch"
                                  component="div"
                                  className="text-danger form-text mb-2"
                                />
                              </div>
                              <div className="col-xl-6 col-sm-6">
                                <label className="form-label">
                                  Location Class
                                </label>
                                <div className="input-group mb-2">
                                  <label className="input-group-text mb-0">
                                    Class
                                  </label>
                                  <CustomSelect
                                    name="location_class"
                                    options={allClasses}
                                    className="form-control form-control-sm wide"
                                  />
                                </div>
                              </div>
                              <div className="col-xl-6 col-md-6 col-sm-6">
                                <label className="form-label">
                                  Billing Address{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <Field
                                  as="textarea"
                                  name="location_address"
                                  className="form-control form-control-sm"
                                  placeholder=""
                                  rows="3"
                                />
                                <ErrorMessage
                                  name="location_address"
                                  component="div"
                                  className="text-danger form-text mb-2"
                                />
                              </div>

                              <div className="col-xl-6 col-md-6 col-sm-6">
                                <label className="form-label">
                                  Location Requirements{" "}
                                </label>
                                <div className="form-group">
                                  {locationRequirements.map(
                                    (requirement, index) => (
                                      <div
                                        key={index}
                                        className="form-check mb-2"
                                      >
                                        <Field
                                          type="checkbox"
                                          name="location_requirements"
                                          value={requirement.value}
                                          className="form-check-input"
                                          id={`location_requirements_${index}`}
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor={`location_requirements_${index}`}
                                        >
                                          {requirement.label}
                                        </label>
                                      </div>
                                    )
                                  )}
                                </div>
                                <ErrorMessage
                                  name="location_requirements"
                                  component="div"
                                  className="text-danger form-text mb-2"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </Collapse>
                    </div>
                  )}
                  {!state.section22 && (
                    <div className="filter cm-content-box box-primary">
                      <div className="content-title py-0">
                        <div className="cpa">Contact List</div>
                        <div className="tools">
                          <Link
                            to={"#"}
                            onClick={() => dispatch({ type: "collpase16" })}
                            className={`SlideToolHeader ${
                              state.collpase16 ? "collapse" : "expand"
                            }`}
                          >
                            <i className="fas fa-angle-up"></i>
                          </Link>
                        </div>
                      </div>
                      <Collapse in={!state.collpase16}>
                        <div className="cm-content-body form excerpt">
                          <div className="card-body py-3">
                            <div className="row">
                              <div className="col px-1">
                                <label className="from-label">
                                  Contact Name
                                </label>
                              </div>

                              <div className="col px-1">
                                <label className="from-label">
                                  Designation
                                </label>
                              </div>

                              <div className="col px-1">
                                <label className="from-label">
                                  Mobile & Ext.
                                </label>
                              </div>

                              <div className="col px-1">
                                <label className="from-label">Email</label>
                              </div>

                              <div className="col px-1">
                                <label className="from-label">Fax</label>
                              </div>

                              <div className="col px-1">
                                <label className="from-label">
                                  Add to Email List
                                </label>
                              </div>
                            </div>

                            <ContactList
                              metaList={location_meta}
                              name="location_meta"
                              values={values}
                              errors={values}
                              placeholder="Contact List"
                              label="Contact List"
                              onDeleteContact={onDeleteContact}
                              setFieldValue={setFieldValue}
                            />
                            <span className="mt-3 d-block">
                              Custom fields can be used to extra metadata to a
                              post that you can use in your theme.
                            </span>
                          </div>
                        </div>
                      </Collapse>
                    </div>
                  )}
                </div>
                <div className="col-xl-4 col-xxl-12">
                  {!state.section24 && (
                    <div className="filter cm-content-box box-primary">
                      <div className="content-title py-0">
                        <div className="cpa">Notes (Optional)</div>
                        <div className="tools">
                          <Link
                            to={"#"}
                            onClick={() => dispatch({ type: "collpase18" })}
                            className={`SlideToolHeader ${
                              state.collpase18 ? "collapse" : "expand"
                            }`}
                          >
                            <i className="fas fa-angle-up"></i>
                          </Link>
                        </div>
                      </div>
                      <Collapse in={!state.collpase18}>
                        <div className="cm-content-body form excerpt">
                          <div className="card-body py-3">
                            <div className="row">
                              <div className="col-xl-12 col-md-12 col-sm-6">
                                <label className="form-label">
                                  Private Notes{" "}
                                </label>
                                <Field
                                  as="textarea"
                                  name="location_private_notes"
                                  className="form-control form-control-sm"
                                  placeholder="Private Notes"
                                  rows="3"
                                ></Field>
                                <div className="form-text">
                                  This note is private and viewable only by your
                                  organization.
                                </div>
                              </div>

                              <div className="col-xl-12 col-md-12 col-sm-6 mt-3">
                                <label className="form-label">
                                  Public Notes{" "}
                                </label>
                                <Field
                                  as="textarea"
                                  name="location_public_notes"
                                  className="form-control form-control-sm"
                                  placeholder="Public Notes"
                                  rows="3"
                                ></Field>
                                <div className="form-text">
                                  This note used to add location specific notes
                                  to the load notes on your load documents when
                                  this location is added to a load.
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Collapse>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="form-group mt-3">
            <button
              type="submit"
              className="btn btn-primary"
              disabled={!dirty || isSubmitting}
              onClick={() => {
                if (Object.keys(errors).length > 0) {
                  const firstErrorField = Object.keys(errors)[0];
                  if (Array.isArray(errors[firstErrorField])) {
                    const firstErrorObject = errors[firstErrorField].find(
                      (obj) =>
                        obj !== null &&
                        obj !== undefined &&
                        Object.keys(obj || {}).length > 0
                    );
                    if (firstErrorObject) {
                      const firstErrorKey = Object.keys(firstErrorObject)[0];
                      const errorIndex =
                        errors[firstErrorField].indexOf(firstErrorObject);
                      const errorFieldName = `${firstErrorField}.${errorIndex}.${firstErrorKey}`;
                      const element = document.querySelector(
                        `[name="${errorFieldName}"]`
                      );
                      if (element) {
                        element.focus();
                      }
                    }
                  } else {
                    const element =
                      document.getElementsByName(firstErrorField)[0];
                    if (element) {
                      element.focus();
                    }
                  }
                }
              }}
            >
              Submit
            </button>
            {debug && (
              <>
                <pre style={{ textAlign: "left" }}>
                  <strong>Values</strong>
                  <br />
                  {JSON.stringify(values, null, 2)}
                </pre>
                <pre style={{ textAlign: "left" }}>
                  <strong>Errors</strong>
                  <br />
                  {JSON.stringify(errors, null, 2)}
                </pre>
              </>
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default AddLocation;
